import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import maologo from '../images/mao_logo.webp';
import smlogo from '../images/sanmiguel.webp';
import paologo from '../images/pao_logo.webp';

import { useResetMutation } from "../utils/features/auth/authApiSlice"
import { useForm } from "react-hook-form"
import { toast } from 'react-toastify'

const TITLE = 'GoFarmers | Forgot Password'

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%])[A-Za-z\d!@#$%]{8,}$/;


const ResetPassword = () => {
    const { handleSubmit, register, formState: { errors }, setError, watch } = useForm()
    const [reset] = useResetMutation()

    const navigate = useNavigate()

    const onSubmit = async (data) => {
        try {
            const { password, repeatpassword } = data

            if (repeatpassword !== password) {
                setError("repeatpassword", {
                    type: "manual",
                    message: "Passwords do not match",
                })
                return
            }

            const urlParams = window.location.pathname.split('/')
            const id = urlParams[2]
            const token = urlParams[3]

            await reset({
                id,
                token,
                newPassword: password,
            })
                .unwrap()
                .then((res) => {
                    toast.success('Password changed successfully')
                    navigate('/')
                })
                .catch((err) => {
                    if (err.status === 400 && err.data.message === "Reset link has already been used") {
                        toast.error("Reset link has already been used")
                    } else if (err.status === 400 && err.data.message === "Reset link has expired") {
                        toast.error("Reset link has expired. Please request a new reset link")
                    } else {
                        toast.error("Error occurred: " + err)
                    }
                    // console.log(err)
                })
        } catch (err) {
            // console.log("Unexpected error:", err)
        }
    }

    return (
        <>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <div className="bg">
                <section className="vh-100">
                    <div className="container py-5 h-100">
                        <div className="row d-flex justify-content-center align-items-center h-100">
                            <div className="col col-xl-8">
                                <div className="card-transparent border-0 py-5" id="cardGlass">
                                    <div className="row g-0">

                                        <div className="col-md-5 col-lg-6 d-none d-md-block text-center ps-5">
                                            <img src={maologo} alt="Municipal Agricultural Office" id="maoLogo" />
                                            <p className="fst-italic" id="inCollab">In Collaboration with:</p>
                                            <div className="row justify-content-center g-0">
                                                <div className="col-4">
                                                    <img src={smlogo} alt="Municipality of San Miguel" id="smallLogo" />
                                                </div>
                                                <div className="col-4">
                                                    <img src={paologo} alt="Provincial Agricultural Office" id="smallLogo" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-5 col-lg-6 d-flex align-items-center pt-5 me-md-3 me-lg-0">
                                            <div className="card-body p-4 p-lg-5 text-black">
                                                <form onSubmit={handleSubmit(onSubmit)}>
                                                    <h5 className="fs-2 fw-bold" style={{ color: '#185D10' }}>Reset Password</h5>

                                                    <div className="form-outline mb-2">
                                                        <input type="password" className="form-control form-control-md fw-light" placeholder="New Password" {...register("password", {
                                                            required: "This field is required",
                                                            pattern: {
                                                                value: PWD_REGEX,
                                                                message: "Invalid password"
                                                            }
                                                        })} />
                                                        {errors.password && <p className="errmsg">{errors.password.message}</p>}
                                                    </div>
                                                    <div className="form-outline mb-2">
                                                        <input type="password" className="form-control form-control-md fw-light" placeholder="Confirm New Password" {...register("repeatpassword", {
                                                            required: "This field is required", pattern: {
                                                                value: PWD_REGEX,
                                                                message: "Passwords do not match"
                                                            }, validate: (value) => value === watch("password") || "Passwords do not match"
                                                        })} />
                                                        {errors.repeatpassword && <p className="errmsg">{errors.repeatpassword.message}</p>}
                                                    </div>

                                                    <div className="pt-1 mb-3 d-grid">
                                                        <button className="btn btn-md fw-bold" type="submit" id="entryButton">Submit</button>
                                                    </div>
                                                    <div className="text-center">
                                                        <span className="fs-5 text-white fw-light">Remember your password? </span>
                                                        <Link to="/" className="fs-5 fw-bold text-decoration-none m-0" id="brown">Sign In</Link>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default ResetPassword