import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import format from "date-fns/format"

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, Title, BarElement);

export function BarChart({ data }) {

    const [labels, setLabels] = React.useState([])

    const [datasets, setDatasets] = React.useState([])

    React.useEffect(() => {
        if (data) {
            setLabels(data.map((x) => format(new Date(x.date), "MMMM yyyy")))

            setDatasets([
                {
                    data: data.map((x) => x.value),
                    barThickness: 100,
                    backgroundColor: ['#00A100', '#128FD9', '#FFB200', '#FF0000', '#00A100', '#128FD9'],
                    borderColor: ['#00A100', '#128FD9', '#FFB200', '#FF0000', '#00A100', '#128FD9']
                }
            ])
        }
    }, [data])

    const BarOptions = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          x: {
            grid: { display: false }
          }
        }
    }
    return <Bar options={BarOptions} data={{ labels, datasets }} />;
}
