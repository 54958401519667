// OTHER IMPORTS
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// COMPONENTS
import maologo from '../images/mao_logo.webp';
import smlogo from '../images/sanmiguel.webp';
import paologo from '../images/pao_logo.webp';

import { useForgotMutation } from "../utils/features/auth/authApiSlice"
import { useForm } from "react-hook-form"
import { toast } from 'react-toastify'

const TITLE = 'GoFarmers | Forgot Password'

const ForgotPassword = () => {
  const { handleSubmit, register, formState: { errors } } = useForm()
  const [forgot] = useForgotMutation()

  const navigate = useNavigate()

  const onSubmit = async (data) => {
    try {
      await forgot(data)
        .unwrap()
        .then((res) => {
          toast.success('Reset link sent successfully')
          navigate('/forgot-password/email-sent', { state: { email: data.email } })
        })
        .catch((err) => {
          if (err.status === 400 && err.data.message === "Reset link already sent. Please check your email.") {
            toast.error("Reset link already sent. Please check your email.")
          } else {
            toast.error('Error occurred')
          }
          // console.log(err)
        })
    } catch (err) {
      // console.log("Unexpected error:", err)
    }
  }

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <div className="bg">
        <section className="vh-100">
          <div className="container py-5 h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col col-xl-8">
                <div className="card-transparent border-0 py-5" id="cardGlass">
                  <div className="row g-0">
                    <div className="col-md-6 col-lg-6 d-none d-md-block text-center ps-5">
                      <img src={maologo} alt="Municipal Agricultural Office" id="maoLogo" style={{ Width: "55%" }} />
                      <p className="fst-italic fs-3" id="inCollab">In Collaboration with:</p>
                      <div className="row justify-content-center g-0">
                        <div className="col-4">
                          <img src={smlogo} alt="Municipality of San Miguel" id="smallLogo" style={{ minWidth: "65%" }} />
                        </div>
                        <div className="col-4">
                          <img src={paologo} alt="Provincial Agricultural Office" id="smallLogo" style={{ minWidth: "65%" }} />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-5 col-lg-6 d-flex align-items-center pt-5 me-md-3 me-lg-0">
                      <div className="card-body p-4 p-lg-5 text-black">
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <h5 className="fs-2 fw-bold" style={{ color: '#185D10' }}>Forgotten Password</h5>
                          <p className="text-white fw-light ms-0 pb-1">Enter your registered email to change your password</p>

                          <div className="form-outline mb-2">
                            <input type="email" className="form-control form-control-md fw-light" placeholder="Email" {...register("email", { required: "This field is required" })} />
                            {errors.email && <p className="errmsg">{errors.email.message}</p>}
                          </div>

                          <div className="pt-1 mb-3 d-grid">
                            <button className="btn btn-md fw-bold" type="submit" id="entryButton">Submit</button>
                          </div>
                          <div className="text-center">
                            <span className="fs-5 text-white fw-light">Remembered your password?</span>
                            <Link to="/" className="fs-5 fw-bold text-decoration-none m-0" id="brown"> Sign In</Link>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default ForgotPassword