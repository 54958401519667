import React, { useEffect, useState } from "react";
// OTHER IMPORTS
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { format } from "date-fns"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { toast } from "react-toastify"
import { useFetchUserByFfrsQuery } from "../utils/features/users/usersApiSlice";
import { useCreateFormByBAFCMutation } from "../utils/features/_forms/_formsApiSlice";
import { useFetchLatestInitialReportQuery } from "../utils/features/initialReports/initialReportsApiSlice";
import UseAuth from "../utils/hooks/useAuth";

// COMPONENTS
import Navbar from "../navbar/BAFCNavbar";
import SideBarnav from '../sidebar/BAFCSidebar';
import logo from '../images/logo.webp';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const TITLE = 'GoFarmers | Add Farmer Damaged Form'

const validationSchema = z.object({
  ffrs: z.string({ errorMap: () => ({ message: "FFRS ID is invalid." }) })
    .trim()
    .min(1, "FFRS ID is required.")
    .max(50, "FFRS ID cannot be more than 50 characters."),
  firstName: z.string({ errorMap: () => ({ message: "First Name is invalid." }) })
    .trim()
    .min(1, "First Name is required.")
    .max(255, "First Name cannot be more than 255 characters."),
  lastName: z.string({ errorMap: () => ({ message: "Last Name is invalid." }) })
    .trim()
    .min(1, "Last Name is required.")
    .max(255, "Last Name cannot be more than 255 characters."),
  disasterDate: z.coerce.date({ errorMap: () => ({ message: "Disaster Type is invalid." }) }),
  disasterType: z.string({ errorMap: () => ({ message: "Disaster Type is invalid." }) })
    .trim()
    .nonempty("Disaster Type is required.")
    .max(255, "Disaster Type cannot be more than 255 character(s)."),
  cropSeed: z.string({ errorMap: () => ({ message: "Crop Seed is invalid." }) })
    .trim()
    .nonempty("Crop Seed is required.")
    .max(255, "Crop Seed cannot be more than 255 character(s)."),
  seedType: z.string({ errorMap: () => ({ message: "Seed Type is invalid." }) })
    .trim()
    .nonempty("Seed Type is required.")
    .max(255, "Seed Type cannot be more than 255 character(s)."),
  cropType: z.string({ errorMap: () => ({ message: "Crop Type is invalid." }) })
    .trim()
    .nonempty("Crop Type is required.")
    .max(255, "Crop Type cannot be more than 255 character(s)."),
  cropStage: z.string({ errorMap: () => ({ message: "Crop Stage is invalid." }) })
    .trim()
    .nonempty("Crop Stage is required.")
    .max(255, "Crop Stage cannot be more than 255 character(s)."),
  cropArea: z.coerce.number({ errorMap: () => ({ message: "Area of Standing Crops is invalid." }) })
    .positive("Area of Standing Crops is invalid.")
})

const BAFCAddDamagedForm = () => {
  const [isClicked, setIsClicked] = useState(false);

  const [showModal, setshowModal] = useState(false);

  const modalClose = () => setshowModal(false);
  const modalShow = () => setshowModal(true);

  const handleButtonClick = () => {
    setIsClicked(!isClicked);
  };

  const navigate = useNavigate()

  const user = UseAuth()

  const { handleSubmit, register, setValue, setError, formState: { errors }, watch } = useForm({
    resolver: zodResolver(validationSchema),
    reValidateMode: "onChange"
  })

  const currentFfrs = watch("ffrs", "")

  const { data: userByFfrs, isLoading: userByFfrsIsLoading } = useFetchUserByFfrsQuery({
    ffrs: currentFfrs
  })

  const { data: initialReport, isLoading: initialReportIsLoading } = useFetchLatestInitialReportQuery()

  useEffect(() => {
    if (!userByFfrsIsLoading) {
      setValue("firstName", userByFfrs?.data ? userByFfrs.data.firstName : null)
      setValue("lastName", userByFfrs?.data ? userByFfrs.data.lastName : null)
    }
  }, [userByFfrs, userByFfrsIsLoading, setValue])

  useEffect(() => {
    if (!initialReportIsLoading) {
      setValue("disasterDate", format(new Date(initialReport.data.disasterDate), "yyyy-MM-dd"))
    }
  }, [initialReport, initialReportIsLoading, setValue])

  const [createFormMutation] = useCreateFormByBAFCMutation()

  const handleCreateForm = async (data) => {
    const createForm = await createFormMutation(data)

    if (createForm.error) {
      toast.error(createForm.error.data.message, {
        position: "top-right"
      })

      setError("ffrs", {
        message: createForm.error.data.message
      })
      return
    }
    navigate("/bafc-generated-reports")
    toast.success("Created new farmer response.", {
      position: "top-right"
    })
  }

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Navbar handleButtonClick={handleButtonClick} />
      <div className="container-fluid" id="tryFix">
        <div className="row flex-nowrap  overflow-hidden">
          <SideBarnav />
          <div className="col overflow-x-hidden" id={`${isClicked ? 'tryMove' : ''}`}>
            <style>
              {`
                #tryMove {
                  margin-left: ${isClicked ? '380px' : '0px'};
                }
              `}
            </style>
            <h1 className="section">Add Farmers Damaged Form</h1>

            <div className="d-flex justify-content-between">
              <Link to="/bafc-generated-reports"><button className="btn btn-success my-2" id="buttons"><i class="bi bi-arrow-left"></i> BACK</button></Link>
            </div>

            <div className="container" id="damageContainer">
              <div className="row justify-content-center align-items-center">
                <div id="textSection">
                  <div className="logoForms">
                    <img src={logo} alt="GoFarmers" />
                  </div>
                  <h2 className="centerTitle">Farmer Damaged Generated Report</h2>
                  <form id="form-id" onSubmit={handleSubmit(handleCreateForm)}>
                    <div className="form">
                      <div className="formSection"></div>
                      <h5 className="ms-4 fw-bold">Contact Information</h5>
                      <div className="form">
                        <div className="row justify-content-center align-items-center">
                          <label for="ffrs" className="fw-medium text-end col-sm-5 my-1 col-form-label"><i class="bi-asterisk asterisk"></i> FFRS ID : </label>
                          <div className="col-sm-5">
                            <input type="text" className="textField form-control" {...register("ffrs")} id="ffrs" />
                            {errors?.ffrs && <small className="text-danger">{errors.ffrs.message}</small>}
                          </div>
                        </div>

                        <div className="row justify-content-center align-items-center">
                          <label for="firstName" className="fw-medium text-end col-sm-5 my-1 col-form-label"><i class="bi-asterisk asterisk"></i>  FIRST NAME :</label>
                          <div className="col-sm-5">
                            <input type="text" className="textField form-control" {...register("firstName")} id="firstName" disabled />
                            {errors?.firstName && <small className="text-danger">{errors.firstName.message}</small>}
                          </div>
                        </div>

                        <div className="row justify-content-center align-items-center">
                          <label for="lastName" className="fw-medium text-end col-sm-5 my-1 col-form-label"><i class="bi-asterisk asterisk"></i>  LAST NAME :</label>
                          <div className="col-sm-5">
                            <input type="text" className="textField form-control" {...register("lastName")} id="lastName" disabled />
                            {errors?.lastName && <small className="text-danger">{errors.lastName.message}</small>}
                          </div>
                        </div>

                        <div className="formSection"></div>
                        <h5 className="ms-4 fw-bold" >General Information</h5>
                        <div className="row justify-content-center align-items-center" >
                          <label htmlFor="date" className="fw-medium text-end col-sm-5 my-1 col-form-label"><i class="bi-asterisk asterisk"></i>  DATE OF OCCURENCE :</label>
                          <div className="col-sm-5">
                            <input type="date" className="textField form-control" {...register("disasterDate")} id="date" disabled />
                            {errors?.disasterDate && <small className="text-danger">{errors.disasterDate.message}</small>}
                          </div>
                        </div>
                        <div className="row justify-content-center align-items-center">
                          <label for="type" className="fw-medium text-end col-sm-5 my-1 col-form-label"><i class="bi-asterisk asterisk"></i>  TYPE OF DISASTER :</label>
                          <div className="col-sm-5">
                            <select className="textField form-select" {...register("disasterType")} id="type">
                              <option value="">Select an option</option>
                              <option value="Drought">Drought</option>
                              <option value="Typhoon">Typhoon</option>
                              <option value="Flood">Flood</option>
                            </select>
                            {errors?.disasterType && <small className="text-danger">{errors.disasterType.message}</small>}
                          </div>
                        </div>
                        <div className="form">
                          <div className="formSection"></div>
                          <h5 className="ms-4 fw-bold">Geographic Information</h5>
                          <div className="row justify-content-center align-items-center">
                            <label for="region" className="fw-medium text-end col-sm-5 my-1 col-form-label">REGION :</label>
                            <div className="col-sm-5">
                              <input type="text" inputmode="numeric" className="textField form-control" id="region" defaultValue="3" disabled readonly />
                            </div>
                          </div>
                          <div className="row justify-content-center align-items-center">
                            <label for="province" className="fw-medium text-end col-sm-5 my-1 col-form-label">PROVINCE :</label>
                            <div className="col-sm-5">
                              <input type="text" className="textField form-control" id="province" defaultValue="Bulacan" disabled readonly />
                            </div>
                          </div>
                          <div className="row justify-content-center align-items-center">
                            <label for="municipality" className="fw-medium text-end col-sm-5 my-1 col-form-label">MUNICIPALITY :</label>
                            <div className="col-sm-5">
                              <input type="text" className="textField form-control" id="municipality" defaultValue="San Miguel" disabled readonly />
                            </div>
                          </div>

                          <div className="row justify-content-center align-items-center">
                            <label for="municipality" className="fw-medium text-end col-sm-5 my-1 col-form-label">BARANGAY :</label>
                            <div className="col-sm-5">
                              <input type="text" className="textField form-control" id="municipality" defaultValue={user.barangay} disabled readonly />
                            </div>
                          </div>

                          <div className="formSection"></div>
                          <h5 className="ms-4 fw-bold">Crop Damage Information</h5>
                          <div className="row justify-content-center align-items-center">
                            <label for="cropseed" className="fw-medium text-end col-sm-5 my-1 col-form-label"><i class="bi-asterisk asterisk"></i>  CROP TYPE :</label>
                            <div className="col-sm-5">
                              <input type="text" className="textField form-control" {...register("cropSeed")} id="cropseed" />
                              {errors?.cropSeed && <small className="text-danger">{errors.cropSeed.message}</small>}
                            </div>
                          </div>
                          <div className="row justify-content-center align-items-center">
                            <label for="type" className="fw-medium text-end col-sm-5 my-1 col-form-label"><i class="bi-asterisk asterisk"></i> SEED TYPE :</label>
                            <div className="col-sm-5">
                              <select className="textField form-select" id="type" {...register("seedType")}>
                                <option value="">Select an option</option>
                                <option value="Inbred">Inbred</option>
                                <option value="Hybrid">Hybrid</option>
                              </select>
                              {errors?.seedType && <small className="text-danger">{errors.seedType.message}</small>}
                            </div>
                          </div>
                          <div className="row justify-content-center align-items-center">
                            <label for="type" className="fw-medium text-end col-sm-5 my-1 col-form-label"><i class="bi-asterisk asterisk"></i> ECOSYSTEM :</label>
                            <div className="col-sm-5">
                              <select className="textField form-select" id="type" {...register("cropType")}>
                                <option value="">Select an option</option>
                                <option value="Irrigated">Irrigated</option>
                                <option value="Raised Field - lowland">Raised Field - lowland</option>
                                <option value="Raised Field - upland"> Raised Field - upland</option>
                              </select>
                              {errors?.cropType && <small className="text-danger">{errors.cropType.message}</small>}
                            </div>
                          </div>
                          <div className="row justify-content-center align-items-center">
                            <label for="type" className="fw-medium text-end col-sm-5 my-1 col-form-label"><i class="bi-asterisk asterisk"></i> CROP STAGE :</label>
                            <div className="col-sm-5">
                              <select className="textField form-select" id="type" {...register("cropStage")}>
                                <option value="">Select an option</option>
                                <option value="Seedling">Seedling</option>
                                <option value="Planting">Planting</option>
                                <option value="Vegetative">Vegetative</option>
                                <option value="Reproductive">Reproductive</option>
                                <option value="Maturity">Maturity</option>
                              </select>
                              {errors?.cropStage && <small className="text-danger">{errors.cropStage.message}</small>}
                            </div>
                          </div>
                          <div className="formSection"></div>
                          <h5 className="ms-4 fw-bold">Damage Assessment of Area</h5>
                          <div className="row justify-content-center align-items-center">
                            <label for="region" className="fw-medium text-end col-sm-5 my-1 col-form-label"><i class="bi-asterisk asterisk"></i> AREA OF STANDING CROP IN HECTARES :</label>
                            <div className="col-sm-5">
                              <input type="text" inputmode="numeric" className="textField form-control" id="cropArea" {...register("cropArea")} />
                              {errors?.cropArea && <small className="text-danger">{errors.cropArea.message}</small>}
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </form>
                </div>
              </div>
              {/* BUTTONS */}
              <div className="row justify-content-center align-items-center pt-3">
                <div className="d-flex justify-content-end">
                  <div>
                    <Link to="/bafc-generated-reports">
                      <button type="button" className="btn btn-primary me-3" id="cancelButton">CANCEL</button>
                    </Link>
                  </div>
                  <button type="submit" className="btn btn-primary" onClick={modalShow} id="buttons">ADD REPORT</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={modalClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body className="py-4">
          <div className="row mb-4 text-center" style={{ color: '#185D10' }}>
            <h1><i className="bi bi-question-circle"></i></h1>
            <h5 className="fw-bold">ARE YOU SURE <br /> YOU WANT TO ADD THIS FORM?</h5>
          </div>
          <div className="row text-center">
            <div className="col text-end">
              <Button className="m-auto" onClick={modalClose} id="modalCancelbtn">
                Cancel
              </Button>
            </div>
            <div className="col text-start">
              <Button className="m-auto" type="submit" form="form-id" onClick={modalClose} id="modalConfirmbtn">ADD REPORT</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default BAFCAddDamagedForm