import React, { useState, useEffect } from "react";
// OTHER IMPORTS
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// COMPONENTS
import Navbar from "../navbar/SADNavbar";
import SideBarnav from "../sidebar/SADSidebar";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select'

import { useUpdateUserMutation } from "../utils/features/users/usersApiSlice"
import { useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import { toast } from 'react-toastify'
import { useLocation } from "react-router-dom"

const TITLE = 'GoFarmers | Edit Account'

const MOBILE_REGEX = /^09\d{9}$/

const SADEditAccount = () => {
    const [isClicked, setIsClicked] = useState(false);
    const [selectedBarangay, setSelectedBarangay] = useState([]);
    const [formattedBarangay, setFormattedBarangay] = useState([])

    const handleButtonClick = () => {
        setIsClicked(!isClicked);
    };

    const [showModal, setshowModal] = useState(false);

    const modalClose = () => setshowModal(false);
    const modalShow = () => setshowModal(true);

    const navigate = useNavigate()
    const location = useLocation()
    const { user } = location.state || {}

    const barangayOptions = [
        { value: "Bagong Pag-asa", label: "Bagong Pag-asa" },
        { value: "Bagong Silang", label: "Bagong Silang" },
        { value: "Balaong", label: "Balaong" },
        { value: "Balite", label: "Balite" },
        { value: "Bantog", label: "Bantog" },
        { value: "Bardias", label: "Bardias" },
        { value: "Baritan", label: "Baritan" },
        { value: "Batasan Bata", label: "Batasan Bata" },
        { value: "Batasan Matanda", label: "Batasan Matanda" },
        { value: "Biak-na-Bato", label: "Biak-na-Bato" },
        { value: "Biclat", label: "Biclat" },
        { value: "Buga", label: "Buga" },
        { value: "Buliran", label: "Buliran" },
        { value: "Bulualto", label: "Bulualto" },
        { value: "Calumpang", label: "Calumpang" },
        { value: "Cambio", label: "Cambio" },
        { value: "Camias", label: "Camias" },
        { value: "Ilog-Bulo", label: "Ilog-Bulo" },
        { value: "King Kabayo", label: "King Kabayo" },
        { value: "Labne", label: "Labne" },
        { value: "Lambakin", label: "Lambakin" },
        { value: "Magmarale", label: "Magmarale" },
        { value: "Malibay", label: "Malibay" },
        { value: "Maligaya", label: "Maligaya" },
        { value: "Mandile", label: "Mandile" },
        { value: "Masalipit", label: "Masalipit" },
        { value: "Pacalag", label: "Pacalag" },
        { value: "Paliwasan", label: "Paliwasan" },
        { value: "Partida", label: "Partida" },
        { value: "Pinambaran", label: "Pinambaran" },
        { value: "Poblacion", label: "Poblacion" },
        { value: "Pulong Bayabas", label: "Pulong Bayabas" },
        { value: "Pulong Duhat", label: "Pulong Duhat" },
        { value: "Sacdalan", label: "Sacdalan" },
        { value: "Salacot", label: "Salacot" },
        { value: "Salangan", label: "Salangan" },
        { value: "San Agustin", label: "San Agustin" },
        { value: "San Jose", label: "San Jose" },
        { value: "San Juan", label: "San Juan" },
        { value: "San Vicente", label: "San Vicente" },
        { value: "Santa Ines", label: "Santa Ines" },
        { value: "Santa Lucia", label: "Santa Lucia" },
        { value: "Santa Rita Bata", label: "Santa Rita Bata" },
        { value: "Santa Rita Matanda", label: "Santa Rita Matanda" },
        { value: "Sapang", label: "Sapang" },
        { value: "Sibul", label: "Sibul" },
        { value: "Tartaro", label: "Tartaro" },
        { value: "Tibagan", label: "Tibagan" },
        { value: "Tigpalas", label: "Tigpalas" },
    ];

    const { handleSubmit, register, formState: { errors }, setValue, watch } = useForm({
        defaultValues: user,
    })

    useEffect(() => {
        if (user) {
            const formattedData = user.barangay.map(barangay => ({ label: barangay, value: barangay }))
            setFormattedBarangay(formattedData)
            setValue("barangay", formattedData)
        }
    }, [user, setValue])

    const barangayValue = watch("barangay", [])

    function arraysAreEqual(arr1, arr2) {
        if (arr1.length !== arr2.length) {
            return false
        }
        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) {
                return false
            }
        }
        return true
    }

    const validateBarangay = (value) => {
        if (value && value.length >= 1) {
            return true
        }
        if (user.role === "agritech") {
            return "Please select at least one option for Barangay."
        }
        return true
    }

    const [updateUser] = useUpdateUserMutation('usersList', {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    const onSubmit = async (data) => {
        const barangayValue = Array.isArray(data.barangay)
            ? data.barangay.map((x) => x.value)
            : data.barangay
                ? [data.barangay.value]
                : []

        try {
            const updateObject = {
                id: user.id,
            }
            if (data.role !== user.role) {
                updateObject.role = data.role
            }
            if (data.mobile !== user.mobile) {
                updateObject.mobile = data.mobile
            }
            if (data.mobile !== user.mobile) {
                updateObject.mobile = data.mobile
            }
            if (!arraysAreEqual(data.barangay, formattedBarangay)) {
                updateObject.barangay = barangayValue
            }
            if (data.role === user.role && data.mobile === user.mobile && arraysAreEqual(barangayValue, user.barangay)) {
                toast.info("No changes detected")
                return
            }

            const response = await updateUser(updateObject).unwrap()
            if (response) {
                toast.success("Updated successfully")
                navigate("/superadmin-account")
            } else {
                toast.error("Something went wrong")
            }
        } catch (err) {
            toast.error("Failed to update")
        }
    }

    return (
        <>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <Navbar handleButtonClick={handleButtonClick} />
            <div className="container-fluid" id="tryFix">
                <div className="row flex-nowrap overflow-hidden">
                    <SideBarnav />

                    <div className="col overflow-x-hidden" id={`${isClicked ? 'tryMove' : ''}`}>
                        <style>
                            {`
                  #tryMove {
                  margin-left: ${isClicked ? '380px' : '0px'};
                  }
              `}
                        </style>
                        {/* SECTION TITLE */}
                        <h1 className="section">Edit Account</h1>
                        <div className="col-md-3">
                            <Link to="/superadmin-account"><button className="btn btn-success" id="buttons"><i class="bi bi-arrow-left"></i> BACK</button></Link>
                        </div>
                        <div className="container-fluid" id="customContainer">
                            <div className="row">
                                <div id="textSection">

                                    <form className="ps-4 pe-4 my-4">
                                        <div className="row justify-content-center align-items-center">
                                            <label htmlFor="firstName" className="formLabel col-sm-5 my-1 col-form-label text-end">FIRST NAME :</label>
                                            <div className="col-sm-6">
                                                <input type="text" className="textField form-control" id="firstName" {...register("firstName")} disabled readOnly />
                                            </div>
                                        </div>
                                        <div className="row justify-content-center align-items-center">
                                            <label htmlFor="lastName" className="formLabel col-sm-5 my-1 col-form-label text-end">LAST NAME :</label>
                                            <div className="col-sm-6">
                                                <input type="text" className="textField form-control" id="lastName" {...register("lastName")} disabled readOnly />
                                            </div>
                                        </div>
                                        <div className="row justify-content-center align-items-center">
                                            <label htmlFor="email" className="formLabel col-sm-5 my-1 col-form-label text-end">EMAIL :</label>
                                            <div className="col-sm-6">
                                                <input type="text" className="textField form-control" id="email" {...register("email")} disabled readOnly />
                                            </div>
                                        </div>
                                        <div className="row justify-content-center align-items-center">
                                            <label htmlFor="mobileNumber" className="formLabel col-sm-5 my-1 col-form-label text-end">MOBILE NUMBER :</label>
                                            <div className="col-sm-6">
                                                <input type="text" className="textField form-control" id="mobileNumber" {...register("mobile", {
                                                    required: "This field is required",
                                                    pattern: {
                                                        value: MOBILE_REGEX,
                                                        message: "Invalid mobile number. Mobile number should start with 09 and be 11 digits long."
                                                    }
                                                })} />
                                                {errors.mobile && <p className="errmsg">{errors.mobile.message}</p>}
                                            </div>
                                        </div>
                                        <div className="row justify-content-center align-items-center">
                                            <label htmlFor="role" className="formLabel col-sm-5 my-1 col-form-label text-end">ROLE:</label>
                                            <div className="col-sm-6">
                                                <select className="textField form-select" id="role" {...register("role")} disabled readOnly>
                                                    <option value="">Select Role</option>
                                                    <option value="BAFCadmin">Barangay Agricultural and Fishery Council </option>
                                                    <option value="agritech">Agricultural Technician </option>
                                                    <option value="MRO-rice">Municipal Report Officer(Rice) </option>
                                                    <option value="MRO-veg">Municipal Report Officer(Vegetables) </option>
                                                    <option value="PRO-rice">Provincial Report Officer(Rice) </option>
                                                    <option value="PRO-veg">Provincial Report Officer(Vegetables) </option>
                                                </select>
                                            </div>
                                        </div>
                                        {/* BARANGAY SINGLE SELECT */}
                                        <div className="row justify-content-center align-items-center">
                                            <label
                                                htmlFor="barangay"
                                                className={`formLabel col-sm-5 my-1 col-form-label text-end ${user.role !== "BAFCadmin" ? "d-none" : ""}`}
                                            >
                                                BARANGAY:
                                            </label>
                                            <div className={`col-sm-6 ${user.role !== "BAFCadmin" ? "d-none" : ""}`}>
                                                <Select
                                                    options={barangayOptions}
                                                    className="textField mb-2"
                                                    {...register("barangay", {
                                                        validate: validateBarangay
                                                    })}
                                                    value={barangayValue}
                                                    onChange={(selectedOptions) => {
                                                        setValue("barangay", selectedOptions)
                                                        setSelectedBarangay(selectedOptions)
                                                    }}
                                                />
                                                {errors.barangay && (
                                                    <p className="errmsg">{errors.barangay.message}</p>
                                                )}
                                            </div>
                                        </div>

                                        {/* BARANGAY MULTI SELECT */}
                                        <div className="row justify-content-center align-items-center">
                                            <label
                                                htmlFor="barangay"
                                                className={`formLabel col-sm-5 my-1 col-form-label text-end ${user.role !== "agritech" ? "d-none" : ""
                                                    }`}
                                            >
                                                BARANGAY :
                                            </label>
                                            <div
                                                className={`col-sm-6 ${user.role !== "agritech" ? "d-none" : ""
                                                    }`}
                                            >
                                                <Select
                                                    options={barangayOptions}
                                                    isMulti
                                                    isOptionDisabled={() => selectedBarangay.length >= 5 || barangayValue >= 5}
                                                    className="textField mb-2"
                                                    {...register("barangay", {
                                                        validate: validateBarangay
                                                    })}
                                                    value={barangayValue}
                                                    onChange={(selectedOptions) => {
                                                        setValue("barangay", selectedOptions)
                                                        setSelectedBarangay(selectedOptions)
                                                    }}
                                                />
                                                {errors.barangay && (
                                                    <p className="errmsg">{errors.barangay.message}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <div><Link to='/superadmin-account'><button type="button" className="btn btn-primary me-3" id="cancelButton">CANCEL</button></Link></div>
                                            <button type="button" className="btn btn-primary" id="buttons" onClick={modalShow}>UPDATE</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
            <Modal
                show={showModal}
                onHide={modalClose}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Body className="py-4">
                    <div className="row mb-4 text-center" style={{ color: '#185D10' }}>
                        <h1><i className="bi bi-question-circle"></i></h1>
                        <h5 className="fw-bold">ARE YOU SURE <br /> YOU WANT TO UPDATE THIS ACCOUNT?</h5>
                    </div>
                    <div className="row text-center">
                        <div className="col text-end">
                            <Button className="m-auto" onClick={modalClose} id="modalCancelbtn">
                                Cancel
                            </Button>
                        </div>
                        <div className="col text-start">
                            <Button className="m-auto" id="modalConfirmbtn" onClick={() => handleSubmit(onSubmit)()}>Update</Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SADEditAccount