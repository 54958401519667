import React, { useState, useEffect } from "react";
// OTHER IMPORTS
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { useForm } from "react-hook-form"
import { format } from "date-fns"
import { useFetchSummaryReportQuery } from "../utils/features/summaryReports/summaryReportsApiSlice";
import { PDFDownloadLink } from "@react-pdf/renderer";
import SummaryReportPDF from "../components/exports/SummaryReportPDF";

// COMPONENTS
import Navbar from "../navbar/MRONavbar";
import SideBarnav from '../sidebar/MROSidebar';
import logo from '../images/logo.webp';
import Modal from 'react-bootstrap/Modal';

const TITLE = 'GoFarmers | Damage Form History'

const MROHistoryForm = () => {
  const params = useParams()

  const [isClicked, setIsClicked] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleButtonClick = () => {
    setIsClicked(!isClicked);
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const { data: report, isLoading: reportIsLoading } = useFetchSummaryReportQuery({
    id: params.id
  })

  const { register, reset } = useForm()

  useEffect(() => {
    if (!reportIsLoading && report?.data) {
      reset({
        disasterDate: format(new Date(report.data.initialReport.disasterDate), "yyyy-MM-dd"),
        disasterType: report.data.initialReport.disasterType,
        disasterName: report.data.initialReport.disasterName,
        barangay: report.data.barangay,
        cropType: report.data.cropType,
        seedType: report.data.seedType,
        cropStage: report.data.cropStage,
        cropArea: report.data.cropArea,
        farmersCount: report.data.farmersCount,
        totallyDamaged: report.data.totallyDamaged,
        partiallyDamaged: report.data.partiallyDamaged,
        totalDamaged: report.data.totalDamaged,
        yieldBefore: report.data.yieldBefore,
        yieldAfter: report.data.yieldAfter,
        yieldLoss: report.data.yieldLoss,
        inputCost: report.data.inputCost,
        totalCost: report.data.totalCost
      })
    }
  }, [reportIsLoading, report, reset])

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Navbar handleButtonClick={handleButtonClick} />
      <div className="container-fluid" id="tryFix">
        <div className="row flex-nowrap  overflow-hidden">
          <SideBarnav />
          <div className="col overflow-x-hidden" id={`${isClicked ? 'tryMove' : ''}`}>
            <style>
              {`
                #tryMove {
                  margin-left: ${isClicked ? '380px' : '0px'};
                  
                }
              `}
            </style>
            <h1 className="section">Barangay Damage Form History</h1>
            <div className="d-flex justify-content-between">
              <Link to="/mro-report-history"><button className="btn btn-success my-2" id="buttons"><i class="bi bi-arrow-left"></i> BACK</button></Link>

              <PDFDownloadLink document={<SummaryReportPDF data={report?.data} />} fileName={`${report?.data.barangay}_summary-report`} >
                <button className="btn btn-success my-2" id="buttons"><i class="bi bi-download"></i> DOWNLOAD</button>
              </PDFDownloadLink>
            </div>
            <div className="container" id="damageContainer">
              <div className="row justify-content-center align-items-center">
                <div id="textSection">
                  <div className="logoForms">
                    <img src={logo} alt="GoFarmers" />
                  </div>
                  <h2 className="centerTitle">{report?.data.barangay} Damage Form History</h2>
                  <form>
                    <div className="form">
                      <div className="formSection"></div>
                      <h5 className="ms-4 fw-bold">General Information</h5>
                      <div className="form">
                        <div className="row justify-content-center align-items-center" >
                          <label htmlFor="date" className="fw-medium text-end col-sm-5 my-1 col-form-label"> DATE OF OCCURENCE :</label>
                          <div className="col-sm-5">
                            <input type="date" className="textField form-control" id="date" {...register("disasterDate")} disabled readonly />
                          </div>
                        </div>
                        <div className="row justify-content-center align-items-center">
                          <label for="causedamage" className="fw-medium  text-end col-sm-5 my-1 col-form-label"> TYPE OF DISASTER :</label>
                          <div className="col-sm-5">
                            <select className="textField form-select" id="causedamage" value="Drought" {...register("disasterType")} disabled readonly>
                              <option style={{ display: 'none' }}></option>
                              <option defaultValue="Drought">Drought</option>
                              <option defaultValue="Typhoon">Typhoon</option>
                              <option defaultValue="Flood">Flood</option>
                            </select>
                          </div>
                        </div>
                        <div className="row justify-content-center align-items-center" >
                          <label htmlFor="disasterName" className="fw-medium text-end col-sm-5 my-1 col-form-label">NAME OF DISASTER (OPTIONAL) :</label>
                          <div className="col-sm-5">
                            <input type="text" inputmode="numeric" className="textField form-control" id="disasterName" {...register("disasterName")} disabled readonly />
                          </div>
                        </div>
                        <div className="form">
                          <div className="formSection"></div>
                          <h5 className="ms-4 fw-bold">Geographic Information</h5>
                          <div className="row justify-content-center align-items-center">
                            <label for="region" className="fw-medium  text-end col-sm-5 my-1 col-form-label">REGION :</label>
                            <div className="col-sm-5">
                              <input type="text" inputmode="numeric" className="textField form-control" id="region" defaultValue="3" disabled readonly />
                            </div>
                          </div>
                          <div className="row justify-content-center align-items-center">
                            <label for="province" className="fw-medium  text-end col-sm-5 my-1 col-form-label">PROVINCE :</label>
                            <div className="col-sm-5">
                              <input type="text" className="textField form-control" id="province" defaultValue="Bulacan" disabled readonly />
                            </div>
                          </div>
                          <div className="row justify-content-center align-items-center">
                            <label for="municipality" className="fw-medium  text-end col-sm-5 my-1 col-form-label">MUNICIPALITY :</label>
                            <div className="col-sm-5">
                              <input type="text" className="textField form-control" id="municipality" defaultValue="San Miguel" disabled readonly />
                            </div>
                          </div>
                          <div className="row justify-content-center align-items-center">
                            <label for="barangay" className="fw-medium  text-end col-sm-5 my-1 col-form-label">BARANGAY :</label>
                            <div className="col-sm-5">
                              <input type="text" className="textField form-control" id="barangay" {...register("barangay")} disabled readonly />
                            </div>
                          </div>

                          <div className="formSection"></div>
                          <h5 className="ms-4 fw-bold">Particulars</h5>
                          <div className="row justify-content-center align-items-center">
                            <label for="type" className="fw-medium  text-end col-sm-5 my-1 col-form-label"> ECOSYSTEM :</label>
                            <div className="col-sm-5">
                              <select className="textField form-select" id="type" {...register("cropType")} disabled readonly>
                                <option style={{ display: 'none' }}></option>
                                <option defaultValue="Irrigated">Irrigated</option>
                                <option defaultValue="Raised Field - lowland">Raised Field - lowland</option>
                                <option defaultValue="Raised Field - upland"> Raised Field - upland</option>
                              </select>
                            </div>
                          </div>
                          <div className="row justify-content-center align-items-center">
                            <label for="type" className="fw-medium  text-end col-sm-5 my-1 col-form-label"> CROP STAGE:</label>
                            <div className="col-sm-5">
                              <select className="textField form-select" id="type" {...register("cropStage")} disabled readonly>
                                <option style={{ display: 'none' }}></option>
                                <option defaultValue="Seedling">Seedling</option>
                                <option defaultValue="Planting">Planting</option>
                                <option defaultValue="Vegetative">Planting</option>
                                <option defaultValue="Reproductive">Reproductive</option>
                                <option defaultValue="Maturity">Maturity</option>
                              </select>
                            </div>
                          </div>
                          <div className="row justify-content-center align-items-center">
                            <label for="typeOfCrop" className="fw-medium  text-end col-sm-5 my-1 col-form-label"> AREA OF STANDING CROP :</label>
                            <div className="col-sm-5">
                              <input type="text" inputmode="numeric" className="textField form-control" id="typeOfCrop" {...register("cropArea")} disabled readonly />
                            </div>
                          </div>
                          <div className="row justify-content-center align-items-center">
                            <label for="typeOfCrop" className="fw-medium  text-end col-sm-5 my-1 col-form-label"> NUMBER OF FARMERS AFFECTED :</label>
                            <div className="col-sm-5">
                              <input type="text" inputmode="numeric" className="textField form-control" id="typeOfCrop" {...register("farmersCount")} disabled readonly />
                            </div>
                          </div>
                          <div className="row justify-content-center align-items-center">
                            <label for="type" className="fw-medium  text-end col-sm-5 my-1 col-form-label"> SEED TYPE :</label>
                            <div className="col-sm-5">
                              <select className="textField form-select" id="type" {...register("seedType")} disabled readonly>
                                <option style={{ display: 'none' }}></option>
                                <option defaultValue="Inbred">Inbred</option>
                                <option defaultValue="Hybrid">Hybrid</option>
                              </select>
                            </div>
                          </div>
                          <h5 className="ms-4 fw-semibold pt-4">Area Affected (HA.) SEEDLINGS (BAGS)</h5>
                          <div className="row justify-content-center align-items-center">
                            <label for="totallyDamaged" className="fw-medium  text-end col-sm-5 my-1 col-form-label"> TOTALLY DAMAGED :</label>
                            <div className="col-sm-5">
                              <div className="form-outline">
                                <input type="number" id="typeNumber" className="textField form-control" min="0" max="10" {...register("totallyDamaged")} disabled readonly />
                              </div>
                            </div>
                          </div>
                          <div className="row justify-content-center align-items-center">
                            <label for="partiallyDamaged" className="fw-medium  text-end col-sm-5 my-1 col-form-label"> PARTIALLY DAMAGED :</label>
                            <div className="col-sm-5">
                              <input type="text" className="textField form-control" id="partiallyDamaged" {...register("partiallyDamaged")} disabled readonly />
                            </div>
                          </div>
                          <div className="row justify-content-center align-items-center">
                            <label for="total" className="fw-medium  text-end col-sm-5 my-1 col-form-label">TOTAL :</label>
                            <div className="col-sm-5">
                              <input type="text" inputmode="numeric" className="textField form-control" id="total" {...register("totalDamaged")} disabled readonly />
                            </div>
                          </div>
                          <h5 className="ms-4 fw-semibold pt-4">Yield per Hectare (M.T)</h5>
                          <div className="row justify-content-center align-items-center">
                            <label for="beforeCalamity" className="fw-medium  text-end col-sm-5 my-1 col-form-label"> BEFORE CALAMITY :</label>
                            <div className="col-sm-5">
                              <input type="text" inputmode="numeric" className="textField form-control" id="beforeCalamity" {...register("yieldBefore")} disabled readonly />
                            </div>
                          </div>
                          <div className="row justify-content-center align-items-center">
                            <label for="afterCalamity" className="fw-medium  text-end col-sm-5 my-1 col-form-label"> AFTER CALAMITY :</label>
                            <div className="col-sm-5">
                              <input type="text" inputmode="numeric" className="textField form-control" id="afterCalamity" {...register("yieldAfter")} disabled readonly />
                            </div>
                          </div>
                          <div className="row justify-content-center align-items-center">
                            <label for="yieldLoss" className="fw-medium  text-end col-sm-5 my-1 col-form-label">YIELD LOSS(%) :</label>
                            <div className="col-sm-5">
                              <input type="text" inputmode="numeric" className="textField form-control" id="yieldLoss" {...register("yieldLoss")} disabled readonly />
                            </div>
                          </div>
                          <h5 className="ms-4 fw-semibold pt-4">Total Losses(Based on Cost of Input)</h5>
                          <div className="row justify-content-center align-items-center">
                            <label for="costOfInput" className="fw-medium  text-end col-sm-5 my-1 col-form-label"> COST OF INPUT/HA. :</label>
                            <div className="col-sm-5">
                              <input type="text" inputmode="numeric" className="textField form-control" id="costOfInput" {...register("inputCost")} disabled readonly />
                            </div>
                          </div>
                          <div className="row justify-content-center align-items-center">
                            <label for="totalValue" className="fw-medium  text-end col-sm-5 my-1 col-form-label">TOTAL :</label>
                            <div className="col-sm-5">
                              <input type="text" inputmode="numeric" className="textField form-control" id="totalValue" {...register("totalCost")} disabled readonly />
                            </div>
                          </div>

                          <h5 className="ms-4 fw-semibold pt-4">Disaster Damage Photo Documentation</h5>
                          <div className="attachPhoto">
                            <img src={`${process.env.REACT_APP_SERVER_URL}/${report?.data.imagePath}`} width="200" onClick={openModal} style={{ cursor: 'pointer' }} alt="Summary Report" />
                          </div>

                          <Modal
                            show={modalIsOpen}
                            onHide={closeModal}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                          >
                            <Modal.Header closeButton>
                              <Modal.Title className="modalTitle">Disaster Damage Image</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <img src={`${process.env.REACT_APP_SERVER_URL}/${report?.data.imagePath}`} alt="Enlarged" className="img-fluid" />
                            </Modal.Body>
                            <Modal.Footer>
                              <button className="btn btn-secondary" onClick={closeModal} id="buttons">
                                Close
                              </button>
                            </Modal.Footer>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* FLOATING INFO BUTTON */}
        <div className="floatPosition">
          <div
            style={{ backgroundColor: "#185D10" }}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            <i class="bi bi-lightbulb-fill floatIcon" />
          </div>
          {showTooltip && (
            <div className="customTooltip">
              <span className="tooltipText">
                You can download a pdf copy of this report using the
                download button.
              </span>
            </div>
          )}
        </div>
      </div>

    </>
  )
}

export default MROHistoryForm