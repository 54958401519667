import React, { useState } from "react";
// OTHER IMPORTS
import { Link, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { format, isBefore, startOfDay } from "date-fns";

// COMPONENTS IMPORTS
import Navbar from "../navbar/BAFCNavbar";
import SideBarnav from "../sidebar/BAFCSidebar";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { useFetchBarangayDistributionScheduleQuery, useReceiveBarangayRehabAidMutation } from "../utils/features/distribution/distributionApiSlice";

const TITLE = "GoFarmers | Scheduled Dates";

const BAFCInbox = () => {
  const [isClicked, setIsClicked] = useState(false);
  const [showModal2, setshowModal2] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showTooltip2, setShowTooltip2] = useState(false);

  const [target, setTarget] = useState(null);

  const modalClose2 = () => {
    setTarget(null);
    setshowModal2(false);
  };

  const modalShow2 = (id) => {
    setTarget(id);
    setshowModal2(true);
  };

  const handleButtonClick = () => {
    setIsClicked(!isClicked);
  };

  const [searchParams, setSearchParams] = useSearchParams({
    sortField: "",
  });

  const {
    data: distributionSchedules,
    isLoading: distributionSchedulesIsLoading,
  } = useFetchBarangayDistributionScheduleQuery({
    sortField: searchParams.get("sortField"),
  });

  const handleSort = (sortField) =>
    setSearchParams(
      (searchParams) => {
        searchParams.set("sortField", sortField);

        return searchParams;
      },
      { replace: true }
    );

  const [receiveBarangayRehab] = useReceiveBarangayRehabAidMutation();

  async function handleReceiveDistribution() {
    modalClose2();

    const receivedBarangayRehab = await receiveBarangayRehab({
      id: target,
    });

    if (receivedBarangayRehab.error) {
      // console.error("Failed to receive rehab aid.");
      return;
    }
    // console.log("Successfully received rehab aid.");
  }

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Navbar handleButtonClick={handleButtonClick} />
      <div className="container-fluid" id="tryFix">
        <div className="row flex-nowrap overflow-hidden">
          <SideBarnav />
          <div
            className="col overflow-x-hidden"
            id={`${isClicked ? "tryMove" : ""}`}
          >
            <style>
              {`
                  #tryMove {
                    margin-left: ${isClicked ? "380px" : "0px"};       
                  }
                `}
            </style>
            {/* SECTION TITLE */}
            <h1 className="section">Scheduled Distribution Dates</h1>

            {/* BUTTONS */}
            <div className="topButtons">
              <div className="row buttonContainer">
                <dic className="singleContainer">
                  <button
                    className="btn btn-secondary dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    id="buttons"
                  >
                    SORT BY
                  </button>
                  <ul className="dropdown-menu" id="dropDown">
                    <li>
                      <Link
                        className="dropdown-item"
                        to=""
                        onClick={() => handleSort("-createdAt")}
                      >
                        Latest to Oldest
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to=""
                        onClick={() => handleSort("createdAt")}
                      >
                        Oldest to Latest
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to=""
                        onClick={() => handleSort("receivedAt")}
                      >
                        Received
                      </Link>
                    </li>
                  </ul>
                </dic>
              </div>
            </div>

            {/* GENERATED BARANGAY REPORTS */}
            <div className="row mt-4 tableContainer">
              <div
                className="col-11 col-lg-11 overflow-auto p-0"
                id="tableBorder"
              >
                <table
                  className="table mb-0 text-center table-hover align-middle"
                  style={{ borderColor: "#185D10" }}
                >
                  <thead>
                    <tr id="thead1">
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <thead>
                    <tr className="align-middle" id="thead1">
                      <th scope="col">DISASTER NAME</th>
                      <th scope="col">DISASTER TYPE</th>
                      <th scope="col">DATE OF OCCURENCE</th>
                      <th scope="col">CROP STAGE</th>
                      <th scope="col">SCHEDULED DATE</th>
                      <th scope="col">STATUS</th>
                    </tr>
                  </thead>
                  <thead>
                    <tr id="thead1">
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {!distributionSchedulesIsLoading &&
                      distributionSchedules?.data.length ? (
                      distributionSchedules.data.map((distributionSchedule) => (
                        <tr
                          className="tableData"
                          key={distributionSchedule._id}
                        >
                          <td>
                            {
                              distributionSchedule.summaryReport.initialReport?.disasterName || "Unnamed Disaster"
                            }
                          </td>
                          <td>
                            {
                              distributionSchedule.summaryReport.initialReport?.disasterType
                            }
                          </td>
                          <td>
                            {format(
                              new Date(
                                distributionSchedule.summaryReport.initialReport?.disasterDate
                              ),
                              "MM-dd-yyyy"
                            )}
                          </td>
                          <td>
                            {distributionSchedule.summaryReport.cropStage}
                          </td>
                          <td>
                            {format(
                              new Date(distributionSchedule.scheduledAt),
                              "MM-dd-yyyy"
                            )}
                          </td>
                          <td>
                            <div className="d-flex flex-row justify-content-center">
                              <div>
                                {distributionSchedule?.receivedAt ? (
                                  <button
                                    className="btn btn-primary statusButton"
                                    id="buttonInitial"
                                    disabled
                                  >
                                    RECEIVED
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-primary statusButton"
                                    id="buttonReceive"
                                    onClick={() =>
                                      modalShow2(distributionSchedule._id)
                                    }
                                    disabled={isBefore(
                                      new Date(),
                                      startOfDay(
                                        new Date(
                                          distributionSchedule.scheduledAt
                                        )
                                      )
                                    )}
                                  >
                                    SET AS RECEIVED
                                  </button>
                                )}
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="tableData">
                        <td className="text-center" colSpan={100}>
                          There is no distribution schedule available.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* FLOATING INFO BUTTON */}
      {!distributionSchedulesIsLoading && distributionSchedules?.data.length ? (
        distributionSchedules.data.map((distributionSchedule) => (
          <div className="floatPosition">
            <div
              style={{ backgroundColor: "#185D10" }}
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            >
              <i class="bi bi-lightbulb-fill floatIcon" />
            </div>
            {showTooltip && (
              <div className="customTooltip2">
                <span className="tooltipText">
                  Use this modal to check the scheduled rehabilitation aid
                  distribution date in your barangay. Mark "received" when the
                  aid reaches your barangay office.
                </span>
              </div>
            )}
          </div>
        ))
      ) : (
        <div className="floatPosition">
          <div
            style={{ backgroundColor: "#185D10" }}
            onMouseEnter={() => setShowTooltip2(true)}
            onMouseLeave={() => setShowTooltip2(false)}
          >
            <i class="bi bi-lightbulb-fill floatIcon" />
          </div>
          {showTooltip2 && (
            <div className="customTooltip">
              <span className="tooltipText">
                Wait for the Municipal Report Officer to schedule date of
                rehabilitation aid distribution to your barangay.
              </span>
            </div>
          )}
        </div>
      )}
      {/* MODAL FOR RECEIVED BUTTON*/}
      <Modal
        show={showModal2}
        onHide={modalClose2}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body className="py-4">
          <div className="row mb-4 text-center" style={{ color: "#185D10" }}>
            <h1>
              <i className="bi bi-question-circle"></i>
            </h1>
            <h5 className="fw-bold">
              ARE YOU SURE <br /> YOU WANT TO MARK THIS AS RECEIVED?
            </h5>
            <h6 className="fw-normal">
              <i class="bi bi-lightbulb-fill bulb"></i>
              Once you mark this as received you can't change the status.
            </h6>
          </div>
          <div className="row text-center">
            <div className="col text-end">
              <Button
                className="m-auto"
                onClick={modalClose2}
                id="modalCancelbtn"
              >
                Cancel
              </Button>
            </div>
            <div className="col text-start">
              <Button
                className="m-auto"
                onClick={handleReceiveDistribution}
                id="modalConfirmbtn"
              >
                Confirm
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BAFCInbox;
