import React, { useState, useEffect } from "react";
// OTHER IMPORTS
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useNavigate } from "react-router-dom"

// COMPONENTS
import Navbar from "../navbar/MRONavbar";
import SideBarnav from "../sidebar/MROSidebar";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { useGetUserQuery, useUpdateProfileMutation } from "../utils/features/users/usersApiSlice"
import { useForm } from "react-hook-form"
import { toast } from 'react-toastify'

const TITLE = 'GoFarmers | Profile Settings'

const MOBILE_REGEX = /^09\d{9}$/
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%])[A-Za-z\d!@#$%]{8,}$/;


const MROProfileSettings = () => {
  const [isClicked, setIsClicked] = useState(false);

  const handleButtonClick = () => {
    setIsClicked(!isClicked);
  };

  const [showModal, setshowModal] = useState(false);

  const modalClose = () => setshowModal(false);
  const modalShow = () => setshowModal(true);

  const navigate = useNavigate()

  const [updateProfile] = useUpdateProfileMutation()
  const { data: user, refetch } = useGetUserQuery()

  const { handleSubmit, register, setValue, formState: { errors } } = useForm()

  useEffect(() => {
    if (user) {
      setValue('firstName', user.user.firstName || '')
      setValue('lastName', user.user.lastName || '')
      setValue('email', user.user.email || '')
      setValue('mobile', user.user.mobile || '')
      setValue('password', '')
    }
  }, [user, setValue])

  const onSubmit = async (data) => {
    const updatedFields = {
      id: user.user._id,
    }

    if (data.mobile !== user.user.mobile) {
      updatedFields.mobile = data.mobile
    }

    if (data.oldPassword && data.oldPassword.trim() !== "") {
      updatedFields.oldPassword = data.oldPassword;
      if (data.newPassword && data.newPassword.trim() !== "") {
        if (!PWD_REGEX.test(data.newPassword)) {
          toast.error("Password must be at least 8 characters and must include letters, numbers, and special characters like !@#$%")
          return
        }
        if (data.oldPassword === data.newPassword) {
          toast.error("New password must be different from the old password");
          return;
        }
        if (data.newPassword === data.confirmPassword) {
          updatedFields.password = data.newPassword
        } else {
          toast.error("New password and confirmation password do not match")
          return
        }
      } else {
        toast.error("New password is required")
        return
      }
    }

    if (Object.keys(updatedFields).length === 1) {
      toast.info("No changes detected")
      return;
    }

    try {
      const response = await updateProfile(updatedFields).unwrap()

      if (response) {
        toast.success("Updated successfully")
        refetch()
        navigate("/mro-dashboard")
      } else {
        throw new Error("Failed to update")
      }
    } catch (err) {
      if (err && err.status === 400) {
        toast.error("Old password is incorrect")
      } else {
        toast.error("Failed to update")
      }
      // console.log(err)
    }
  }

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Navbar handleButtonClick={handleButtonClick} />
      <div className="container-fluid" id="tryFix">
        <div className="row flex-nowrap overflow-hidden">
          <SideBarnav />

          <div className="col overflow-x-hidden" id={`${isClicked ? 'tryMove' : ''}`}>
            <style>
              {`
                #tryMove {
                margin-left: ${isClicked ? '380px' : '0px'};             
                }
            `}
            </style>
            {/* SECTION TITLE */}
            <h1 className="section">Profile Information</h1>

            <div className="col-md-3">
              <Link to="/mro-dashboard"><button className="btn btn-success" id="buttons"><i class="bi bi-arrow-left"></i> BACK</button></Link>
            </div>
            <div className="container-fluid" id="customContainer">
              <div className="row">
                <div id="textSection">

                  {/* {inputs && */}
                  <form className="ps-4 pe-4 my-4">
                    <div className="row justify-content-center align-items-center">
                      <label htmlFor="firstName" className="formLabel col-sm-5 my-1 col-form-label text-end">FIRST NAME :</label>
                      <div className="col-sm-6">
                        <input type="text" className="textField form-control" id="firstName" name="firstName" {...register("firstName")} disabled readonly />
                      </div>
                    </div>
                    <div className="row justify-content-center align-items-center">
                      <label htmlFor="lastName" className="formLabel col-sm-5 my-1 col-form-label text-end">LAST NAME :</label>
                      <div className="col-sm-6">
                        <input type="text" className="textField form-control" id="lastName" name="lastName" {...register("lastName")} disabled readonly />
                      </div>
                    </div>
                    <div className="row justify-content-center align-items-center">
                      <label htmlFor="email" className="formLabel col-sm-5 my-1 col-form-label text-end">EMAIL :</label>
                      <div className="col-sm-6">
                        <input type="email" className="textField form-control" id="email" name="email" {...register("email")} disabled readonly />
                      </div>
                    </div>
                    <div className="row justify-content-center align-items-center">
                      <label htmlFor="mobileNumber" className="formLabel col-sm-5 my-1 col-form-label text-end">MOBILE NUMBER :</label>
                      <div className="col-sm-6">
                        <input type="number" className="textField form-control" id="mobileNumber" {...register("mobile", {
                          pattern: {
                            value: MOBILE_REGEX,
                            message: "Invalid mobile number. Mobile number should start with 09 and be 11 digits long."
                          }
                        })} />
                        {errors.mobile && <p className="errmsg">{errors.mobile.message}</p>}
                      </div>
                    </div>
                    <div className="row justify-content-center align-items-center">
                      <label htmlFor="oldPassword" className="formLabel col-sm-5 my-1 col-form-label text-end">OLD PASSWORD:</label>
                      <div className="col-sm-6">
                        <input type="password" className="textField form-control" id="oldPassword" {...register("oldPassword")} />
                      </div>
                    </div>
                    <div className="row justify-content-center align-items-center">
                      <label htmlFor="newPassword" className="formLabel col-sm-5 my-1 col-form-label text-end">NEW PASSWORD:</label>
                      <div className="col-sm-6">
                        <input type="password" className="textField form-control" id="newPassword" {...register("newPassword")} />
                      </div>
                    </div>
                    <div className="row justify-content-center align-items-center">
                      <label htmlFor="confirmPassword" className="formLabel col-sm-5 my-1 col-form-label text-end">CONFIRM PASSWORD:</label>
                      <div className="col-sm-6">
                        <input type="password" className="textField form-control" id="confirmPassword" {...register("confirmPassword")} />
                      </div>
                    </div>
                    <div className="d-flex justify-content-end">
                      <div>
                        <Link to='/mro-dashboard'>
                          <button type="button" className="btn btn-primary me-3" id="cancelButton">CANCEL</button>
                        </Link>
                      </div>
                      <button type="button" onClick={modalShow} className="btn btn-primary" id="buttons">UPDATE</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >


      <Modal
        show={showModal}
        onHide={modalClose}
        backdrop="static"
        keyboard={false}
        centered
      >

        <Modal.Body className="py-4">
          <div className="row mb-4 text-center" style={{ color: '#185D10' }}>
            <h1><i className="bi bi-question-circle"></i></h1>
            <h5 className="fw-bold">ARE YOU SURE <br /> YOU WANT TO UPDATE THIS ACCOUNT?</h5>
          </div>
          <div className="row text-center">
            <div className="col text-end">
              <Button className="m-auto" onClick={modalClose} id="modalCancelbtn">
                Cancel
              </Button>
            </div>
            <div className="col text-start">
              <Button className="m-auto" id="modalConfirmbtn" onClick={handleSubmit(onSubmit)}>Update</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default MROProfileSettings