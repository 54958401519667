import { Link } from "react-router-dom";
import navbarlogo from "./navbarlogo.webp";

import useAuth from "../utils/hooks/useAuth"

const SADNavbar = ({ handleButtonClick }) => {
  const { firstName } = useAuth()

  return (
    <div>
      <nav className="navbar navbar-expand-md navbar-light bg-light shadow fixed-top overflow-hidden" style={{ height: "75px" }}>
        <button onClick={handleButtonClick} type="button" className="btn btn-lg btn-block" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
          <i className="fs-4 bi-list"></i>
        </button>
        <Link className="navbar-brand" to="/mro-dashboard">
          <img src={navbarlogo} alt="GoFarmers" style={{ width: "50%" }} />
        </Link>
        <div className="collapse navbar-collapse justify-content-end">
          <div className="navbar-nav">
            <div className="d-flex align-items-center fs-5 pe-4 fw-semibold" style={{ color: "#185D10" }}>
              <span className="fw-semibold d-none d-sm-inline ms-2">Hello, &nbsp; </span> <span className="fw-bold">{firstName}</span>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default SADNavbar;
