import React, { useState } from "react";
// ANALYTICS VISUAL CHARTS AND MAP
import { BarChart } from "../components/charts/barChart";

import { useSearchParams } from "react-router-dom"
import { useForm } from "react-hook-form"
import { useFetchDashboardDataQuery } from "../utils/features/dashboard/dashboardApiSlice"

// OTHER IMPORTS
import { Helmet } from 'react-helmet';
import Navbar from "../navbar/MRONavbar";
import SideNav from "../sidebar/MROSidebar";

const TITLE = 'GoFarmers | MRO Analytics'

const MROAnalytics = () => {
  const [isClicked, setIsClicked] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleButtonClick = () => {
    setIsClicked(!isClicked);
  };

  const MIN_YEAR_VALUE = 2000;

  const DEFAULT_YEAR = new Date().getFullYear()
  const DEFAULT_MONTH = new Date().getMonth()

  const [searchParams, setSearchParams] = useSearchParams({
    month: DEFAULT_MONTH,
    year: DEFAULT_YEAR
  })

  const { register, handleSubmit } = useForm()

  const handleFilter = (data) => setSearchParams((prevSearchParams) => {
    prevSearchParams.set("month", data.month)
    prevSearchParams.set("year", data.year)
    return prevSearchParams
  }, { replace: true })

  const { data, isLoading: dataIsLoading } = useFetchDashboardDataQuery({
    role: "analytics/mro",
    month: searchParams.get("month"),
    year: searchParams.get("year")
  })

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Navbar handleButtonClick={handleButtonClick} />
      <div className="container-fluid" id="tryFix">
        <div className="row flex-nowrap overflow-hidden">
          <SideNav />
          <div className="col overflow-x-hidden" id={`${isClicked ? 'tryMove' : ''}`}>
            <style>
              {`
                #tryMove {
                  margin-left: ${isClicked ? '380px' : '0px'};
                  
                }
              `}
            </style>
            {/* SECTION TITLE */}
            <h1 className="section">San Miguel, Bulacan Province Analytics</h1>

            {/* FILTER MONDTH AND YEAR */}
            <div>
              <div className="row justify-content-center mt-0 px-auto pb-0 ">
                <div className="col-11 col-lg-10 overflow-auto p-0">
                  <div className="row mx-0 mt-0">
                    <h5 className="sortLabel">Choose Month and Year:</h5>
                  </div>
                  <form className="sortContainer row mx-0 pb-0 g-4" onChange={handleSubmit(handleFilter)}>
                    <div className="col-12">
                      <div className="row">
                        <div className="col-12 col-lg-5">
                          <select {...register("year")} defaultValue={DEFAULT_YEAR} className="form-select textField" autoFocus>
                            <option value="" disabled>Select a Year</option>

                            {
                              [...Array(new Date().getFullYear() - MIN_YEAR_VALUE).keys()]
                                .map((x) => x + 1)
                                .toReversed()
                                .map((year) => (
                                  <option value={MIN_YEAR_VALUE + year} key={`year-${MIN_YEAR_VALUE + year}`}>{MIN_YEAR_VALUE + year}</option>
                                ))
                            }
                            <option value={MIN_YEAR_VALUE}>{MIN_YEAR_VALUE}</option>
                          </select>

                        </div>
                        <div className="col-12 col-lg-7">
                          <select {...register("month")} defaultValue={DEFAULT_MONTH} className="form-select textField">
                            <option value="" disabled>Select a Month</option>
                            {
                              [
                                "January", "February", "March", "April", "May", "June",
                                "July", "August", "September", "October", "November", "December"
                              ]
                                .map((month, idx) => (
                                  <option value={idx} key={`month-${idx}`}>{month}</option>
                                ))
                            }
                          </select>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/* ANALYTICS CARD STATS */}
            <div className="row justify-content-center mt-0 mx-3 mb-3">
              <div className="analyticsRow mt-2">
                <div className="analytics1">
                  <p className="analyticsNumber">{data?.data.summaryReportsCount}</p>
                  <p className="analyticsDesc">Barangay Reports</p>
                </div>
                <div className="analytics1">
                  <p className="analyticsNumber">{data?.data.farmerFormsCount}</p>
                  <p className="analyticsDesc">Farmer Reports</p>
                </div>
                <div className="analytics1">
                  <p className="analyticsNumber">{data?.data.totalYieldLoss || 0}%</p>
                  <p className="analyticsDesc">Total Losses</p>
                </div>
                <div className="analytics1">
                  <p className="analyticsNumber">{data?.data.totalDamagedArea}</p>
                  <p className="analyticsDesc">Total Seedling Bags Affected</p>
                </div>
              </div>
            </div>

            {/* BAR CHART */}
            <div className="row justify-content-center mt-0 mb-3">
              <div className="col-10  p-0 overflow-hidden" id="chartBorder">
                <div className=" p-0">
                  <h4 className="tableCaption text-center m-0 p-3 fw-bold text-white">TOTAL DAMAGED AREA IN HA. FOR THE PAST SIX (6) MONTHS</h4>
                  <table className="table text-center table-hover align-middle" style={{ borderColor: '#185D10' }}>
                    <thead>
                      <tr className="align-middle" id="thead1">
                        <th scope="col">SAN MIGUEL, BULACAN</th>
                      </tr>
                    </thead>
                    <tbody>
                      <div className="chartHeight p-2 row overflow-auto">
                        {
                          !dataIsLoading && data?.data && (
                            <BarChart data={data?.data.totalAreaPastSixMonths} />
                          )
                        }
                      </div>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="row justify-content-center mt-0  mb-3">
              <div className="col-10  p-0 overflow-hidden" id="chartBorder">
                <div className=" p-0">
                  <h4 className="tableCaption text-center m-0 p-3 fw-bold text-white">TOTAL YIELD LOSS FOR THE PAST SIX (6) MONTHS</h4>
                  <table className="table text-center table-hover align-middle" style={{ borderColor: '#185D10' }}>
                    <thead>
                      <tr className="align-middle" id="thead1">
                        <th scope="col">SAN MIGUEL, BULACAN</th>
                      </tr>
                    </thead>
                    <tbody>
                      <div className="chartHeight p-2 row overflow-auto">
                        {
                          !dataIsLoading && data?.data && (
                            <BarChart data={data?.data.averageYieldLossPastSixMonths} />
                          )
                        }
                      </div>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="row justify-content-center mt-0  mb-3">
              <div className="col-10  p-0 overflow-hidden" id="chartBorder">
                <div className=" p-0">
                  <h4 className="tableCaption text-center m-0 p-3 fw-bold text-white">TOTAL COST IN PHP FOR THE PAST SIX (6) MONTHS</h4>
                  <table className="table text-center table-hover align-middle" style={{ borderColor: '#185D10' }}>
                    <thead>
                      <tr className="align-middle" id="thead1">
                        <th scope="col">SAN MIGUEL, BULACAN</th>
                      </tr>
                    </thead>
                    <tbody>
                      <div className="chartHeight p-2 row overflow-auto">
                        {
                          !dataIsLoading && data?.data && (
                            <BarChart data={data?.data.totalCostPastSixMonths} />
                          )
                        }
                      </div>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="row justify-content-center mt-0  mb-5">
              <div className="col-10  p-0 overflow-hidden" id="chartBorder">
                <div className=" p-0">
                  <h4 className="tableCaption text-center m-0 p-3 fw-bold text-white">TOTAL NO. OF SUBMITTED REPORTS FOR THE PAST SIX (6) MONTHS</h4>
                  <table className="table text-center table-hover align-middle" style={{ borderColor: '#185D10' }}>
                    <thead>
                      <tr className="align-middle" id="thead1">
                        <th scope="col">SAN MIGUEL, BULACAN</th>
                      </tr>
                    </thead>
                    <tbody>
                      <div className="chartHeight p-2 row overflow-auto">
                        {
                          !dataIsLoading && data?.data && (
                            <BarChart data={data?.data.totalSubmittedReports} />
                          )
                        }
                      </div>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* FLOATING INFO BUTTON */}
        <div className="floatPosition">
          <div
            style={{ backgroundColor: "#185D10" }}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            <i class="bi bi-lightbulb-fill floatIcon" />
          </div>
          {showTooltip && (
            <div className="customTooltip2">
              <span className="tooltipText">
                Use this modal to monitor the generated and accummulated reports damage data
                of San Miguel, Bulacan.
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default MROAnalytics



