import React from "react";
import { Helmet } from 'react-helmet';
import gofarmers from '../images/mao_logo.webp';
import paologo from '../images/pao_logo.webp';
import smlogo from '../images/sanmiguel.webp';
import { Link } from 'react-router-dom';
const TITLE = 'GoFarmers | Sign In'

const Terms = () => {


  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <div className="bg">
        <section className="vh-100">
          <div className="container py-7 h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col col-xl-8">
                <div className="card-transparent border-0 py-5 px-3" id="cardGlass">
                  <div className="row g-0">
                    <div className="col-lg-6 d-none d-lg-block text-center ps-0 pt-4">
                      <img src={gofarmers} alt="Municipal Agricultural Office" id="maoLogo" />
                      <p className="fst-italic" id="inCollab">In Collaboration with:</p>
                      <div className="row justify-content-center g-0">
                        <div className="col-4">
                          <img src={smlogo} alt="Municipality of San Miguel" style={{ width: "65%" }} />
                        </div>
                        <div className="col-4">
                          <img src={paologo} alt="Provincial Agricultural Office" style={{ width: "65%" }} />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-10 col-lg-6 d-flex align-items-center mx-auto">
                      <div className="card-body text-black">
                        <div className="scroll-bg">
                          <div className="scroll-div">
                            <div className="scroll-object">
                              <h2 className="fw-bold">GoFarmers Web System Terms and Conditions</h2>
                              <p className=" text-white">
                                These Terms and Conditions ("Terms") are a legal agreement between you and the administrators of the GoFarmers mobile and web system, operated by the Municipal Agricultural Office of San Miguel, Bulacan, and the Provincial Agricultural Office (hereinafter referred to as "GoFarmers," "we," "us," or "our"). By accessing or using the GoFarmers mobile and web system, you acknowledge and agree to be bound by these Terms. If you do not agree with these Terms, please refrain from using the system.
                              </p>
                              <h2 className="fw-bold ">1. Acceptance of Terms</h2>
                              <p className=" text-white">
                                By using GoFarmers, you accept and agree to comply with these Terms and any additional policies, guidelines, or rules that are provided to you through the system.
                              </p>
                              <h2 className="fw-bold ">2. Use of the System</h2>
                              <h3 className="fw-bold ">2.1. Eligibility</h3>
                              <p className=" text-white">
                                You must be at least 18 years of age to use the GoFarmers system. If you are using the system on behalf of an organization, you represent and warrant that you have the authority to do so.
                              </p>
                              <h3 className="fw-bold ">2.2. User Accounts</h3>
                              <p className=" text-white">
                                When using the GoFarmers system, you may be required to create an account. You are responsible for keeping your account information accurate, confidential, and up to date. You must not share your account credentials with anyone else.
                              </p>
                              <h3 className="fw-bold">2.3. Prohibited Activities</h3>
                              <p className=" text-white">
                                You agree not to engage in any of the following activities:
                              </p>
                              <ul className="text-white">
                                <li>Use the system for any illegal, unauthorized, or improper purposes.</li>
                                <li>Attempt to gain unauthorized access to the system, or any part of it.</li>
                                <li>Post, transmit, or share any content that is unlawful, threatening, harmful, abusive, defamatory, or otherwise objectionable.</li>
                                <li>Attempt to disrupt or interfere with the operation of the system.</li>
                              </ul>
                              <h2 className="fw-bold ">3. Intellectual Property</h2>
                              <p className=" text-white">
                                The GoFarmers system, including its content, design, and underlying technology, is protected by intellectual property rights. You agree not to copy, modify, distribute, or otherwise use any part of the system without prior written permission from GoFarmers.
                              </p>
                              <h2 className="fw-bold ">4. Privacy</h2>
                              <p className=" text-white">
                                Your use of GoFarmers is also governed by our Privacy Policy. Please review this policy to understand how we collect, use, and protect your personal information.
                              </p>
                              <h2 className="fw-bold ">5. Disclaimers</h2>
                              <p className=" text-white">
                                GoFarmers is provided on an "as-is" basis, without any warranties or guarantees. We do not guarantee the accuracy or reliability of the information on the system. You use the system at your own risk.
                              </p>
                              <h2 className="fw-bold ">6. Limitation of Liability</h2>
                              <p className=" text-white">
                                In no event shall GoFarmers, its administrators, or its affiliates be liable for any indirect, special, consequential, or incidental damages, including but not limited to loss of data, revenue, or profits, arising out of or in connection with your use of the system.
                              </p>
                              <h2 className="fw-bold ">7. Termination</h2>
                              <p className=" text-white">
                                We reserve the right to terminate or suspend your access to GoFarmers at our discretion, without notice. You may also terminate your account at any time. All provisions of these Terms that would naturally survive termination, including but not limited to intellectual property rights, disclaimers, and limitations of liability, shall continue to be in effect.
                              </p>
                              <h2 className="fw-bold ">8. Governing Law</h2>
                              <p className=" text-white">
                                These Terms are governed by and construed in accordance with the laws of the Philippines.
                              </p>
                              <h2 className="fw-bold ">9. Changes to Terms</h2>
                              <p className=" text-white">
                                We may update these Terms from time to time. You will be notified of any changes, and it is your responsibility to review the updated Terms. Your continued use of the GoFarmers system after any changes constitute your acceptance of the updated Terms.
                              </p>
                              <h2 className="fw-bold ">10. Contact Information</h2>
                              <p className=" text-white">
                                If you have any questions or concerns regarding these Terms, please contact us at the following email address:
                              </p>
                              <p className=" text-white">Email: gofarmersbulacan@gmail.com</p>
                              <p className=" text-white">
                                By using GoFarmers, you acknowledge that you have read, understood, and agree to these Terms and Conditions.
                              </p>

                            </div>
                          </div>
                        </div>
                        <div className="singleContainer">
                          <Link to="/"><button className="btn btn-success me-4" id="buttons"><i class="bi bi-arrow-left"></i> BACK</button></Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default Terms