import { createSlice } from "@reduxjs/toolkit"

const token = sessionStorage.getItem("key")
// const user = sessionStorage.getItem("user)

const authSlice = createSlice({
  name: "auth",
  initialState: 
  {
    token: token ? token : null,
    // user: user ? user : null,
  }
  ,
  reducers: {
    setCredentials: (state, action) => {
      const { accessToken, userInfo } = action.payload
      state.token = accessToken
      state.user = userInfo
      sessionStorage.setItem("key", accessToken)
      // sessionStorage.setItem("user", JSON.stringify(userInfo))
    },
    logOut: (state, action) => {
      state.token = null
      state.user = null
      sessionStorage.removeItem("key")
      // sessionStorage.removeItem("user")
    },
  },
})

export const { setCredentials, logOut } = authSlice.actions

export default authSlice.reducer

export const selectUser = (state) => state.auth.user.user
export const selectCurrentToken = (state) => state.auth.token