import { apiSlice } from "../../app/api/apiSlice"
import { logOut } from "./authSlice"

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    signin: builder.mutation({
      query: (credentials) => ({
        url: "/auth",
        method: "POST",
        body: credentials,
      }),
    }),
    forgot: builder.mutation({
      query: (initialUserData) => ({
        url: "/auth/forgot-password",
        method: "POST",
        body: {
          ...initialUserData,
        },
      }),
    }),
    reset: builder.mutation({
      query: ({ id, token, newPassword }) => {
        return {
          url: `/auth/reset/${id}/${token}`,
          method: "PUT",
          body: {
            newPassword: newPassword,
          },
        }
      },
      invalidatesTags: ["User"],
    }),
    regenerate: builder.mutation({
      query: () => ({
        url: "/auth/regenerate",
        method: "POST",
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: "/auth/logout",
        method: "POST",
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(logOut())
          setTimeout(() => {
            dispatch(apiSlice.util.resetApiState())
          }, 1000)
        } catch (err) {
          console.log(err)
        }
      },
    }),
  }),
})

export const {
  useSigninMutation,
  useForgotMutation,
  useResetMutation,
  useRegenerateMutation,
  useLogoutMutation,
} = authApiSlice
