
import React, { useEffect, useState } from "react";

// OTHER IMPORTS
import { Link, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { format } from "date-fns"

import { CSVLink } from "react-csv"

import { useFetchPendingSummaryReportsQuery } from "../utils/features/summaryReports/summaryReportsApiSlice"

// COMPONENTS IMPORTS
import Navbar from "../navbar/ATNavbar";
import SideBarnav from "../sidebar/ATSidebar";

const TITLE = 'GoFarmers | Generated Reports'

const ATGeneratedReports = () => {
  const [isClicked, setIsClicked] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showTooltip2, setShowTooltip2] = useState(false);

  const handleButtonClick = () => {
    setIsClicked(!isClicked);
  };

  const [searchParams, setSearchParams] = useSearchParams({
    sortField: ""
  })

  const { data: pendingReports, isLoading: pendingReportsIsLoading } = useFetchPendingSummaryReportsQuery({
    sortField: searchParams.get("sortField")
  })

  const handleSort = (sortField) => setSearchParams((searchParams) => {
    searchParams.set("sortField", sortField)

    return searchParams
  })

  const [csvData, setCsvData] = useState([[]])

  useEffect(() => {
    if (!pendingReportsIsLoading && pendingReports?.data && pendingReports.data.length) {
      // console.log(pendingReports.data)

      setCsvData([
        [
          "reportId",
          "barangay",
          "disasterDate",
          "farmersCount",
          "cropArea",
          "cropStage",
          "yieldLoss (%)",
          "totalDamaged (HA)",
          "status"
        ],
        ...(pendingReports.data.map((report) => ([
          report._id,
          report.barangay,
          format(new Date(report.initialReport.disasterDate), "yyyy-MM-dd"),
          report.farmersCount,
          report.cropArea,
          report.cropStage,
          report.yieldLoss,
          report.totalDamaged,
          report.status
        ])))
      ])
    }
  }, [pendingReportsIsLoading, pendingReports])

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Navbar handleButtonClick={handleButtonClick} />
      <div className="container-fluid" id="tryFix">
        <div className="row flex-nowrap overflow-hidden">
          <SideBarnav />
          <div className="col overflow-x-hidden" id={`${isClicked ? 'tryMove' : ''}`}>
            <style>
              {`
                  #tryMove {
                    margin-left: ${isClicked ? '380px' : '0px'};       
                  }
                `}
            </style>
            {/* SECTION TITLE */}
            <h1 className="section">Generated Summary Reports</h1>

            {/* BUTTONS */}
            <div className="topButtons">
              <div className="column buttonContainer2">
                <div className="singleContainer2">
                  <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" id="buttons">
                    SORT BY
                  </button>
                  <ul className="dropdown-menu" id="dropDown">
                    <li><Link className="dropdown-item" to="" onClick={() => handleSort("barangay")}>Barangay</Link></li>
                    <li><Link className="dropdown-item" to="" onClick={() => handleSort("-createdAt")}>Latest to Oldest</Link></li>
                    <li><Link className="dropdown-item" to="" onClick={() => handleSort("createdAt")}>Oldest to Latest</Link></li>
                  </ul>
                </div>
                <div className="singleContainer">
                  <CSVLink className="btn btn-secondary" data={csvData} filename="agritech_summary-reports" id="buttons">DOWNLOAD CSV</CSVLink>
                </div>
              </div>
            </div>

            {/* GENERATED BARANGAY REPORTS */}
            <div className="row mt-4 tableContainer">
              <div className="col-11 col-lg-11 overflow-auto p-0" id="tableBorder">
                <table className="table mb-0 text-center table-hover align-middle" style={{ borderColor: '#185D10' }}>
                  <thead>
                    <tr id="thead1">
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                    <tr className="align-middle" id="thead1">
                      <th scope="col">BARANGAY</th>
                      <th scope="col">DATE OF OCCURENCE</th>
                      <th scope="col">NUMBER OF AFFECTED FARMERS</th>
                      <th scope="col">AREA OF STANDING 	CROP</th>
                      <th scope="col">STAGE OF CROP DEVELOPMENT</th>
                      <th scope="col">YIELD LOSS(%)</th>
                      <th scope="col">AREA AFFECTED</th>
                      <th scope="col">STATUS</th>
                      <th scope="col">ACTION</th>
                    </tr>
                    <tr id="thead1">
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      !pendingReportsIsLoading && pendingReports?.data.length ? pendingReports.data.reverse().map((pendingReport) => (
                        <tr className="tableData">
                          <td>{pendingReport.barangay}</td>
                          <td>{format(new Date(pendingReport.initialReport.disasterDate), "MM-dd-yyyy")}</td>
                          <td>{pendingReport.farmersCount}</td>
                          <td>{pendingReport.cropArea}</td>
                          <td>{pendingReport.cropStage}</td>
                          <td>{pendingReport.yieldLoss}%</td>
                          <td>{pendingReport.totalDamaged}</td>
                          <td>
                            <div className="d-flex flex-row justify-content-center">
                              <div>
                                <Link to={`/agritech-damaged-form/${pendingReport._id}`} className="btn btn-primary py-2 mx-auto btn-sm" id="editButton"><i className="bi fs-4 bi-pencil-square"></i></ Link>
                              </div>
                            </div>
                          </td>
                          <td>
                            <button className="btn btn-primary statusButton px-3 disabled" id="buttons" aria-disabled="true">
                              {pendingReport.status === "agri-waiting" ? "PENDING" : pendingReport.status === "agri-approved" ? "APPROVED" : null}
                            </button>
                          </td>
                        </tr>
                      )) : (
                        <tr className="tableData">
                          <td className="text-center" colSpan={100}>There is no summary report available.</td>
                        </tr>
                      )
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* FLOATING INFO */}
      {
        !pendingReportsIsLoading && pendingReports?.data.length ? pendingReports.data.map((pendingReport) => (
          <div className="floatPosition" onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
            <div style={{ backgroundColor: '#185D10' }}>
              <i class="bi bi-lightbulb-fill floatIcon" />
            </div>
            {showTooltip && (
              <div className="customTooltip">
                <span className="tooltipText">
                  You can now edit or download the barangay generated reports as csv.
                </span>
              </div>
            )}
          </div>
        )) : (
          <div className="floatPosition" onMouseEnter={() => setShowTooltip2(true)} onMouseLeave={() => setShowTooltip2(false)}>
            <div style={{ backgroundColor: '#185D10' }}>
              <i class="bi bi-lightbulb-fill floatIcon" />
            </div>
            {showTooltip2 && (
              <div className="customTooltip">
                <span className="tooltipText">
                  To receive reports, you need to wait for the BAFC to submit a report.
                </span>
              </div>
            )}
          </div>
        )
      }
    </>
  )
}

export default ATGeneratedReports
