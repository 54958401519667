import { Document, Font, Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer"

import AppLogo from "../../images/logo.png"

import RalewayBold from "../../fonts/Raleway-Bold.ttf"
import Raleway from "../../fonts/Raleway-Regular.ttf"

Font.register({
   family: "Raleway Bold",
   src: RalewayBold
})

Font.register({
   family: "Raleway",
   src: Raleway
})

const styles = StyleSheet.create({
   body: {
      paddingVertical: 35,
      paddingHorizontal: 35
   },
   wrapper: {
      display: "flex",
      flexDirection: "column",
      borderStyle: "solid",
      borderWidth: "2px",
      borderColor: "#185D10",
      borderRadius: 15,
      width: "75%",
      marginHorizontal: "auto",
      paddingVertical: 10,
      paddingHorizontal: 20
   },
   logo: {
      marginHorizontal: "auto",
      width: 125
   },
   header: {
      color: "#185D10",
      fontSize: 16,
      fontFamily: "Raleway Bold",
      textAlign: "center",
      marginTop: 5
   },
   divider: {
      width: "100%",
      height: 1,
      backgroundColor: "#185D10",
      marginVertical: 8
   },
   sectionHeader: {
      color: "#185D10",
      fontSize: 11,
      fontFamily: "Raleway Bold",
      textAlign: "left",
      marginBottom: 5
   },
   fieldsWrapper: {
      display: "flex",
      flexDirection: "column",
      rowGap: 3.5,
      width: "100%"
   },
   fieldWrapper: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      columnGap: 10,
      width: "100%"
   },
   fieldLabel: {
      color: "#185D10",
      fontSize: 8.5,
      fontFamily: "Raleway",
      textAlign: "right",
      textTransform: "uppercase"
   },
   fieldValue: {
      color: "#185D10",
      fontSize: 8.5,
      fontFamily: "Raleway",
      textAlign: "left",
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: "#185D10",
      borderRadius: 5,
      paddingHorizontal: 5,
      paddingTop: 2.5,
      paddingBottom: 1.5,
      width: 125
   }
})

const FarmerFormPDF = ({ data }) => {
   return data ? (
      <Document>
         <Page size="A4" style={styles.body}>
            <View style={styles.wrapper}>
               <Image style={styles.logo} src={AppLogo} />

               <Text style={styles.header}>Farmer Damaged Generated Report</Text>

               <Divider />

               <Text style={styles.sectionHeader}>Contact Information</Text>

               <View style={styles.fieldsWrapper}>
                  <FormField label="ffrs :" value={data.user.ffrs} />

                  <FormField label="farmer name :" value={`${data.user.firstName} ${data.user.lastName}`} />
               </View>

               <Divider />

               <Text style={styles.sectionHeader}>Geographic Information</Text>

               <View style={styles.fieldsWrapper}>
                  <FormField label="region :" value="3" />

                  <FormField label="province :" value="Bulacan" />

                  <FormField label="municipality :" value="San Miguel" />

                  <FormField label="barangay :" value={data.barangay} />
               </View>

               <Divider />

               <Text style={styles.sectionHeader}>Crop Damage Information</Text>

               <View style={styles.fieldsWrapper}>
                  <FormField label="crop type :" value={data.cropSeed} />

                  <FormField label="seed type :" value={data.seedType} />

                  <FormField label="ecosystem :" value={data.cropType} />

                  <FormField label="crop stage :" value={data.cropStage} />
               </View>

               <Divider />

               <Text style={styles.sectionHeader}>Damage Assessment of Area</Text>

               <View style={styles.fieldsWrapper}>
                  <FormField label="area of standing crops (HA) :" value={data.cropArea} />
               </View>
            </View>
         </Page>
      </Document>
   ) : null
}

const Divider = () => <View style={styles.divider} />

const FormField = ({ label, value }) => (
   <View style={styles.fieldWrapper}>
      <View style={{ width: "100%" }}>
         <Text style={styles.fieldLabel}>{label}</Text>
      </View>

      <View style={{ width: "100%" }}>
         <Text style={styles.fieldValue}>{value}</Text>
      </View>
   </View>
)

export default FarmerFormPDF