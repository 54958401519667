
import React, { useEffect, useState } from "react";
// OTHER IMPORTS
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import * as dateFns from "date-fns"
import { useFetchBAFCPendingFormsQuery } from "../utils/features/_forms/_formsApiSlice"
import { useGenerateSummaryReportsMutation } from "../utils/features/summaryReports/summaryReportsApiSlice";

// COMPONENTS IMPORTS
import Navbar from "../navbar/BAFCNavbar";
import SideBarnav from "../sidebar/BAFCSidebar";

const TITLE = 'GoFarmers | Pending Reports'

const BAFCGeneratedReports = () => {
  const [isClicked, setIsClicked] = useState(false);
  const [showTooltip1, setShowTooltip1] = useState(false);
  const [showTooltip2, setShowTooltip2] = useState(false);
  const [showTooltip3, setShowTooltip3] = useState(false);
  const navigate = useNavigate();

  const handleButtonClick = () => {
    setIsClicked(!isClicked);
  };

  const handleRowClick = (cropStage) => {
    navigate(`/bafc-farmer-reports/${cropStage}`);
  };

  const { data: forms, isLoading: formsIsLoading } = useFetchBAFCPendingFormsQuery()

  const [isSubmitReady, setIsSubmitReady] = useState()
  const [isAfterThreeDays, setIsAfterThreeDays] = useState()

  useEffect(() => {
    if (!formsIsLoading && forms?.data.data) {
      const hasNoPendingForms = forms.data.data.every((form) => !form.isPending)

      const isAfterThreeDays = dateFns.isAfter(new Date(), dateFns.addDays(new Date(forms.data.initialReport.createdAt), 3))

      // console.log(dateFns.isAfter(new Date(), dateFns.addDays(new Date("2023-10-16T15:35:55.356+00:00"), 3)))

      setIsSubmitReady(hasNoPendingForms/* && isAfterThreeDays*/)

      setIsAfterThreeDays(isAfterThreeDays)
    }
  }, [formsIsLoading, forms])

  const [generateSummaryReportsMutation] = useGenerateSummaryReportsMutation()

  async function handleGenerateSummaryReports() {
    const generatedSummaryReports = await generateSummaryReportsMutation()

    if (generatedSummaryReports.error) {
      // console.error("Failed to create summary reports")
      return
    }

    // console.info("Successfully created summary reports.") 
  }

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Navbar handleButtonClick={handleButtonClick} />
      <div className="container-fluid" id="tryFix">
        <div className="row flex-nowrap overflow-hidden">
          <SideBarnav />
          <div className="col overflow-x-hidden" id={`${isClicked ? 'tryMove' : ''}`}>
            <style>
              {`
                  #tryMove {
                    margin-left: ${isClicked ? '380px' : '0px'};       
                  }
                `}
            </style>
            {/* SECTION TITLE */}
            <h1 className="section">Pending Summary Reports</h1>
            {/* BUTTONS */}
            <div className="topButtons">
              <div className="row buttonContainer">
                <div className="singleContainer">
                  {
                    !forms?.data.initialReport || isAfterThreeDays ? null : (
                      <Link to="/bafc-farmer-report-add"><button className="btn btn-primary" id="buttons">ADD REPORT <i class="bi bi-plus-circle"></i></button></Link>
                    )
                  }
                </div>
              </div>
            </div>
            {/* GENERATED BARANGAY REPORTS */}
            <div className="row mt-4 tableContainer">
              <div className="col-11 col-lg-11 overflow-auto p-0" id="tableBorder">
                <table className="table mb-0 text-center table-hover align-middle" style={{ borderColor: '#185D10' }}>
                  <thead>
                    <tr id="thead1">
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                    <tr className="align-middle" id="thead1">
                      <th scope="col">NUMBER OF AFFECTED FARMERS </th>
                      <th scope="col">AREA OF STANDING CROP</th>
                      <th scope="col">STAGE OF CROP DEVELOPMENT</th>
                      <th scope="col">STATUS</th>
                    </tr>
                    <tr id="thead1">
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      !formsIsLoading && forms?.data.data.length ? forms.data.data.map((form, index) => (
                        <tr key={`form-row-${index}`} className="tableData" onClick={() => handleRowClick(form.cropStage)} style={{ cursor: 'pointer' }}>
                          <td>{form.farmersCount}</td>
                          <td>{form.standingCrop}</td>
                          <td>{form.cropStage}</td>
                          <td>
                            <button className="btn btn-primary statusButton px-3 disabled" id="buttons" aria-disabled="true">{form.isPending ? "PENDING" : "COMPLETED"}</button>
                          </td>
                        </tr>
                      )) : (
                        <tr className="tableData">
                          <td colSpan={100}>There are no pending forms available.</td>
                        </tr>
                      )
                    }
                  </tbody>
                </table>

              </div>
              {/* BUTTONS */}
              {
                !formsIsLoading && forms?.data.data.length && isSubmitReady ? (
                  <div className="row buttonContainer pt-4">
                    <div className="singleContainer">
                      <button className="btn btn-primary" type="button" onClick={handleGenerateSummaryReports} id="buttons">SUBMIT REPORTS <i class="bi bi-send"></i></button>
                    </div>
                  </div>
                ) : null
              }
            </div>
          </div>
        </div>
      </div>
      {/* FLOATING BUTTON */}
      {/* REMINDER 1 */}
      {
        !forms?.data.initialReport || isAfterThreeDays ? (
          <div className="floatPosition" onMouseEnter={() => setShowTooltip1(true)} onMouseLeave={() => setShowTooltip1(false)}>
            <div style={{ backgroundColor: '#185D10' }}>
              <i class="bi bi-lightbulb-fill floatIcon" />
            </div>
            {showTooltip1 && (
              <div className="customTooltip">
                <span className="tooltipText">
                  To add and receive farmer damage reports, you need to create the mandatory{' '}
                  <Link to="/bafc-initial-reports" className="text-white">Initial Report</Link>.
                </span>
              </div>
            )}
          </div>
        ) : (
          <div className="floatPosition">
            <div
              style={{ backgroundColor: '#185D10' }}
              onMouseEnter={() => setShowTooltip1(true)}
              onMouseLeave={() => setShowTooltip1(false)}
            >
              <i class="bi bi-lightbulb-fill floatIcon" />
            </div>
            {showTooltip1 && (
              <div className="customTooltip">
                <span className="tooltipText">You can only add and receive farmer reports in the span of 3 days after you created the Initial Report.</span>
              </div>
            )}
          </div>
        )
      }
      {/* REMINDER 2 */}
      {
        !formsIsLoading && forms?.data.data.length ? forms.data.data.map((form, index) => (
          <div className="floatPosition">
            <div
              style={{ backgroundColor: '#185D10' }}
              onMouseEnter={() => setShowTooltip2(true)}
              onMouseLeave={() => setShowTooltip2(false)}
            >
              <i class="bi bi-lightbulb-fill floatIcon" />
            </div>
            {showTooltip2 && (
              <div className="customTooltip">
                <span className="tooltipText">You need to approve or decline the reports inside each row to mark the status approved</span>
              </div>
            )}
          </div>
        )) : null
      }
      {/* REMINDER 3 */}
      {
        !formsIsLoading && forms?.data.data.length && isSubmitReady ? (
          <div className="floatPosition">
            <div
              style={{ backgroundColor: '#185D10' }}
              onMouseEnter={() => setShowTooltip3(true)}
              onMouseLeave={() => setShowTooltip3(false)}
            >
              <i class="bi bi-lightbulb-fill floatIcon" />
            </div>
            {showTooltip3 && (
              <div className="customTooltip2">
                <span className="tooltipText">Once you've finished checking all the reports, you can click the "Submit Reports" button to generate a barangay report based on the accumulated farmer reports for the Agritech side.</span>
              </div>
            )}
          </div>
        ) : null
      }
    </>
  )
}

export default BAFCGeneratedReports
