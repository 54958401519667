import React, { useEffect, useState } from "react";
// OTHER IMPORTS
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { useForm } from "react-hook-form"
import formatDate from "date-fns/format"
import { useFetchBAFCDeclinedFormQuery } from "../utils/features/_forms/_formsApiSlice";
import { PDFDownloadLink } from "@react-pdf/renderer";
import FarmerFormPDF from "../components/exports/FarmerFormPDF";

// COMPONENTS
import Navbar from "../navbar/BAFCNavbar";
import SideBarnav from '../sidebar/BAFCSidebar';
import logo from '../images/logo.webp';

const TITLE = 'GoFarmers | Initial Report Form'

const BAFCDeclinedForm = () => {
  const [isClicked, setIsClicked] = useState(false);

  const handleButtonClick = () => {
    setIsClicked(!isClicked);
  };

  const params = useParams()

  const { data: declinedForm, isLoading: declinedFormIsLoading } = useFetchBAFCDeclinedFormQuery({
    id: params.id
  })

  const { register, reset } = useForm()

  useEffect(() => {
    if (!declinedFormIsLoading && declinedForm?.data) {
      reset({
        ffrs: declinedForm.data.user.ffrs,
        name: `${declinedForm.data.user.firstName} ${declinedForm.data.user.lastName}`,
        disasterDate: formatDate(new Date(declinedForm.data.disasterDate), "yyyy-MM-dd"),
        disasterType: declinedForm.data.disasterType,
        barangay: declinedForm.data.barangay,
        cropType: declinedForm.data.cropType,
        cropSeed: declinedForm.data.cropSeed,
        seedType: declinedForm.data.seedType,
        cropStage: declinedForm.data.cropStage,
        cropArea: declinedForm.data.cropArea
      })
    }
  }, [declinedFormIsLoading, declinedForm, reset])

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Navbar handleButtonClick={handleButtonClick} />
      <div className="container-fluid" id="tryFix">
        <div className="row flex-nowrap  overflow-hidden">
          <SideBarnav />
          <div className="col overflow-x-hidden" id={`${isClicked ? 'tryMove' : ''}`}>
            <style>
              {`
                #tryMove {
                  margin-left: ${isClicked ? '380px' : '0px'};
                }
              `}
            </style>
            <h1 className="section">Declined Farmers Damaged Form</h1>
            <div className="d-flex justify-content-between">
              <Link to="/bafc-declined-forms"><button className="btn btn-success my-2" id="buttons"><i class="bi bi-arrow-left"></i> BACK</button></Link>

              <PDFDownloadLink document={<FarmerFormPDF data={declinedForm?.data} />} fileName={`${declinedForm?.data.user.ffrs}_damage-assessment-report`} >
                <button className="btn btn-success my-2" id="buttons"><i class="bi bi-download"></i> DOWNLOAD</button>
              </PDFDownloadLink>
            </div>

            <div className="container" id="damageContainer">
              <div className="row justify-content-center align-items-center">
                <div id="textSection">
                  <div className="logoForms">
                    <img src={logo} alt="GoFarmers" />
                  </div>
                  <h2 className="centerTitle">Declined Farmer Damaged Generated Report</h2>
                  <form>
                    <div className="form">
                      <div className="formSection"></div>
                      <h5 className="ms-4 fw-bold">Contact Information</h5>
                      <div className="form">
                        <div className="row justify-content-center align-items-center">
                          <label for="email" className="fw-medium text-end col-sm-5 my-1 col-form-label">FFRS ID :</label>
                          <div className="col-sm-5">
                            <input type="text" className="textField form-control" id="email" {...register("ffrs")} disabled readonly />
                          </div>
                        </div>
                        <div className="row justify-content-center align-items-center">
                          <label for="farmerName" className="fw-medium text-end col-sm-5 my-1 col-form-label">FARMER NAME :</label>
                          <div className="col-sm-5">
                            <input type="text" className="textField form-control" id="farmerName" {...register("name")} disabled readonly />
                          </div>
                        </div>

                        <div className="formSection"></div>
                        <h5 className="ms-4 fw-bold" >General Information</h5>
                        <div className="form">
                          <div className="row justify-content-center align-items-center">
                            <label for="date" className="fw-medium text-end col-sm-5 my-1 col-form-label">DATE OF OCCURENCE :</label>
                            <div className="col-sm-5">
                              <input type="text" className="textField form-control" id="date" {...register("disasterDate")} disabled readonly />
                            </div>
                          </div>
                          <div className="row justify-content-center align-items-center">
                            <label for="causedamage" className="fw-medium text-end col-sm-5 my-1 col-form-label">TYPE OF DISASTER :</label>
                            <div className="col-sm-5">
                              <select className="textField form-select" id="type" {...register("disasterType")} disabled readonly>
                                <option style={{ display: 'none' }}></option>
                                <option defaultValue="Drought">Drought</option>
                                <option defaultValue="Typhoon">Typhoon</option>
                                <option defaultValue="Flood">Flood</option>
                              </select>
                            </div>
                          </div>

                          <div className="formSection"></div>
                          <h5 className="ms-4 fw-bold">Geographic Information</h5>
                          <div className="row justify-content-center align-items-center">
                            <label for="region" className="fw-medium text-end col-sm-5 my-1 col-form-label">REGION :</label>
                            <div className="col-sm-5">
                              <input type="text" inputmode="numeric" className="textField form-control" id="region" defaultValue="3" disabled readonly />
                            </div>
                          </div>
                          <div className="row justify-content-center align-items-center">
                            <label for="province" className="fw-medium text-end col-sm-5 my-1 col-form-label">PROVINCE :</label>
                            <div className="col-sm-5">
                              <input type="text" className="textField form-control" id="province" defaultValue="Bulacan" disabled readonly />
                            </div>
                          </div>
                          <div className="row justify-content-center align-items-center">
                            <label for="municipality" className="fw-medium text-end col-sm-5 my-1 col-form-label">MUNICIPALITY :</label>
                            <div className="col-sm-5">
                              <input type="text" className="textField form-control" id="municipality" defaultValue="San Miguel" disabled readonly />
                            </div>
                          </div>
                          <div className="row justify-content-center align-items-center">
                            <label for="barangay" className="fw-medium text-end col-sm-5 my-1 col-form-label">BARANGAY :</label>
                            <div className="col-sm-5">
                              <input type="text" className="textField form-control" id="barangay" {...register("barangay")} disabled readonly />
                            </div>
                          </div>

                          <div className="formSection"></div>
                          <h5 className="ms-4 fw-bold">Crop Damage Information</h5>
                          <div className="row justify-content-center align-items-center">
                            <label for="type" className="fw-medium text-end col-sm-5 my-1 col-form-label">CROP TYPE :</label>
                            <div className="col-sm-5">
                              <input type="text" className="textField form-control" id="crop" {...register("cropSeed")} disabled readonly />
                            </div>
                          </div>
                          <div className="row justify-content-center align-items-center">
                            <label for="type" className="fw-medium text-end col-sm-5 my-1 col-form-label">SEED TYPE :</label>
                            <div className="col-sm-5">
                              <select className="textField form-select" id="type" {...register("seedType")} disabled readonly >
                                <option defaultValue="Inbred">Inbred</option>
                                <option defaultValue="Hybrid">Hybrid</option>
                              </select>
                            </div>
                          </div>
                          <div className="row justify-content-center align-items-center">
                            <label for="type" className="fw-medium text-end col-sm-5 my-1 col-form-label">CROP TYPE :</label>
                            <div className="col-sm-5">
                              <select className="textField form-select" id="type" {...register("cropType")} disabled readonly >
                                <option value="Irrigated">Irrigated</option>
                                <option value="Raised Field - lowland">Raised Field - lowland</option>
                                <option value="Raised Field - upland"> Raised Field - upland</option>
                              </select>
                            </div>
                          </div>
                          <div className="row justify-content-center align-items-center">
                            <label for="type" className="fw-medium text-end col-sm-5 my-1 col-form-label">CROP STAGE :</label>
                            <div className="col-sm-5">
                              <select className="textField form-select" id="type" {...register("cropStage")} disabled readonly >
                                <option defaultValue="">Maturity</option>
                                <option defaultValue="Seedling">Seedling</option>
                                <option defaultValue="Planting Silang">Planting Silang</option>
                                <option defaultValue="Vegetative">Vegetative</option>
                                <option defaultValue="Reproductive">Reproductive</option>
                                <option defaultValue="Maturity">Maturity</option>
                              </select>
                            </div>
                          </div>
                          <h5 className="ms-4 fw-semibold pt-4">Damage Assessment of Area</h5>
                          <div className="row justify-content-center align-items-center">
                            <label for="" className="fw-medium text-end col-sm-5 my-1 col-form-label">AREA OF STANDING CROP IN HECTARES  :</label>
                            <div className="col-sm-5">
                              <div className="form-outline">
                                <input type="number" id="" className="textField form-control" min="0" max="10" {...register("cropArea")} disabled readonly />
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BAFCDeclinedForm