import { createSelector, createEntityAdapter } from "@reduxjs/toolkit"
import { apiSlice } from "../../app/api/apiSlice"

const usersAdapter = createEntityAdapter({})

const initialState = usersAdapter.getInitialState()

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllUser: builder.query({
      query: () => "/users/",
      validateStatus: (response, result) => {
        return response.status === 200 && !result.isError
      },
      transformResponse: (responseData) => {
        if (
          responseData &&
          responseData.users &&
          Array.isArray(responseData.users)
        ) {
          const loadedUsers = responseData.users.map((user) => {
            user.id = user._id
            return user
          })
          return usersAdapter.setAll(initialState, loadedUsers)
        }
        return usersAdapter.setAll(initialState, [])
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "User", id: "LIST" },
            ...result.ids.map((id) => ({ type: "User", id })),
          ]
        } else return [{ type: "User", id: "LIST" }]
      },
    }),
    getUser: builder.query({
      query: () => ({
        url: "/users/user",
        method: "GET",
      }),
    }),
    createAdmin: builder.mutation({
      query: (userData) => ({
        url: "/users/create-admin",
        method: "POST",
        body: {
          ...userData,
        },
      }),
      invalidatesTags: [{ type: "User", id: "LIST" }],
    }),
    createUser: builder.mutation({
      query: (userData) => ({
        url: "/users/create-user",
        method: "POST",
        body: {
          ...userData,
        },
      }),
      invalidatesTags: [{ type: "User", id: "LIST" }],
    }),
    updateUser: builder.mutation({
      query: (initialUserData) => ({
        url: "/users/update",
        method: "PUT",
        body: {
          ...initialUserData,
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "User", id: arg.id }],
    }),
    updateProfile: builder.mutation({
      query: (userData) => ({
        url: "/users/update-profile",
        method: "PUT",
        body: {
          ...userData,
        },
      }),
      invalidatesTags: ["User"],
    }),
    deleteUser: builder.mutation({
      query: ({ id }) => ({
        url: `/users/delete`,
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "User", id: arg.id }],
    }),
    refresh: builder.query({
      query: () => ({
        url: "/users/refresh",
        method: "GET",
      }),
    }),
    fetchUserByFfrs: builder.query({
      query: (data) => ({
        url: `/users/${ data.ffrs }`
      })
    }),
    fetchUserInFfrs: builder.query({
      query: (data) => ({
        url: `/users/user/${ data.ffrs }`
      })
    })
  }),
})

export const {
  useGetAllUserQuery,
  useGetUserQuery,
  useCreateAdminMutation,
  useCreateUserMutation,
  useUpdateUserMutation,
  useUpdateProfileMutation,
  useDeleteUserMutation,
  useRefreshQuery,
  useFetchUserByFfrsQuery,
  useFetchUserInFfrsQuery
} = usersApiSlice

export const selectUsersResult = usersApiSlice.endpoints.getAllUser.select()

const selectUsersData = createSelector(selectUsersResult, (usersResult) => {
  if (usersResult.isLoading || usersResult.isError) {
    return null
  }
  return usersResult.data
})

export const {
  selectAll: selectAllUsers,
  selectById: selectUserById,
  selectIds: selectUserIds,
} = usersAdapter.getSelectors((state) => selectUsersData(state) ?? initialState)
