import { apiSlice } from "../../app/api/apiSlice"

const dashboardApiSlice = apiSlice.injectEndpoints({
   endpoints: (builder) => ({
      fetchDashboardData: builder.query({
         query: ({ role, ...data }) => ({
            url: `/dashboard/${ role }`,
            params: data
         }),
         invalidatesTags: ["Dashboard"]
      })
   })
})

export const {
   useFetchDashboardDataQuery
} = dashboardApiSlice