import React, { useState, useEffect } from "react";
// OTHER IMPORTS
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

// COMPONENTS
import Navbar from "../navbar/BAFCNavbar";
import SideBarnav from "../sidebar/BAFCSidebar";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { useCreateUserMutation } from "../utils/features/users/usersApiSlice"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { useGetUserQuery } from "../utils/features/users/usersApiSlice"
import { useFetchUserInFfrsQuery } from "../utils/features/users/usersApiSlice"

const TITLE = "GoFarmers | Add New Account";

const FFRS_REGEX = /^\d{2}-\d{2}-\d{2}-\d{3}-\d{6}$/
const MOBILE_REGEX = /^09\d{9}$/
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%])[A-Za-z\d!@#$%]{8,}$/;

const BAFCAddAccount = () => {
  const [isClicked, setIsClicked] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleButtonClick = () => {
    setIsClicked(!isClicked);
  };

  const [showModal, setshowModal] = useState(false);

  const modalClose = () => setshowModal(false);
  const modalShow = () => setshowModal(true);

  const navigate = useNavigate()

  const { handleSubmit, register, setValue, formState: { errors }, setError, watch } = useForm()

  const { data: user } = useGetUserQuery()

  let BAFCbarangay
  if (user && user.user.barangay && user.user.barangay.length > 0) {
    BAFCbarangay = user.user.barangay[0]
  }

  const currentFfrs = watch("ffrs", "")

  const { data: userByFfrs, isLoading: userByFfrsIsLoading } = useFetchUserInFfrsQuery({
    ffrs: currentFfrs
  })

  useEffect(() => {
    if (!userByFfrsIsLoading) {
      setValue("firstName", userByFfrs?.data ? userByFfrs.data.firstName : null)
      setValue("lastName", userByFfrs?.data ? userByFfrs.data.lastName : null)
    }
  }, [userByFfrs, userByFfrsIsLoading, setValue])

  const [createUser] = useCreateUserMutation()

  const onSubmit = async (data) => {
    try {
      const { password, repeatpassword } = data

      if (repeatpassword !== password) {
        setError("repeatpassword", {
          type: "manual",
          message: "Passwords do not match",
        })
        return
      }

      const errorFields = Object.keys(errors);
      if (errorFields.length === 0) {
        modalShow()
      }
    } catch (err) {
      toast.error("Failed to save")
      // console.log(err)
    }
  };

  const addUser = async () => {
    try {
      const data = watch()

      // console.log(data)

      await createUser(data)
        .unwrap()
        .then((res) => {
          toast.success("User added successfully")
          navigate("/bafc-account")
        })
    } catch (err) {
      if (err.status === 404 || err.status === 400) {
        toast.error("No matching FFRS found in the database")
      } else if (err.status === 403) {
        toast.error("User with that FFRS number already exists")
      } else {
        toast.error("Something went wrong")
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Navbar handleButtonClick={handleButtonClick} />
      <div className="container-fluid" id="tryFix">
        <div className="row flex-nowrap overflow-hidden">
          <SideBarnav />
          <div
            className="col overflow-x-hidden"
            id={`${isClicked ? "tryMove" : ""}`}
          >
            <style>
              {`
                #tryMove {
                  margin-left: ${isClicked ? "380px" : "0px"};
                }
              `}
            </style>
            {/* SECTION TITLE */}
            <h1 className="section">Add New Account</h1>

            <div className="col-md-3">
              <Link to="/bafc-account">
                <button className="btn btn-success" id="buttons">
                  <i class="bi bi-arrow-left"></i> BACK
                </button>
              </Link>
            </div>

            <div className="container-fluid" id="customContainer">
              <div className="row">
                <div id="textSection">
                  <form className="ps-4 pe-4 my-4" onSubmit={handleSubmit(onSubmit)} >
                    <div className="row justify-content-center align-items-center">
                      <label htmlFor="ffrsid" className="formLabel col-sm-5 my-1 col-form-label text-end" >
                        FFRS ID :
                      </label>
                      <div className="col-sm-6">
                        <input type="text" className={`textField form-control ${errors.mobile ? "invalid" : ""}`} id="ffrsid" {...register("ffrs", { required: "This field is required", pattern: { value: FFRS_REGEX, message: "Invalid FFRS ID", }, })} />
                        {errors.ffrs && (
                          <p className="errmsg">{errors.ffrs.message}</p>
                        )}
                      </div>
                    </div>
                    <div className="row justify-content-center align-items-center">
                      <label htmlFor="firstName" className="formLabel col-sm-5 my-1 col-form-label text-end" >
                        FIRST NAME :
                      </label>
                      <div className="col-sm-6">
                        <input type="text" className={`textField form-control ${errors.firstName ? "invalid" : ""}`} id="firstName" {...register("firstName")} readOnly />
                      </div>
                    </div>
                    <div className="row justify-content-center align-items-center">
                      <label htmlFor="lastName" className="formLabel col-sm-5 my-1 col-form-label text-end">
                        LAST NAME :
                      </label>
                      <div className="col-sm-6">
                        <input type="text" className={`textField form-control ${errors.lastName ? "invalid" : ""}`} id="lastName" {...register("lastName")} readOnly />
                      </div>
                    </div>

                    <div className="row justify-content-center align-items-center">
                      <label htmlFor="mobileNumber" className="formLabel col-sm-5 my-1 col-form-label text-end">
                        MOBILE NUMBER :
                      </label>
                      <div className="col-sm-6">
                        <input type="number" className={`textField form-control ${errors.mobile ? "invalid" : ""}`} id="mobileNumber" {...register("mobile", { required: "This field is required", pattern: { value: MOBILE_REGEX, message: "Invalid mobile number", }, })} />
                        {errors.mobile && (
                          <p className="errmsg">{errors.mobile.message}</p>
                        )}
                      </div>
                    </div>
                    <div className="row justify-content-center align-items-center">
                      <label htmlFor="barangay" className="formLabel col-sm-5 my-1 col-form-label text-end" >
                        BARANGAY :
                      </label>
                      <div className="col-sm-6">
                        <input type="barangay" className='textField form-control' id="barangay" value={user.user.barangay[0]} {...register("barangay")} readOnly />
                      </div>
                    </div>
                    <div className="row justify-content-center align-items-center">
                      <label htmlFor="role" className="formLabel col-sm-5 my-1 col-form-label text-end" >
                        ROLE:
                      </label>
                      <div className="col-sm-6">
                        <input type="text" className="textField form-control" id="role" value={"user"} {...register("role")} readOnly disabled />
                      </div>
                    </div>
                    <div className="row justify-content-center align-items-center">
                      <label htmlFor="password" className="formLabel col-sm-5 my-1 col-form-label text-end" >
                        PASSWORD:
                      </label>
                      <div className="col-sm-6">
                        <input type="password" className={`textField form-control ${errors.password ? "invalid" : ""}`} id="password" {...register("password", { required: "This field is required", pattern: { value: PWD_REGEX, message: "Password must be at least 8 characters and must include letters, numbers, and special characters like !@#$%", }, })} /> {errors.password && (<p className="errmsg">{errors.password.message}</p>)}
                      </div>
                    </div>
                    <div className="row justify-content-center align-items-center">
                      <label htmlFor="rePassword" className="formLabel col-sm-5 my-1 col-form-label text-end" >
                        RE-ENTER PASSWORD:
                      </label>
                      <div className="col-sm-6">
                        <input type="password" className={`textField form-control ${errors.repeatpassword ? "repeatpassword" : ""}`} id="rePassword" {...register("repeatpassword", { required: "This field is required", validate: (value) => value === watch("password") || "Passwords do not match", })} />
                        {errors.repeatpassword && (<p className="errmsg"> {errors.repeatpassword.message} </p>)}
                      </div>
                    </div>
                    <div className="d-flex justify-content-end">
                      <div>
                        <Link to="/bafc-account"> <button type="button" className="btn btn-primary me-3" id="cancelButton" > CANCEL </button> </Link>
                      </div>
                      <button type="submit" className="btn btn-primary" id="buttons" >
                        ADD ACCOUNT
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* FLOATING INFO BUTTON */}
      <div className="floatPosition">
        <div
          style={{ backgroundColor: "#185D10" }}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          <i class="bi bi-lightbulb-fill floatIcon" />
        </div>
        {showTooltip && (
          <div className="customTooltip">
            <span className="tooltipText">
              You can only add farmer accounts with an FFRS ID.
            </span>
          </div>
        )}
      </div>
      <Modal show={showModal} onHide={modalClose} backdrop="static" keyboard={false} centered >
        <Modal.Body className="py-4">
          <div className="row mb-4 text-center" style={{ color: "#185D10" }}>
            <h1>
              <i className="bi bi-question-circle"></i>
            </h1>
            <h5 className="fw-bold">
              ARE YOU SURE <br /> YOU WANT TO ADD THIS ACCOUNT?
            </h5>
          </div>
          <div className="row text-center">
            <div className="col text-end">
              <Button
                className="m-auto"
                onClick={modalClose}
                id="modalCancelbtn"
              >
                CANCEL
              </Button>
            </div>
            <div className="col text-start">
              <Button className="m-auto" id="modalConfirmbtn" onClick={addUser}>
                ADD ACCOUNT
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BAFCAddAccount;
