import React, { useState } from "react";
// OTHER IMPORTS
import { useSearchParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import formatDate from "date-fns/format"
import { useFetchBAFCDeclinedFormsQuery } from "../utils/features/_forms/_formsApiSlice";

// COMPONENTS IMPORTS
import Navbar from "../navbar/BAFCNavbar";
import SideBarnav from "../sidebar/BAFCSidebar";

const TITLE = 'GoFarmers | Declined Reports'

const BAFCDecForms = () => {
  // TOGGLE SIDEBAR
  const [isClicked, setIsClicked] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const handleButtonClick = () => {
    setIsClicked(!isClicked);
  };

  const [searchParams] = useSearchParams()

  const { data: declinedForms, isLoading: declinedFormsIsLoading } = useFetchBAFCDeclinedFormsQuery({
    sortField: searchParams.get("sortField")
  })

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Navbar handleButtonClick={handleButtonClick} />
      <div className="container-fluid" id="tryFix">
        <div className="row flex-nowrap overflow-hidden">
          <SideBarnav />
          <div className="col overflow-x-hidden" id={`${isClicked ? 'tryMove' : ''}`}>
            <style>
              {`
                #tryMove {
                  margin-left: ${isClicked ? '380px' : '0px'};
                  
                }
              `}
            </style>
            {/* SECTION TITLE */}
            <h1 className="section">Declined Reports</h1>
            {/* BUTTONS */}
            <div className="topButtons">
              <div className="row buttonContainer">
                <dic className="singleContainer">
                  <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" id="buttons">
                    SORT BY
                  </button>
                  <ul className="dropdown-menu" id="dropDown">
                    <li><Link className="dropdown-item" to={{ search: "?sortField=name" }}>A-Z</Link></li>
                    <li><Link className="dropdown-item" to={{ search: "?sortField=-name" }}>Z-A</Link></li>
                    <li><Link className="dropdown-item" to={{ search: "?sortField=-updatedAt" }}>Latest to Oldest</Link></li>
                    <li><Link className="dropdown-item" to={{ search: "?sortField=updatedAt" }}>Oldest to Latest</Link></li>
                  </ul>
                </dic>
              </div>
            </div>
            <div className="row mt-4 tableContainer">
              <div className="col-11 col-lg-11 overflow-auto p-0" id="tableBorder">
                <table className="table mb-0 text-center table-hover align-middle" style={{ borderColor: '#185D10' }}>
                  <thead>
                    <tr id="thead1">
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <thead>
                    <tr className="align-middle" id="thead1">
                      <th scope="col">FFRS ID</th>
                      <th scope="col">FORM ID</th>
                      <th scope="col">FARMER NAME</th>
                      <th scope="col">DATE OF OCCURENCE</th>
                      <th scope="col">AREA OF STANDING CROP </th>
                      <th scope="col">STAGE OF CROP DEVELOPMENT</th>
                      <th scope="col">ACTION</th>
                    </tr>
                  </thead>
                  <thead>
                    <tr id="thead1">
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      !declinedFormsIsLoading && declinedForms?.data.length ? declinedForms.data.map((form) => (
                        <tr className="tableData" key={form._id}>
                          <td>{form.user.ffrs}</td>
                          <td>{form._id}</td>
                          <td>{`${form.user.firstName} ${form.user.lastName}`}</td>
                          <td>{formatDate(new Date(form.disasterDate), "yyyy-MM-dd")}</td>
                          <td>{form.cropArea}</td>
                          <td>{form.cropStage}</td>
                          <td>
                            <Link to={`/bafc-declined-form/${form._id}`} className="btn btn-primary py-2 mx-auto btn-sm" id="editButton"><i class="bi fs-4 bi-eye"></i></Link>
                          </td>
                        </tr>
                      )) : (
                        <tr className="tableData">
                          <td colSpan={100}>There are no declined forms available.</td>
                        </tr>
                      )
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* FLOATING INFO BUTTON */}
      <div className="floatPosition">
        <div
          style={{ backgroundColor: '#185D10' }}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          <i class="bi bi-lightbulb-fill floatIcon" />
        </div>
        {showTooltip && (
          <div className="customTooltip">
            <span className="tooltipText">Use this modal to view or download the farmer forms you marked as declined.</span>
          </div>
        )}
      </div>
    </>
  )
}

export default BAFCDecForms;

