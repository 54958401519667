import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle.min"
import "bootstrap-icons/font/bootstrap-icons.css"
import { Routes, Route } from "react-router-dom"
import "./App.css"
// ENTRY PAGES
import SignIn from "./entry/SignIn"
import ForgotPassword from "./entry/ForgotPassword"
import ForgotPasswordEmailSent from "./entry/ForgotPasswordEmailSent"
import ResetPassword from "./entry/ResetPassword"
import Terms from "./terms-policy/Terms"
import Policy from "./terms-policy/Policy"
import PageNotFound from "./entry/PageNotFound"

// SUPER ADMIN - SAD
import SADash from "./superadmin/SADashboard"
import SADAcc from "./superadmin/SADAccount"
import SADAddAcc from "./superadmin/SADAddAccount"
import SADEditAcc from "./superadmin/SADEditAccount"

// BARANGAY AND FISHERIES COUNCILOR - BAFC
import BAFCDash from "./bafc-pages/BAFCDashboard"
import BAFCProcess from "./bafc-pages/BAFCProcess"
import BAFCFarmerRepHistory from "./bafc-pages/BAFCFarmerRepHistory"
import BAFCFarmerFormHistory from "./bafc-pages/BAFCFarmerFormHistory"
import BAFCInitRep from "./bafc-pages/BAFCInitialReports"
import BAFCAddInitRep from "./bafc-pages/BAFCAddInitialReport"
import BAFCViewInitForm from "./bafc-pages/BAFCViewInitialReport"
import BAFCGenRep from "./bafc-pages/BAFCGeneratedReports"
import BAFCFarmRep from "./bafc-pages/BAFCFarmerReports"
import BAFCAddFarmRep from "./bafc-pages/BAFCAddDamagedForm"
import BAFCDmgForms from "./bafc-pages/BAFCDamagedForm"
import BAFCDecForms from "./bafc-pages/BAFCDecForms"
import BAFCDeclinedForm from "./bafc-pages/BAFCDecForm"
import BAFCInbox from "./bafc-pages/BAFCInbox"
import BAFCAcc from "./bafc-pages/BAFCAccount"
import BAFCAddAcc from "./bafc-pages/BAFCAddAccount"
import BAFCEditAcc from "./bafc-pages/BAFCEditAccount"
import BAFCProfSet from "./bafc-pages/BAFCProfileSettings"

// AGRICULTURE TECHNICIAN - AT
import ATDash from "./agritech-pages/ATDashboard"
import ATProcess from "./agritech-pages/ATProcess"
import ATRepHistory from "./agritech-pages/ATReportHistory"
import ATFormHistory from "./agritech-pages/ATHistoryForm"
import ATInitRep from "./agritech-pages/ATInitialReports"
import ATEditInitForm from "./agritech-pages/ATInitialForm"
import ATAutoGen from "./agritech-pages/ATGeneratedReports"
import ATDmgForms from "./agritech-pages/ATDamagedForm"
import ATProfSet from "./agritech-pages/ATProfileSettings"

// MUNICIPAL REPORT OFFICERS - MRO
import MRODash from "./mro-pages/MRODashboard"
import MROAnalytics from "./mro-pages/MROAnalytics"
import MROProcess from "./mro-pages/MROProcess"
import MRORepHistory from "./mro-pages/MROReportHistory"
import MROFormHistory from "./mro-pages/MROHistoryForm"
import MROInitRep from "./mro-pages/MROInitialReports"
import MROInitForm from "./mro-pages/MROInitialForm"
import MROBrgyForm from "./mro-pages/MROBarangayDamagedForm"
import MROAutoGen from "./mro-pages/MROGeneratedReports"
import MROSched from "./mro-pages/MROSchedule"
import MROEditSched from "./mro-pages/MROEditSchedule"
import MROProfSet from "./mro-pages/MROProfileSettings"

// PROVINCIAL REPORT OFFICERS - PRO
import PRODash from "./pro-pages/PRODashboard"
import PROAnalytics from "./pro-pages/PROAnalytics"
import PROProcess from "./pro-pages/PROProcess"
import PRORepHistory from "./pro-pages/PROReportHistory"
import PROFormHistory from "./pro-pages/PROHistoryForm"
import PROAutoGen from "./pro-pages/PROGeneratedReports"
import PRODmgForms from "./pro-pages/PRODamagedForms"
import PROProfSet from "./pro-pages/PROProfileSettings"

import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Prefetch from "./utils/features/auth/prefetch"
import RequireAuth from "./utils/features/auth/requireAuth"

function App() {
  return (
    <>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="forgot-password/email-sent" element={<ForgotPasswordEmailSent />} />
        <Route path="reset/:id/:token" element={<ResetPassword />} />
        <Route path="terms" element={<Terms/>} />
        <Route path="policy" element={<Policy/>} />

        <Route element={<Prefetch />}>
          <Route element={<RequireAuth allowedRole="superAdmin" />}>
            <Route path="superadmin-dashboard" element={<SADash />} />
            <Route path="superadmin-account" element={<SADAcc />} />
            <Route path="superadmin-account-add" element={<SADAddAcc />} />
            <Route path="superadmin-account-edit/:id" element={<SADEditAcc />} />
          </Route>

          <Route element={<RequireAuth allowedRole="BAFCadmin" />}>
            <Route path="bafc-dashboard" element={<BAFCDash />} />
            <Route path="bafc-process-progress" element={<BAFCProcess />} />
            <Route path="bafc-report-history" element={<BAFCFarmerRepHistory />} />
            <Route path="bafc-farmer-form-history/:id" element={<BAFCFarmerFormHistory />} />
            <Route path="bafc-initial-reports" element={<BAFCInitRep />} />
            <Route path="bafc-initial-report-add" element={<BAFCAddInitRep />} />
            <Route path="bafc-initial-report/:id" element={<BAFCViewInitForm />} />
            <Route path="bafc-generated-reports" element={<BAFCGenRep />} />
            <Route path="bafc-farmer-reports/:cropStage" element={<BAFCFarmRep />} />
            <Route path="bafc-damaged-forms/:cropStage/:id" element={<BAFCDmgForms />} />
            <Route path="bafc-farmer-report-add" element={<BAFCAddFarmRep />} />
            <Route path="bafc-scheduled-dates" element={<BAFCInbox />} />
            <Route path="bafc-account" element={<BAFCAcc />} />
            <Route path="bafc-account-add" element={<BAFCAddAcc />} />
            <Route path="bafc-account-edit/:id" element={<BAFCEditAcc />} />
            <Route path="bafc-declined-forms" element={<BAFCDecForms />} />
            <Route path="bafc-declined-form/:id" element={<BAFCDeclinedForm />} />
            <Route path="bafc-profile-edit" element={<BAFCProfSet />} />
          </Route>

          <Route element={<RequireAuth allowedRole="agritech" />}>
            <Route path="agritech-dashboard" element={<ATDash />} />
            <Route path="agritech-process-progress" element={<ATProcess />} />
            <Route path="agritech-report-history" element={<ATRepHistory />} />
            <Route path="agritech-form-history/:id" element={<ATFormHistory />} />
            <Route path="agritech-initial-reports" element={<ATInitRep />} />
            <Route path="agritech-initial-reports/:id" element={<ATEditInitForm />} />
            <Route path="agritech-generated-reports" element={<ATAutoGen />} />
            <Route path="agritech-damaged-form/:id" element={<ATDmgForms />} />
            <Route path="agritech-profile-edit" element={<ATProfSet />} />
          </Route>

          <Route element={<RequireAuth allowedRole={["MRO-rice", "MRO-veg"]} />}>
            <Route path="mro-dashboard" element={<MRODash />} />
            <Route path="mro-analytics" element={<MROAnalytics />} />
            <Route path="mro-process-progress" element={<MROProcess />} />
            <Route path="mro-report-history" element={<MRORepHistory />} />
            <Route path="mro-form-history/:id" element={<MROFormHistory />} />
            <Route path="mro-initial-reports" element={<MROInitRep />} />
            <Route path="mro-initial-form/:id" element={<MROInitForm />} />
            <Route path="mro-barangay-damaged-form/:id" element={<MROBrgyForm />} />
            <Route path="mro-generated-reports" element={<MROAutoGen />} />
            <Route path="mro-distribution-schedule" element={<MROSched />} />
            <Route path="mro-distribution-schedule/:id/edit" element={<MROEditSched />} />
            <Route path="mro-profile-edit" element={<MROProfSet />} />
          </Route>

          <Route element={<RequireAuth allowedRole={["PRO-rice", "PRO-veg"]} />}>
            <Route path="pro-dashboard" element={<PRODash />} />
            <Route path="pro-analytics" element={<PROAnalytics />} />
            <Route path="pro-process-progress" element={<PROProcess />} />
            <Route path="pro-report-history" element={<PRORepHistory />} />
            <Route path="pro-form-history/:id" element={<PROFormHistory />} />
            <Route path="pro-generated-reports" element={<PROAutoGen />} />
            <Route path="pro-damage-forms/:id" element={<PRODmgForms />} />
            <Route path="pro-profile-edit" element={<PROProfSet />} />
          </Route>
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  )
}

export default App
