import { apiSlice } from "../../app/api/apiSlice"

const initialReportsApiSlice = apiSlice.injectEndpoints({
   endpoints: (builder) => ({
      fetchAllInitialReports: builder.query({
         query: (data) => ({
            url: "/initial-reports",
            params: data
         }),
         keepUnusedDataFor: 5 * 60,
         providesTags: ["Initial-Reports"]
      }),
      
      fetchInitialReport: builder.query({
         query: (data) => `/initial-reports/${ data.id }`,
         keepUnusedDataFor: 3 * 60,
         providesTags: (_res, _err, args) => [{ type: "Initial-Report", id: args.id }]
      }),

      createInitialReport: builder.mutation({
         query: (data) => ({
            url: "/initial-reports",
            method: "POST",
            body: data
         }),
         invalidatesTags: ["Initial-Reports"]
      }),
      
      updateInitialReport: builder.mutation({
         query: (data) => ({
            url: `/initial-reports/${ data.id }`,
            method: "PUT",
            body: data
         }),
         invalidatesTags: (_res, _err, args) => [{ type: "Initial-Report", id: args.id }, "Initial-Reports"]
      }),
      
      deleteInitialReport: builder.mutation({
         query: (data) => ({
            url: `/initial-reports/${ data.id }`,
            method: "DELETE"
         }),
         invalidatesTags: ["Initial-Reports"]
      }),

      fetchInitialReportsByBarangay: builder.query({
         query: (data) => ({
            url: "/initial-reports/barangay",
            params: data
         }),
         providesTags: ["Initial-Reports-Multi"]
      }),

      fetchLatestInitialReport: builder.query({
         query: () => "/initial-reports/latest"
      })
   })
})

export const {
   useFetchAllInitialReportsQuery,
   useFetchInitialReportQuery,
   useCreateInitialReportMutation,
   useUpdateInitialReportMutation,
   useDeleteInitialReportMutation,

   useFetchInitialReportsByBarangayQuery,
   useFetchLatestInitialReportQuery
} = initialReportsApiSlice