import React, { useState } from "react";
// OTHER IMPORTS
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// COMPONENTS
import Navbar from "../navbar/BAFCNavbar";
import SideBarnav from "../sidebar/BAFCSidebar";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { useUpdateUserMutation } from "../utils/features/users/usersApiSlice"
import { useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import { toast } from 'react-toastify'
import { useLocation } from "react-router-dom"

const TITLE = 'GoFarmers | Edit Account'

const MOBILE_REGEX = /^09\d{9}$/

const BAFCEditAccount = () => {
  const [isClicked, setIsClicked] = useState(false);

  const handleButtonClick = () => {
    setIsClicked(!isClicked);
  };

  const [showModal, setshowModal] = useState(false);

  const modalClose = () => setshowModal(false);
  const modalShow = () => setshowModal(true);

  const navigate = useNavigate()
  const location = useLocation()
  const { user } = location.state || {}

  const { handleSubmit, register, formState: { errors } } = useForm({
    defaultValues: user,
  })

  const [updateUser] = useUpdateUserMutation('usersList', {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true
  })

  const onSubmit = async (data) => {
    try {
      const updateObject = {
        id: user.id,
      }

      if (data.mobile !== user.mobile) {
        updateObject.mobile = data.mobile
      }

      if (Object.keys(updateObject).length === 1) {
        toast.info("No changes detected")
        return
      }

      const response = await updateUser(updateObject).unwrap()

      if (response) {
        toast.success("Updated successfully")
        navigate("/bafc-account")
      } else {
        toast.error("Something went wrong")
      }
    } catch (err) {
      toast.error("Failed to update")
      // console.log(err)
    }
  }

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Navbar handleButtonClick={handleButtonClick} />
      <div className="container-fluid" id="tryFix">
        <div className="row flex-nowrap overflow-hidden">
          <SideBarnav />

          <div className="col overflow-x-hidden" id={`${isClicked ? 'tryMove' : ''}`}>
            <style>
              {`
                  #tryMove {
                  margin-left: ${isClicked ? '380px' : '0px'};
                  }
              `}
            </style>
            {/* SECTION TITLE */}
            <h1 className="section">Edit Account</h1>
            <div className="col-md-3">
              <Link to="/bafc-account"><button className="btn btn-success" id="buttons"><i class="bi bi-arrow-left"></i> BACK</button></Link>
            </div>
            <div className="container-fluid" id="customContainer">
              <div className="row">
                <div id="textSection">

                  <form className="ps-4 pe-4 my-4" >
                    <div className="row justify-content-center align-items-center">
                      <label htmlFor="firstName" className="formLabel col-sm-5 my-1 col-form-label text-end">FFRS ID :</label>
                      <div className="col-sm-6">
                        <input type="text" className="textField form-control" id="firstName" value="102-5637425" {...register("ffrs")} disabled readOnly />
                      </div>
                    </div>
                    <div className="row justify-content-center align-items-center">
                      <label htmlFor="firstName" className="formLabel col-sm-5 my-1 col-form-label text-end">FIRST NAME :</label>
                      <div className="col-sm-6">
                        <input type="text" className="textField form-control" id="firstName" {...register("firstName")} disabled readonly />
                      </div>
                    </div>
                    <div className="row justify-content-center align-items-center">
                      <label htmlFor="lastName" className="formLabel col-sm-5 my-1 col-form-label text-end">LAST NAME :</label>
                      <div className="col-sm-6">
                        <input type="text" className="textField form-control" id="lastName" {...register("lastName")} disabled readonly />
                      </div>
                    </div>
                    <div className="row justify-content-center align-items-center">
                      <label htmlFor="barangay" className="formLabel col-sm-5 my-1 col-form-label text-end">BARANGAY :</label>
                      <div className="col-sm-6">
                        <input type="text" className="textField form-control" id="barangay" {...register("barangay")} disabled readOnly />
                      </div>
                    </div>
                    <div className="row justify-content-center align-items-center">
                      <label htmlFor="mobileNumber" className="formLabel col-sm-5 my-1 col-form-label text-end">MOBILE NUMBER :</label>
                      <div className="col-sm-6">
                        <input type="text" className="textField form-control" id="mobileNumber" {...register("mobile", {
                          pattern: {
                            value: MOBILE_REGEX,
                            message: "Invalid mobile number. Mobile number should start with 09 and be 11 digits long."
                          }
                        })} />
                        {errors.mobile && <p className="errmsg">{errors.mobile.message}</p>}
                      </div>
                    </div>
                    <div className="row justify-content-center align-items-center">
                      <label htmlFor="role" className="formLabel col-sm-5 my-1 col-form-label text-end">ROLE:</label>
                      <div className="col-sm-6">
                        <input type="text" className="textField form-control" id="role" defaultValue="user" name="role" disabled readonly />
                      </div>
                    </div>
                    <div className="d-flex justify-content-end">
                      <div><Link to='/bafc-account'><button type="button" className="btn btn-primary me-3" id="cancelButton">CANCEL</button></Link></div>
                      <button type="button" className="btn btn-primary" id="buttons" onClick={modalShow}>UPDATE</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
      <Modal
        show={showModal}
        onHide={modalClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body className="py-4">
          <div className="row mb-4 text-center" style={{ color: '#185D10' }}>
            <h1><i className="bi bi-question-circle"></i></h1>
            <h5 className="fw-bold">ARE YOU SURE <br /> YOU WANT TO UPDATE THIS ACCOUNT?</h5>
          </div>
          <div className="row text-center">
            <div className="col text-end">
              <Button className="m-auto" onClick={modalClose} id="modalCancelbtn">
                Cancel
              </Button>
            </div>
            <div className="col text-start">
              <Button className="m-auto" id="modalConfirmbtn" onClick={() => handleSubmit(onSubmit)()}>Update</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default BAFCEditAccount