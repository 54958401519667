import React, { useState } from "react";
// OTHER IMPORTS
import { Helmet } from 'react-helmet';
import Navbar from "../navbar/SADNavbar";
import SideBarnav from '../sidebar/SADSidebar';

import { useGetAllUserQuery } from "../utils/features/users/usersApiSlice"

const TITLE = 'GoFarmers | Accounts Admin Dashboard'

const SADashboard = () => {
  const [isClicked, setIsClicked] = useState(false);

  const handleButtonClick = () => {
    setIsClicked(!isClicked);
  };

  const { data: usersData } = useGetAllUserQuery()

  const roleCounts = {
    'BAFCadmin': 0,
    'agritech': 0,
    'user': 0,
    'MRO': 0,
    'PRO': 0
  }

  if (usersData?.ids) {
    usersData.ids.forEach((userId) => {
      const user = usersData.entities[userId]
      const role = user.role

      if (role.startsWith('MRO')) {
        roleCounts['MRO'] += 1
      } else if (role.startsWith('PRO')) {
        roleCounts['PRO'] += 1
      } else {
        roleCounts[role] += 1
      }
    })
  }

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Navbar handleButtonClick={handleButtonClick} />
      <div className="container-fluid" id="tryFix">
        <div className="row flex-nowrap overflow-hidden">
          <SideBarnav />
          <div className="col overflow-x-hidden" id={`${isClicked ? 'tryMove' : ''}`}>
            <style>
              {`
                #tryMove {
                  margin-left: ${isClicked ? '380px' : '0px'};
                  
                }
              `}
            </style>
            <h1 className="section">Accounts Admin Dashboard</h1>
            <div className="row justify-content-center g-4 m-3">
              <div className="col-6 col-lg-3 mx-lg-3" >
                <div className="card py-2" id="bg-card">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="h1 text-white text-center" id="fontsize-card">{roleCounts['user']}</div>
                        <div className="text-white text-uppercase text-center" id="fontweight-card">
                          Total Registered Farmers
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 col-lg-4 mx-lg-3">
                <div className="card py-2" id="bg-card">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="h1 font-weight-bold text-white text-center" id="fontsize-card">{roleCounts['BAFCadmin']}</div>
                        <div className="font-weight-bold text-white text-uppercase text-center" id="fontweight-card">
                          Total Registered BAFC
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 col-lg-4 mx-lg-3">
                <div className="card py-2" id="bg-card">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="h1 font-weight-bold text-white text-center" id="fontsize-card">{roleCounts['agritech']}</div>
                        <div className="font-weight-bold text-white text-uppercase text-center" id="fontweight-card">
                          Total Registered Agritech
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 col-lg-4 mx-lg-3">
                <div className="card py-2" id="bg-card">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="h1 font-weight-bold text-white text-center" id="fontsize-card">{roleCounts['MRO']}</div>
                        <div className="font-weight-bold text-white text-uppercase text-center" id="fontweight-card">
                          Total Registered Municipal Report Officers
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 col-lg-4 mx-lg-3">
                <div className="card py-2" id="bg-card">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="h1 font-weight-bold text-white text-center" id="fontsize-card">{roleCounts['PRO']}
                        </div>
                        <div className="font-weight-bold text-white text-uppercase text-center" id="fontweight-card">
                          Total Registered Provincial Report Officers
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div >
        </div >
      </div >
    </>
  )
}

export default SADashboard
