import React, { useEffect, useState } from "react";

// OTHER IMPORTS
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { useForm } from "react-hook-form"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useCreateInitialReportMutation } from "../utils/features/initialReports/initialReportsApiSlice"
import UseAuth from "../utils/hooks/useAuth"

// COMPONENTS
import Navbar from "../navbar/BAFCNavbar";
import SideBarnav from '../sidebar/BAFCSidebar';
import logo from '../images/logo.webp';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const TITLE = 'GoFarmers | Initial Report Form'

const validationSchema = z.object({
  disasterDate: z.coerce.date({ errorMap: () => ({ message: "Disaster Type is invalid." }) }),
  disasterType: z.string({ errorMap: () => ({ message: "Disaster Type is invalid." }) })
    .trim()
    .nonempty("Disaster Type is required.")
    .max(255, "Disaster Type cannot be more than 255 character(s)."),
  disasterName: z.string({ errorMap: () => ({ message: "Disaster Name is invalid." }) })
    .trim()
    .max(255, "Disaster Name cannot be more than 255 character(s)."),
  cropType: z.string({ errorMap: () => ({ message: "Crop Type is invalid." }) })
    .trim()
    .nonempty("Crop Type is required.")
    .max(255, "Crop Type cannot be more than 255 character(s)."),
  standingCrops: z.coerce.number({ errorMap: () => ({ message: "Area of Standing Crops is invalid." }) })
    .positive("Area of Standing Crops is invalid."),
  farmersCount: z.coerce.number({ errorMap: () => ({ message: "Number of Affected Farmers is invalid." }) })
    .positive("Number of Affected Farmers is invalid."),
  seedType: z.string({ errorMap: () => ({ message: "Seed Type is invalid." }) })
    .trim()
    .nonempty("Seed Type is required.")
    .max(255, "Seed Type cannot be more than 255 character(s)."),
  totallyDamaged: z.coerce.number({ errorMap: () => ({ message: "Totally Damaged Area is invalid." }) })
    .positive("Totally Damaged Area is invalid."),
  partiallyDamaged: z.coerce.number({ errorMap: () => ({ message: "Partially Damaged Area is invalid." }) })
    .positive("Partially Damaged Area is invalid."),
  totalDamaged: z.coerce.number({ errorMap: () => ({ message: "Total Damaged Area is invalid." }) }),
  yieldBefore: z.coerce.number({ errorMap: () => ({ message: "Yield Before is invalid." }) })
    .positive("Yield Before is invalid."),
  yieldAfter: z.coerce.number({ errorMap: () => ({ message: "Yield After is invalid." }) })
    .positive("Yield After is invalid."),
  yieldLoss: z.coerce.number({ errorMap: () => ({ message: "Yield Loss is invalid." }) }),
  inputCost: z.coerce.number({ errorMap: () => ({ message: "Input Cost is invalid." }) })
    .positive("Input Cost is invalid."),
  totalCost: z.coerce.number({ errorMap: () => ({ message: "Total Cost is invalid." }) })
})

const BAFCAddInitialReport = () => {
  const navigate = useNavigate()

  const [isClicked, setIsClicked] = useState(false)
  const [showModal, setshowModal] = useState(false)

  const modalClose = () => setshowModal(false)
  const modalShow = () => setshowModal(true)

  const handleButtonClick = () => {
    setIsClicked(!isClicked)
  }

  const { handleSubmit, register, reset, setValue, formState: { errors }, watch } = useForm({
    resolver: zodResolver(validationSchema),
    reValidateMode: "onChange"
  })

  // Auto-compute Total Damaged Area
  const [_totallyDamaged, _partiallyDamaged] = watch(["totallyDamaged", "partiallyDamaged"])

  useEffect(() => {
    if (_totallyDamaged && _partiallyDamaged && +_totallyDamaged && +_partiallyDamaged) {
      let totalDamaged = (+_totallyDamaged + +_partiallyDamaged)

      setValue("totalDamaged", (Number)(totalDamaged.toFixed(2)))
    }
  }, [_totallyDamaged, _partiallyDamaged, setValue])

  // Auto-compute Yield Loss
  const [_yieldBefore, _yieldAfter] = watch(["yieldBefore", "yieldAfter"])

  useEffect(() => {
    if (_yieldBefore && _yieldAfter && +_yieldBefore && +_yieldAfter) {
      let yieldLoss = (+_yieldAfter / +_yieldBefore)

      yieldLoss *= 100
      yieldLoss -= 100
      yieldLoss *= -1

      setValue("yieldLoss", (Number)(yieldLoss.toFixed(2)))
    }
  }, [_yieldBefore, _yieldAfter, setValue])

  // Auto-compute Total Cost
  const [_inputCost, _totalDamaged] = watch(["inputCost", "totalDamaged"])

  useEffect(() => {
    if (_inputCost && _totalDamaged && +_inputCost && +_totalDamaged) {
      let totalCost = (+_inputCost * +_totalDamaged)

      setValue("totalCost", (Number)(totalCost.toFixed(2)))
    }
  }, [_inputCost, _totalDamaged, setValue])

  // Submit initial report
  const [createInitialReportMutation] = useCreateInitialReportMutation()

  const { userId, barangay } = UseAuth()

  async function handleSubmitForm(data) {
    const createdInitialReport = await createInitialReportMutation({ userId: userId, ...data })

    if (createdInitialReport.error) {
      // console.error(createdInitialReport.error)
      return
    }
    // console.log("Created")
    reset()
    navigate("/bafc-initial-reports")
  }

  function handleErrorForm(errors) {
    // console.info(errors)
  }

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <Navbar handleButtonClick={handleButtonClick} />

      <div className="container-fluid" id="tryFix">
        <div className="row flex-nowrap  overflow-hidden">
          <SideBarnav />
          <div className="col overflow-x-hidden" id={`${isClicked ? 'tryMove' : ''}`}>
            <style>
              {`
                #tryMove {
                  margin-left: ${isClicked ? '380px' : '0px'}; 
                }
              `}
            </style>
            {/* SECTION TITLE */}
            <h1 className="section">Add Barangay Initial Report</h1>

            <div className="col-md-3">
              <Link to="/bafc-initial-reports"><button className="btn btn-success" id="buttons"><i className="bi bi-arrow-left"></i> BACK</button></Link>
            </div>

            <div className="container" id="damageContainer">
              <div className="row justify-content-center align-items-center">
                <div id="textSection">
                  <div className="logoForms">
                    <img src={logo} alt="GoFarmers" />
                  </div>
                  <h2 className="centerTitle">Barangay Initial Report</h2>
                  <form id="form-id" onSubmit={handleSubmit(handleSubmitForm, handleErrorForm)}>
                    <div className="form">
                      <div className="formSection"></div>
                      <h5 className="ms-4 fw-bold">General Information</h5>
                      <div className="form">
                        <div className="row justify-content-center align-items-center" >
                          <label htmlFor="disasterDate" className="fw-medium text-end col-sm-5 my-1 col-form-label"><i class="bi-asterisk asterisk"></i> DATE OF OCCURENCE :</label>
                          <div className="col-sm-5">
                            <input type="date" className="textField form-control" {...register("disasterDate")} id="disasterDate" autoFocus />
                            {errors?.disasterDate && <small className="text-danger text-sm">{errors.disasterDate.message}</small>}
                          </div>
                        </div>

                        <div className="row justify-content-center align-items-center">
                          <label htmlFor="disasterType" className="fw-medium  text-end col-sm-5 my-1 col-form-label"><i class="bi-asterisk asterisk"></i> TYPE OF DISASTER :</label>
                          <div className="col-sm-5">
                            <select className="textField form-select" {...register("disasterType")} id="disasterType" >
                              <option value="">Select an Option</option>
                              <option value="Drought">Drought</option>
                              <option value="Typhoon">Typhoon</option>
                              <option value="Flood">Flood</option>
                            </select>
                            {errors?.disasterType && <small className="text-danger text-sm">{errors.disasterType.message}</small>}
                          </div>
                        </div>

                        <div className="row justify-content-center align-items-center" >
                          <label htmlFor="disasterName" className="fw-medium text-end col-sm-5 my-1 col-form-label">NAME OF DISASTER (OPTIONAL) :</label>
                          <div className="col-sm-5">
                            <input type="text" className="textField form-control" {...register("disasterName")} id="disasterName" />
                            {errors?.disasterName && <small className="text-danger text-sm">{errors.disasterName.message}</small>}
                          </div>
                        </div>

                        <div className="form">
                          <div className="formSection"></div>

                          <h5 className="ms-4 fw-bold">Geographic Information</h5>

                          <div className="row justify-content-center align-items-center">
                            <label htmlFor="region" className="fw-medium  text-end col-sm-5 my-1 col-form-label">REGION :</label>
                            <div className="col-sm-5">
                              <input type="numeric" {...register("region")} className="textField form-control" id="region" defaultValue="3" tabIndex={-1} readOnly />
                            </div>
                          </div>

                          <div className="row justify-content-center align-items-center">
                            <label htmlFor="province" className="fw-medium  text-end col-sm-5 my-1 col-form-label">PROVINCE :</label>
                            <div className="col-sm-5">
                              <input type="text" {...register("province")} className="textField form-control" id="province" defaultValue="Bulacan" tabIndex={-1} readOnly />
                            </div>
                          </div>

                          <div className="row justify-content-center align-items-center">
                            <label htmlFor="municipality" className="fw-medium  text-end col-sm-5 my-1 col-form-label">MUNICIPALITY :</label>
                            <div className="col-sm-5">
                              <input type="text" {...register("municipality")} className="textField form-control" id="municipality" defaultValue="San Miguel" tabIndex={-1} readOnly />
                            </div>
                          </div>

                          <div className="row justify-content-center align-items-center">
                            <label htmlFor="barangay" className="fw-medium  text-end col-sm-5 my-1 col-form-label">BARANGAY :</label>
                            <div className="col-sm-5">
                              <input type="text" {...register("barangay")} className="textField form-control" id="barangay" defaultValue={barangay} tabIndex={-1} readOnly />
                            </div>
                          </div>

                          <div className="formSection"></div>

                          <h5 className="ms-4 fw-bold">Particulars</h5>

                          <div className="row justify-content-center align-items-center">
                            <label htmlFor="cropType" className="fw-medium  text-end col-sm-5 my-1 col-form-label"><i class="bi-asterisk asterisk"></i> ECOSYSTEM :</label>
                            <div className="col-sm-5">
                              <select className="textField form-select" {...register("cropType")} id="cropType" >
                                <option value="">Select an Option</option>
                                <option value="Irrigated">Irrigated</option>
                                <option value="Raised Field - lowland">Raised Field - lowland</option>
                                <option value="Raised Field - upland"> Raised Field - upland</option>
                              </select>
                              {errors?.cropType && <small className="text-danger text-sm">{errors.cropType.message}</small>}
                            </div>
                          </div>
                          <div className="row justify-content-center align-items-center">
                            <label htmlFor="standingCrops" className="fw-medium  text-end col-sm-5 my-1 col-form-label"><i class="bi-asterisk asterisk"></i> AREA OF STANDING CROPS :</label>
                            <div className="col-sm-5">
                              <input type="number" {...register("standingCrops")} id="standingCrops" className="textField form-control" min="0" defaultValue="0" />
                              {errors?.standingCrops && <small className="text-danger text-sm">{errors.standingCrops.message}</small>}
                            </div>
                          </div>

                          <div className="row justify-content-center align-items-center">
                            <label htmlFor="farmersCount" className="fw-medium  text-end col-sm-5 my-1 col-form-label"><i class="bi-asterisk asterisk"></i> NUMBER OF FARMERS AFFECTED :</label>
                            <div className="col-sm-5">
                              <input type="number" {...register("farmersCount")} id="farmersCount" className="textField form-control" min="0" defaultValue="0" />
                              {errors?.farmersCount && <small className="text-danger text-sm">{errors.farmersCount.message}</small>}
                            </div>
                          </div>

                          <div className="row justify-content-center align-items-center">
                            <label htmlFor="seedType" className="fw-medium  text-end col-sm-5 my-1 col-form-label"><i class="bi-asterisk asterisk"></i> SEED TYPE :</label>
                            <div className="col-sm-5">
                              <select className="textField form-select" {...register("seedType")} id="seedType">
                                <option value="">Select an Option</option>
                                <option value="Inbred">Inbred</option>
                                <option value="Hybrid">Hybrid</option>
                              </select>
                              {errors?.seedType && <small className="text-danger text-sm">{errors.seedType.message}</small>}
                            </div>
                          </div>

                          <h5 className="ms-4 fw-semibold pt-4">Area Affected (HA.) SEEDLINGS (BAGS)</h5>

                          <div className="row justify-content-center align-items-center">
                            <label htmlFor="totallyDamaged" className="fw-medium  text-end col-sm-5 my-1 col-form-label"><i class="bi-asterisk asterisk"></i> TOTALLY DAMAGED :</label>
                            <div className="col-sm-5">
                              <div className="form-outline">
                                <input type="number" step="0.01" {...register("totallyDamaged")} id="totallyDamaged" className="textField form-control" min="0" max="100" defaultValue="0" />
                                {errors?.totallyDamaged && <small className="text-danger text-sm">{errors.totallyDamaged.message}</small>}
                              </div>
                            </div>
                          </div>

                          <div className="row justify-content-center align-items-center">
                            <label htmlFor="partiallyDamaged" className="fw-medium  text-end col-sm-5 my-1 col-form-label"><i class="bi-asterisk asterisk"></i> PARTIALLY DAMAGED :</label>
                            <div className="col-sm-5">
                              <input type="number" step="0.01" {...register("partiallyDamaged")} id="partiallyDamaged" className="textField form-control" min="0" defaultValue="0" />
                              {errors?.partiallyDamaged && <small className="text-danger text-sm">{errors.partiallyDamaged.message}</small>}
                            </div>
                          </div>

                          <div className="row justify-content-center align-items-center">
                            <label htmlFor="totallyDamaged" className="fw-medium  text-end col-sm-5 my-1 col-form-label">TOTAL :</label>
                            <div className="col-sm-5">
                              <input type="number" step="0.01" {...register("totalDamaged")} id="totallyDamaged" className="textField form-control" defaultValue="0" tabIndex={-1} readOnly />
                              {errors?.totalDamaged && <small className="text-danger text-sm">{errors.totalDamaged.message}</small>}
                            </div>
                          </div>

                          <h5 className="ms-4 fw-semibold pt-4">Yield per Hectare (M.T)</h5>

                          <div className="row justify-content-center align-items-center">
                            <label htmlFor="yieldBefore" className="fw-medium  text-end col-sm-5 my-1 col-form-label"><i class="bi-asterisk asterisk"></i> BEFORE CALAMITY :</label>
                            <div className="col-sm-5">
                              <input type="number" step="0.01" {...register("yieldBefore")} id="yieldBefore" className="textField form-control" min="0" defaultValue="0" />
                              {errors?.yieldBefore && <small className="text-danger text-sm">{errors.yieldBefore.message}</small>}
                            </div>
                          </div>

                          <div className="row justify-content-center align-items-center">
                            <label htmlFor="yieldAfter" className="fw-medium  text-end col-sm-5 my-1 col-form-label"><i class="bi-asterisk asterisk"></i> AFTER CALAMITY :</label>
                            <div className="col-sm-5">
                              <input type="number" step="0.01" {...register("yieldAfter")} id="yieldAfter" className="textField form-control" min="0" defaultValue="0" />
                              {errors?.yieldAfter && <small className="text-danger text-sm">{errors.yieldAfter.message}</small>}
                            </div>
                          </div>

                          <div className="row justify-content-center align-items-center">
                            <label htmlFor="yieldLoss" className="fw-medium  text-end col-sm-5 my-1 col-form-label">YIELD LOSS (%) :</label>
                            <div className="col-sm-5">
                              <input type="number" step="0.01" {...register("yieldLoss")} id="yieldLoss" className="textField form-control" defaultValue="0" tabIndex={-1} readOnly />
                              {errors?.yieldLoss && <small className="text-danger text-sm">{errors.yieldLoss.message}</small>}
                            </div>
                          </div>

                          <h5 className="ms-4 fw-semibold pt-4">Total Losses(Based on Cost of Input)</h5>

                          <div className="row justify-content-center align-items-center">
                            <label htmlFor="inputCost" className="fw-medium  text-end col-sm-5 my-1 col-form-label"><i class="bi-asterisk asterisk"></i> COST OF INPUT/HA. :</label>
                            <div className="col-sm-5">
                              <input type="number" step="0.01" {...register("inputCost")} id="inputCost" className="textField form-control" min="0" defaultValue="0" />
                              {errors?.inputCost && <small className="text-danger text-sm">{errors.inputCost.message}</small>}
                            </div>
                          </div>

                          <div className="row justify-content-center align-items-center">
                            <label htmlFor="totalCost" className="fw-medium  text-end col-sm-5 my-1 col-form-label">TOTAL VALUE :</label>
                            <div className="col-sm-5">
                              <input type="number" step="0.01" {...register("totalCost")} id="totalCost" className="textField form-control" defaultValue="0" tabIndex={-1} readOnly />
                              {errors?.totalCost && <small className="text-danger text-sm">{errors.totalCost.message}</small>}
                            </div>
                          </div>
                        </div>

                      </div>

                    </div>


                  </form>
                </div>
              </div>
              {/* BUTTONS */}
              <div className="row justify-content-center align-items-center pt-3">
                <div className="d-flex justify-content-end">
                  <div>
                    <Link to='/bafc-initial-reports'>
                      <button type="button" className="btn btn-primary me-3" id="cancelButton">CANCEL</button>
                    </Link>
                  </div>
                  <button type="button" className="btn btn-primary" id="buttons" onClick={modalShow}>ADD REPORT</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showModal}
        onHide={modalClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body className="py-4">
          <div className="row mb-2 text-center" style={{ color: '#185D10' }}>
            <h1><i className="bi bi-question-circle"></i></h1>
            <h5 className="fw-bold">ARE YOU SURE YOU WANT TO ADD THIS INNITIAL REPORT?</h5>
            <h6 className="fw-normal"><i class="bi bi-lightbulb-fill bulb"></i>Make sure to double check the initial report before <br />clicking the add button since you can't edit <br />
              an initial report once added. </h6>
          </div>
          <div className="row text-center">
            <div className="col text-end">
              <Button className="m-auto" onClick={modalClose} id="modalCancelbtn">
                CANCEL
              </Button>
            </div>
            <div className="col text-start">
              <Button className="m-auto" type="submit" form="form-id" id="modalConfirmbtn" onClick={modalClose}>ADD REPORT</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default BAFCAddInitialReport