import React, { useState, useEffect } from "react";
// OTHER IMPORTS
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useNavigate } from "react-router-dom"

import { useForm } from "react-hook-form"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod";
import { format, isAfter, isSameDay } from "date-fns"
import { useFetchDistributionScheduleQuery, useUpdateDistributionScheduleMutation } from "../utils/features/distribution/distributionApiSlice";

// COMPONENTS
import Navbar from "../navbar/MRONavbar";
import SideBarnav from "../sidebar/MROSidebar";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const TITLE = 'GoFarmers | Profile Settings'

const validationSchema = z.object({
  scheduledAt: z.coerce.date({ errorMap: () => ({ message: "Scheduled Date is invalid." }) })
    .refine((value) => isSameDay(new Date(value), new Date()) || isAfter(new Date(value), new Date()), "Scheduled Date cannot be from the past.")
})

const MROEditSchedule = () => {
  const params = useParams()

  const [isClicked, setIsClicked] = useState(false);

  const handleButtonClick = () => {
    setIsClicked(!isClicked);
  };

  const [showModal, setshowModal] = useState(false);

  const modalClose = () => setshowModal(false);
  const modalShow = () => setshowModal(true);

  const navigate = useNavigate()

  const { handleSubmit, register, reset, formState: { errors } } = useForm({
    resolver: zodResolver(validationSchema)
  })

  const { data: distributionSchedule, isLoading: distributionScheduleIsLoading } = useFetchDistributionScheduleQuery({
    id: params.id
  })

  useEffect(() => {
    if (!distributionScheduleIsLoading && distributionSchedule?.data) {
      reset({
        report: distributionSchedule.data.summaryReport._id,
        scheduledAt: format(new Date(distributionSchedule.data.scheduledAt), "yyyy-MM-dd"),
        bafc: `${distributionSchedule.data.receiver.firstName} ${distributionSchedule.data.receiver.lastName}`,
        mobile: distributionSchedule.data.receiver.mobile
      })
    }
  }, [distributionSchedule, distributionScheduleIsLoading, reset])

  const [updateDistributionSchedule] = useUpdateDistributionScheduleMutation()

  async function handleEditDistributionSchedule(data) {
    const updatedDistributionSchedule = await updateDistributionSchedule({
      id: params.id,
      scheduledAt: data.scheduledAt
    })

    if (updatedDistributionSchedule.error) {
      // console.error("Failed to update Distribution Schedule!")
      return
    }
    navigate("/mro-distribution-schedule")
    // console.log("Successfully updated Distribution Schedule!")
  }

  return (

    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Navbar handleButtonClick={handleButtonClick} />
      <div className="container-fluid" id="tryFix">
        <div className="row flex-nowrap overflow-hidden">
          <SideBarnav />

          <div className="col overflow-x-hidden" id={`${isClicked ? 'tryMove' : ''}`}>
            <style>
              {`
                #tryMove {
                margin-left: ${isClicked ? '380px' : '0px'};
                
                }
            `}
            </style>
            {/* SECTION TITLE */}
            <h1 className="section">Edit Schedule</h1>

            <div className="col-md-3">
              <Link to="/mro-distribution-schedule"><button className="btn btn-success" id="buttons"><i class="bi bi-arrow-left"></i> BACK</button></Link>
            </div>

            <div className="row justify-content-center mt-4 pb-3">
              <div className="col-4 col-md-4 overflow-auto p-0 m-0" id="textSection">

                <form className="ps-4 pe-4 my-4" id="form-id" onSubmit={handleSubmit(handleEditDistributionSchedule)}>
                  <div className="row justify-content-center align-items-center">
                    <label htmlFor="role" className="formLabel col-sm-5 my-1 col-form-label text-end">REPORT:</label>
                    <div className="col-sm-6">
                      <select className="textField form-control" id="role" name="role" disabled {...register("report")}>
                        {
                          !distributionScheduleIsLoading && distributionSchedule?.data && (
                            <option value={distributionSchedule?.data.summaryReport._id}>{`${distributionSchedule?.data.summaryReport.barangay} - ${distributionSchedule?.data.summaryReport?.initialReport.disasterName || "Unnamed"} - ${distributionSchedule?.data.summaryReport.cropStage}`}</option>
                          )
                        }
                      </select>
                    </div>
                  </div>
                  <div className="row justify-content-center align-items-center" >
                    <label htmlFor="date" className="formLabel col-sm-5 my-1 col-form-label text-end">DATE:</label>
                    <div className="col-sm-6">
                      <input type="date" className="textField form-control" id="date" {...register("scheduledAt")} />
                      {errors?.scheduledAt && <small className="text-danger">{errors.scheduledAt.message}</small>}
                    </div>
                  </div>

                  <div className="row justify-content-center align-items-center">
                    <label htmlFor="name" className="formLabel col-sm-5 my-1 col-form-label text-end">BAFC NAME:</label>
                    <div className="col-sm-6">
                      <input type="text" className="textField form-control" id="name" {...register("bafc")} disabled />
                    </div>
                  </div>
                  <div className="row justify-content-center align-items-center mb-2">
                    <label htmlFor="mobileNumber" className="formLabel col-sm-5 my-1 col-form-label text-end">MOBILE:</label>
                    <div className="col-sm-6">
                      <input type="number" className="textField form-control" id="mobileNumber" {...register("mobile")} disabled />
                    </div>
                  </div>

                  {/* BUTTONS */}
                  <div className="d-flex justify-content-end">
                    <div>
                      <Link to='/mro-distribution-schedule'>
                        <button type="button" className="btn btn-primary me-3" id="cancelButton">CANCEL</button>
                      </Link>
                    </div>
                    <button type="button" onClick={modalShow} className="btn btn-primary" id="buttons">UPDATE</button>
                  </div>
                </form>

              </div>

            </div>
          </div>
        </div>
      </div>


      <Modal
        show={showModal}
        onHide={modalClose}
        backdrop="static"
        keyboard={false}
        centered
      >

        <Modal.Body className="py-4">
          <div className="row mb-4 text-center" style={{ color: '#185D10' }}>
            <h1><i className="bi bi-question-circle"></i></h1>
            <h5 className="fw-bold">ARE YOU SURE <br /> YOU WANT TO UPDATE THIS SCHEDULE?</h5>
          </div>
          <div className="row text-center">
            <div className="col text-end">
              <Button className="m-auto" onClick={modalClose} id="modalCancelbtn">
                Cancel
              </Button>
            </div>
            <div className="col text-start">
              <Button className="m-auto" type="submit" form="form-id" onClick={modalClose} id="modalConfirmbtn">CONFIRM</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default MROEditSchedule