import React, { useState } from "react";
// OTHER IMPORTS
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { useFetchBAFCPendingFormsPerCropStageQuery } from "../utils/features/_forms/_formsApiSlice"

// COMPONENTS IMPORTS
import Navbar from "../navbar/BAFCNavbar";
import SideBarnav from "../sidebar/BAFCSidebar";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const TITLE = 'GoFarmers | Farmer Reports'

const BAFCFarmerReports = () => {
  // TOGGLE SIDEBAR
  const [isClicked, setIsClicked] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const handleButtonClick = () => {
    setIsClicked(!isClicked);
  };

  // MODALS
  const [showModal, setshowModal] = useState(false);
  const [showModal2, setshowModal2] = useState(false);

  const modalClose = () => setshowModal(false);
  const modalClose2 = () => setshowModal2(false);

  const params = useParams()

  const [searchParams] = useSearchParams()

  const { data: forms, isLoading: formsIsLoading } = useFetchBAFCPendingFormsPerCropStageQuery({
    cropStage: params.cropStage,
    sortField: searchParams.get("sortField")
  })

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Navbar handleButtonClick={handleButtonClick} />
      <div className="container-fluid" id="tryFix">
        <div className="row flex-nowrap overflow-hidden">
          <SideBarnav />
          <div className="col overflow-x-hidden" id={`${isClicked ? 'tryMove' : ''}`}>
            <style>
              {`
                #tryMove {
                  margin-left: ${isClicked ? '380px' : '0px'};
                  
                }
              `}
            </style>
            {/* SECTION TITLE */}
            <h1 className="section">Farmer Reports</h1>

            {/* BUTTONS */}
            <div className="topButtons">
              <div className="row buttonContainer">
                <div className="doubleContainer">
                  <Link to="/bafc-generated-reports"><button className="btn btn-success" id="buttons"><i class="bi bi-arrow-left"></i> BACK</button></Link>
                  <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" id="buttons">
                    SORT BY
                  </button>
                  <ul className="dropdown-menu" id="dropDown">
                    {/* Highest to lowest area of standing crop */}
                    <li><Link className="dropdown-item" to={{ search: "?sortField=cropArea" }}>Area of Standing Crop</Link></li>
                    <li><Link className="dropdown-item" to={{ search: "?sortField=name" }}>A-Z</Link></li>
                    <li><Link className="dropdown-item" to={{ search: "?sortField=-name" }}>Z-A</Link></li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="row mt-4 tableContainer">
              <div className="col-11 col-lg-11 overflow-auto p-0" id="tableBorder">
                <table className="table mb-0 text-center table-hover align-middle" style={{ borderColor: '#185D10' }}>
                  <thead>
                    <tr id="thead1">
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <thead>
                    <tr className="align-middle" id="thead1">
                      <th scope="col">FFRS ID</th>
                      <th scope="col">FORM ID</th>
                      <th scope="col">FARMER NAME</th>
                      <th scope="col">AREA OF STANDING CROP </th>
                      <th scope="col">STAGE OF CROP DEVELOPMENT</th>
                      <th scope="col">ACTION</th>
                      <th scope="col">STATUS</th>
                    </tr>
                  </thead>
                  <thead>
                    <tr id="thead1">
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      !formsIsLoading && forms?.data.length ? forms.data.map((form) => (
                        <tr className="tableData" key={form._id}>
                          <td>{form.user.ffrs}</td>
                          <td>{form._id}</td>
                          <td>{form.user.firstName + " " + form.user.lastName}</td>
                          <td>{form.cropArea}</td>
                          <td>{form.cropStage}</td>
                          <td>
                            <div className="d-flex flex-row justify-content-center">
                              <div>
                                <Link to={`/bafc-damaged-forms/${params.cropStage}/${form._id}`} className="btn btn-primary py-2 mx-auto btn-sm" id="editButton"><i className="bi fs-4 bi-pencil-square"></i></Link>
                              </div>
                            </div>
                          </td>
                          <td>
                            {
                              form.status === "bafc-waiting" ?
                                <button type="button" className="btn btn-primary" id="buttonApprove" disabled>PENDING</button> :
                                form.status === "bafc-approved" ?
                                  <button type="button" className="btn btn-primary" id="buttonApprove" disabled>APPROVED</button> :
                                  form.status === "bafc-declined" ?
                                    <button type="button" className="btn btn-primary" id="buttonDecline" disabled>DECLINED</button> : null
                            }
                          </td>
                        </tr>
                      )) :
                        <tr className="tableData">
                          <td colSpan={100}>There are no pending forms.</td>
                        </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="floatPosition">
        <div
          style={{ backgroundColor: '#185D10' }}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          <i class="bi bi-lightbulb-fill floatIcon" />
        </div>
        {showTooltip && (
          <div className="customTooltip">
            <span className="tooltipText">You can now edit, download, and mark each report as approve or decline using the action button.</span>
          </div>
        )}
      </div>
      {/* MODAL FOR DELETE REPORT BUTTON*/}
      <Modal
        show={showModal}
        onHide={modalClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body className="py-4">
          <div className="row mb-4 text-center" style={{ color: '#185D10' }}>
            <h1><i className="bi bi-question-circle"></i></h1>
            <h5 className="fw-bold">ARE YOU SURE <br /> YOU WANT TO DELETE THIS FORM?</h5>
          </div>
          <div className="row text-center">
            <div className="col text-end">
              <Button className="m-auto" onClick={modalClose} id="modalCancelbtn">
                Cancel
              </Button>
            </div>
            <div className="col text-start">
              <Button className="m-auto" onClick={modalClose} id="modalDeletebtn">Delete</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* MODAL FOR RECEIVED BUTTON*/}
      <Modal
        show={showModal2}
        onHide={modalClose2}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body className="py-4">
          <div className="row mb-4 text-center" style={{ color: '#185D10' }}>
            <h1><i className="bi bi-question-circle"></i></h1>
            <h5 className="fw-bold">ARE YOU SURE <br /> YOU WANT TO MARK FFRS ID 23456 AS RECEIVED?</h5>
          </div>
          <div className="row text-center">
            <div className="col text-end">
              <Button className="m-auto" onClick={modalClose2} id="modalCancelbtn">
                Cancel
              </Button>
            </div>
            <div className="col text-start">
              <Button className="m-auto" onClick={modalClose2} id="modalConfirmbtn">Confirm</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default BAFCFarmerReports;

