import React, { useState } from "react";
// STEPPER COMPONENT
import Stepper from "../components/stepper/Stepper";

import formatDate from "date-fns/format";
import UseAuth from "../utils/hooks/useAuth";
import { useFetchOngoingSummaryReportsQuery } from "../utils/features/summaryReports/summaryReportsApiSlice";

// OTHER IMPORTS
import { Helmet } from "react-helmet";
import { Link, useSearchParams } from "react-router-dom";
import Navbar from "../navbar/ATNavbar";
import SideBarnav from "../sidebar/ATSidebar";

const TITLE = "GoFarmers | Summary Reports";

const ATProcess = () => {
  const [isClicked, setIsClicked] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showTooltip2, setShowTooltip2] = useState(false);

  const handleButtonClick = () => {
    setIsClicked(!isClicked);
  };

  const [searchParams, setSearchParams] = useSearchParams({
    barangay: "",
  });

  const { data: ongoingReports, isLoading: ongoingReportsIsLoading } =
    useFetchOngoingSummaryReportsQuery({
      barangay: searchParams.get("barangay"),
    });

  const handleChangeBarangay = (barangay) => {
    setSearchParams(
      (searchParams) => {
        searchParams.set("barangay", barangay);
        return searchParams;
      },
      { replace: true }
    );
  };

  const { barangay } = UseAuth();

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Navbar handleButtonClick={handleButtonClick} />
      <div className="container-fluid" id="tryFix">
        <div className="row flex-nowrap overflow-hidden">
          <SideBarnav />
          <div
            className="col overflow-x-hidden"
            id={`${isClicked ? "tryMove" : ""}`}
          >
            <style>
              {`
                #tryMove {
                  margin-left: ${isClicked ? "380px" : "0px"};  
                }
              `}
            </style>

            {/* SECTION TITLE */}
            <h1 className="section"> Summary Reports</h1>

            {/* REHABILITATION PLAN PROCESS - PROGRESS BAR */}
            <div className="row">
              <div className="singleContainer">
                <button
                  className="btn btn-secondary dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  id="buttons"
                >
                  SELECT BARANGAY
                </button>
                <ul className="dropdown-menu" id="dropDown">
                  <li>
                    <Link
                      className="dropdown-item"
                      to=""
                      onClick={() => handleChangeBarangay("")}
                    >
                      -- None --
                    </Link>
                  </li>
                  {barangay?.length
                    ? barangay.map((brgy, idx) => (
                      <li>
                        <Link
                          className="dropdown-item"
                          to=""
                          onClick={() => handleChangeBarangay(brgy)}
                          key={`brgy-${idx}`}
                        >
                          {brgy}
                        </Link>
                      </li>
                    ))
                    : null}
                </ul>
              </div>
            </div>
            <div className="pt-5">
              <div
                className="d-flex flex-column w-100"
                style={{ rowGap: "1.5rem" }}
              >
                {!ongoingReportsIsLoading && ongoingReports?.data.length ? (
                  ongoingReports.data.map((ongoingReport) => (
                    <div
                      className="d-flex flex-column w-100"
                      style={{ rowGap: "0.25rem" }}
                    >
                      <h1 className="process-summary-title px-5">
                        Brgy. {ongoingReport.barangay} -{" "}
                        {ongoingReport.initialReport.disasterName ||
                          "Unnamed Disaster"}{" "}
                        - {ongoingReport.cropStage} Stage
                      </h1>
                      <span className="process-summary-subtitle px-5">
                        Created at{" "}
                        {formatDate(
                          new Date(ongoingReport.createdAt),
                          "MM-dd-yyyy 'at' h:mm a"
                        )}
                      </span>
                      <Stepper report={ongoingReport} key={ongoingReport._id} />
                    </div>
                  ))
                ) : (
                  <h1 className="text-center">
                    There are no summary reports available.
                  </h1>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* FLOATING INFO BUTTON */}
        {!ongoingReportsIsLoading && ongoingReports?.data.length ? (
          ongoingReports.data.map((ongoingReport) => (
            <div className="floatPosition">
              <div
                style={{ backgroundColor: "#185D10" }}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              >
                <i class="bi bi-lightbulb-fill floatIcon" />
              </div>
              {showTooltip && (
                <div className="customTooltip2">
                  <span className="tooltipText">
                    Use this modal to monitor the progress of the
                    post-disaster crop rehabilitation process of your barangay scope.
                  </span>
                </div>
              )}
            </div>
          ))
        ) : (
          <div className="floatPosition">
            <div
              style={{ backgroundColor: "#185D10" }}
              onMouseEnter={() => setShowTooltip2(true)}
              onMouseLeave={() => setShowTooltip2(false)}
            >
              <i class="bi bi-lightbulb-fill floatIcon" />
            </div>
            {showTooltip2 && (
              <div className="customTooltip2">
                <span className="tooltipText">
                  The progress bar will show after you submit the generated
                  report of this barangay in the generated reports module.
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ATProcess;
