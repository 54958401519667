import React, { useState } from "react";
// STEPPER COMPONENT
import Stepper from "../components/stepper/Stepper";

import formatDate from "date-fns/format";
import { useFetchOngoingSummaryReportsQuery } from "../utils/features/summaryReports/summaryReportsApiSlice";

// OTHER IMPORTS
import { Helmet } from "react-helmet";
import Navbar from "../navbar/BAFCNavbar";
import SideBarnav from "../sidebar/BAFCSidebar";

const TITLE = "GoFarmers | Summary Reports";

const BAFCProcess = () => {
  const [isClicked, setIsClicked] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showTooltip2, setShowTooltip2] = useState(false);

  const handleButtonClick = () => {
    setIsClicked(!isClicked);
  };

  const { data: ongoingReports, isLoading: ongoingReportsIsLoading } =
    useFetchOngoingSummaryReportsQuery();

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Navbar handleButtonClick={handleButtonClick} />
      <div className="container-fluid" id="tryFix">
        <div className="row flex-nowrap overflow-hidden">
          <SideBarnav />
          <div
            className="col overflow-x-hidden"
            id={`${isClicked ? "tryMove" : ""}`}
          >
            <style>
              {`
                #tryMove {
                  margin-left: ${isClicked ? "380px" : "0px"};  
                }
              `}
            </style>

            {/* SECTION TITLE */}
            <h1 className="section"> Summary Reports</h1>
            <div className="pt-5">
              <div
                className="d-flex flex-column w-100"
                style={{ rowGap: "1.5rem" }}
              >
                {!ongoingReportsIsLoading && ongoingReports?.data.length ? (
                  ongoingReports.data.map((ongoingReport) => (
                    <div
                      className="d-flex flex-column w-100"
                      style={{ rowGap: "0.25rem" }}
                    >
                      <h1 className="process-summary-title px-5">
                        Brgy. {ongoingReport.barangay} -{" "}
                        {ongoingReport.initialReport.disasterName ||
                          "Unnamed Disaster"}{" "}
                        - {ongoingReport.cropStage} Stage
                      </h1>
                      <span className="process-summary-subtitle px-5">
                        Created at{" "}
                        {formatDate(
                          new Date(ongoingReport.createdAt),
                          "MM-dd-yyyy 'at' h:mm a"
                        )}
                      </span>
                      <Stepper report={ongoingReport} key={ongoingReport._id} />
                    </div>
                  ))
                ) : (
                  <h1 className="text-center">
                    There are no summary reports available.
                  </h1>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* FLOATING INFO BUTTON */}
        {!ongoingReportsIsLoading && ongoingReports?.data.length ? (
          ongoingReports.data.map((ongoingReport) => (
            <div className="floatPosition">
              <div
                style={{ backgroundColor: "#185D10" }}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              >
                <i class="bi bi-lightbulb-fill floatIcon" />
              </div>
              {showTooltip && (
                <div className="customTooltip">
                  <span className="tooltipText">
                    Use this modal to monitor the progress of your barangay's
                    post-disaster crop rehabilitation process.
                  </span>
                </div>
              )}
            </div>
          ))
        ) : (
          <div className="floatPosition">
            <div
              style={{ backgroundColor: "#185D10" }}
              onMouseEnter={() => setShowTooltip2(true)}
              onMouseLeave={() => setShowTooltip2(false)}
            >
              <i class="bi bi-lightbulb-fill floatIcon" />
            </div>
            {showTooltip2 && (
              <div className="customTooltip">
                <span className="tooltipText">
                  The progress bar will show after you submit the completed reports in the pending reports module.
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default BAFCProcess;
