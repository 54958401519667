import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom"
import Navbar from "../navbar/SADNavbar";
import SideBarnav from "../sidebar/SADSidebar";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { useCreateAdminMutation } from "../utils/features/users/usersApiSlice"
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Select from 'react-select'

const TITLE = "GoFarmers | Add New Account";

const FNAME_REGEX = /^[A-Z][a-z]{1,}( [A-Z][a-z]{1,})?$/
const LNAME_REGEX = /^[A-Z][A-Za-z'-]+$/
const MOBILE_REGEX = /^09\d{9}$/
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%])[A-Za-z\d!@#$%]{8,}$/;

const SADAddAccount = () => {
  const [isClicked, setIsClicked] = useState(false);
  const [selectedRole, setSelectedRole] = useState("")
  const [selectedBarangay, setSelectedBarangay] = useState([])

  const [showModal, setshowModal] = useState(false);

  const modalClose = () => setshowModal(false);
  const modalShow = () => setshowModal(true);

  const navigate = useNavigate()

  const handleButtonClick = () => {
    setIsClicked(!isClicked);
  };

  const barangayOptions = [
    { value: "Bagong Pag-asa", label: "Bagong Pag-asa" },
    { value: "Bagong Silang", label: "Bagong Silang" },
    { value: "Balaong", label: "Balaong" },
    { value: "Balite", label: "Balite" },
    { value: "Bantog", label: "Bantog" },
    { value: "Bardias", label: "Bardias" },
    { value: "Baritan", label: "Baritan" },
    { value: "Batasan Bata", label: "Batasan Bata" },
    { value: "Batasan Matanda", label: "Batasan Matanda" },
    { value: "Biak-na-Bato", label: "Biak-na-Bato" },
    { value: "Biclat", label: "Biclat" },
    { value: "Buga", label: "Buga" },
    { value: "Buliran", label: "Buliran" },
    { value: "Bulualto", label: "Bulualto" },
    { value: "Calumpang", label: "Calumpang" },
    { value: "Cambio", label: "Cambio" },
    { value: "Camias", label: "Camias" },
    { value: "Ilog-Bulo", label: "Ilog-Bulo" },
    { value: "King Kabayo", label: "King Kabayo" },
    { value: "Labne", label: "Labne" },
    { value: "Lambakin", label: "Lambakin" },
    { value: "Magmarale", label: "Magmarale" },
    { value: "Malibay", label: "Malibay" },
    { value: "Maligaya", label: "Maligaya" },
    { value: "Mandile", label: "Mandile" },
    { value: "Masalipit", label: "Masalipit" },
    { value: "Pacalag", label: "Pacalag" },
    { value: "Paliwasan", label: "Paliwasan" },
    { value: "Partida", label: "Partida" },
    { value: "Pinambaran", label: "Pinambaran" },
    { value: "Poblacion", label: "Poblacion" },
    { value: "Pulong Bayabas", label: "Pulong Bayabas" },
    { value: "Pulong Duhat", label: "Pulong Duhat" },
    { value: "Sacdalan", label: "Sacdalan" },
    { value: "Salacot", label: "Salacot" },
    { value: "Salangan", label: "Salangan" },
    { value: "San Agustin", label: "San Agustin" },
    { value: "San Jose", label: "San Jose" },
    { value: "San Juan", label: "San Juan" },
    { value: "San Vicente", label: "San Vicente" },
    { value: "Santa Ines", label: "Santa Ines" },
    { value: "Santa Lucia", label: "Santa Lucia" },
    { value: "Santa Rita Bata", label: "Santa Rita Bata" },
    { value: "Santa Rita Matanda", label: "Santa Rita Matanda" },
    { value: "Sapang", label: "Sapang" },
    { value: "Sibul", label: "Sibul" },
    { value: "Tartaro", label: "Tartaro" },
    { value: "Tibagan", label: "Tibagan" },
    { value: "Tigpalas", label: "Tigpalas" },
  ];

  const { handleSubmit, register, formState: { errors }, setError, setValue, watch } = useForm()
  const [createUser] = useCreateAdminMutation()

  const validateBarangay = (value) => {
    if (value && value.length >= 1) {
      return true
    }
    if (selectedRole === "agritech") {
      return "Please select at least one option for Barangay."
    }
    return true
  }

  const onSubmit = async (data) => {
    try {
      const { password, repeatpassword } = data;

      if (repeatpassword !== password) {
        setError("repeatpassword", {
          type: "manual",
          message: "Passwords do not match",
        })
        return
      }

      const errorFields = Object.keys(errors);
      if (errorFields.length === 0) {
        modalShow()
      }
    } catch (err) {
      toast.error("Failed to save");
      // console.log(err)
    }
  }

  const addAdmin = async () => {
    try {
      const data = watch()

      // const barangayValue = data.barangay ? data.barangay.map((x) => x.value) : [];

      const barangayValue = Array.isArray(data.barangay)
        ? data.barangay.map((x) => x.value)
        : data.barangay
          ? [data.barangay.value]
          : []

      const createdUser = await createUser({
        data: { ...data, barangay: barangayValue }
      }).unwrap()
      if (createdUser !== null && createdUser !== undefined) {
        toast.success("Account created successfully")
        navigate("/superadmin-account")
      } else {
        toast.error("Failed to create account")
      }
    } catch (err) {
      if (err.status === 400) {
        toast.error(
          "User with this email already exists. Please use a different email address."
        )
      } else {
        toast.error("Failed to save")
        // console.log(err)
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Navbar handleButtonClick={handleButtonClick} />
      <div className="container-fluid" id="tryFix">
        <div className="row flex-nowrap overflow-hidden">
          <SideBarnav />
          <div
            className="col overflow-x-hidden"
            id={`${isClicked ? "tryMove" : ""}`}
          >
            <style>
              {`
                #tryMove {
                  margin-left: ${isClicked ? "380px" : "0px"};
                }
              `}
            </style>
            {/* SECTION TITLE */}
            <h1 className="section">Add New Account</h1>

            <div className="col-md-3">
              <Link to="/superadmin-account">
                <button className="btn btn-success" id="buttons">
                  <i className="bi bi-arrow-left"></i> BACK
                </button>
              </Link>
            </div>

            <div className="container-fluid" id="customContainer">
              <div className="row">
                <div id="textSection">
                  <form
                    className="ps-4 pe-4 my-4"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="row justify-content-center align-items-center">
                      <label
                        htmlFor="firstName"
                        className="formLabel col-sm-5 my-1 col-form-label text-end"
                      >
                        FIRST NAME :
                      </label>
                      <div className="col-sm-6">
                        <input
                          type="text"
                          className="textField form-control"
                          id="firstName"
                          {...register("firstName", {
                            required: "This field is required",
                            pattern: {
                              value: FNAME_REGEX,
                              message: "Invalid first name",
                            },
                          })}
                        />
                        {errors.firstName && (
                          <p className="errmsg">{errors.firstName.message}</p>
                        )}
                      </div>
                    </div>
                    <div className="row justify-content-center align-items-center">
                      <label
                        htmlFor="lastName"
                        className="formLabel col-sm-5 my-1 col-form-label text-end"
                      >
                        LAST NAME :
                      </label>
                      <div className="col-sm-6">
                        <input
                          type="text"
                          className="textField form-control"
                          id="lastName"
                          {...register("lastName", {
                            required: "This field is required",
                            pattern: {
                              value: LNAME_REGEX,
                              message: "Invalid last name",
                            },
                          })}
                        />
                        {errors.lastName && (
                          <p className="errmsg">{errors.lastName.message}</p>
                        )}
                      </div>
                    </div>
                    <div className="row justify-content-center align-items-center">
                      <label
                        htmlFor="email"
                        className="formLabel col-sm-5 my-1 col-form-label text-end"
                      >
                        EMAIL :
                      </label>
                      <div className="col-sm-6">
                        <input
                          type="email"
                          className="textField form-control"
                          id="email"
                          {...register("email", {
                            required: "This field is required",
                          })}
                        />
                        {errors.email && (
                          <p className="errmsg">{errors.email.message}</p>
                        )}
                      </div>
                    </div>
                    <div className="row justify-content-center align-items-center">
                      <label
                        htmlFor="mobileNumber"
                        className="formLabel col-sm-5 my-1 col-form-label text-end"
                      >
                        MOBILE NUMBER :
                      </label>
                      <div className="col-sm-6">
                        <input
                          type="number"
                          className="textField form-control"
                          id="mobileNumber"
                          {...register("mobile", {
                            required: "This field is required",
                            pattern: {
                              value: MOBILE_REGEX,
                              message:
                                "Invalid mobile number. Mobile number should start with 09 and be 11 digits long.",
                            },
                          })}
                        />
                        {errors.mobile && (
                          <p className="errmsg">{errors.mobile.message}</p>
                        )}
                      </div>
                    </div>

                    <div className="row justify-content-center align-items-center">
                      <label
                        htmlFor="role"
                        className="formLabel col-sm-5 my-1 col-form-label text-end"
                      >
                        ROLE:
                      </label>
                      <div className="col-sm-6">
                        <select
                          className="textField form-control"
                          id="role"
                          {...register("role", {
                            required: "This field is required",
                          })}
                          onChange={(e) => setSelectedRole(e.target.value)}
                        >
                          <option value="">Select Role</option>
                          <option value="BAFCadmin">Barangay Agricultural and Fishery Council</option>
                          <option value="agritech">Agricultural Technician</option>
                          <option value="MRO-rice">Municipal Report Officer - Rice</option>
                          <option value="MRO-veg">Municipal Report Officer - Vegetable</option>
                          <option value="PRO-rice">Provincial Report Officer - Rice</option>
                          <option value="PRO-veg">Principal Report Officer - Vegetable</option>
                        </select>
                        {errors.role && (
                          <p className="errmsg">{errors.role.message}</p>
                        )}
                      </div>
                    </div>
                    {/* BARANGAY SINGLE SELECT */}
                    <div className="row justify-content-center align-items-center">
                      <label htmlFor="barangay" className={`formLabel col-sm-5 my-1 col-form-label text-end ${selectedRole !== "BAFCadmin" ? "d-none" : ""}`} >
                        BARANGAY:
                      </label>
                      <div className={`col-sm-6 ${selectedRole !== "BAFCadmin" ? "d-none" : ""}`}>
                        <Select
                          options={barangayOptions}
                          className="textField mb-2"
                          {...register("barangay", {
                            validate: validateBarangay
                          })}
                          onChange={(selectedOptions) => {
                            setValue("barangay", selectedOptions)
                            setSelectedBarangay(selectedOptions)
                          }}
                        />
                        {errors.barangay && (
                          <p className="errmsg">{errors.barangay.message}</p>
                        )}
                      </div>
                    </div>

                    {/* BARANGAY MULTI SELECT */}
                    <div className="row justify-content-center align-items-center">
                      <label
                        htmlFor="barangay"
                        className={`formLabel col-sm-5 my-1 col-form-label text-end ${selectedRole !== "agritech" ? "d-none" : ""
                          }`}
                      >
                        BARANGAY :
                      </label>
                      <div
                        className={`col-sm-6 ${selectedRole !== "agritech" ? "d-none" : ""
                          }`}
                      >
                        <Select
                          options={barangayOptions}
                          isMulti
                          isOptionDisabled={() => selectedBarangay.length >= 5}
                          className="textField mb-2"
                          {...register("barangay", {
                            validate: validateBarangay
                          })}
                          onChange={(selectedOptions) => {
                            setValue("barangay", selectedOptions)
                            setSelectedBarangay(selectedOptions)
                          }}
                        />
                        {errors.barangay && (
                          <p className="errmsg">{errors.barangay.message}</p>
                        )}
                      </div>
                    </div>
                    <div className="row justify-content-center align-items-center">
                      <label
                        htmlFor="password"
                        className="formLabel col-sm-5 my-1 col-form-label text-end"
                      >
                        PASSWORD:
                      </label>
                      <div className="col-sm-6">
                        <input
                          type="password"
                          className="textField form-control"
                          id="password"
                          {...register("password", {
                            required: "This field is required",
                            pattern: {
                              value: PWD_REGEX,
                              message: "Password must be at least 8 characters and must include letters, numbers, and special characters like !@#$%",
                            },
                          })}
                        />
                        {errors.password && (
                          <p className="errmsg">{errors.password.message}</p>
                        )}
                      </div>
                    </div>
                    <div className="row justify-content-center align-items-center">
                      <label
                        htmlFor="rePassword"
                        className="formLabel col-sm-5 my-1 col-form-label text-end"
                      >
                        RE-ENTER PASSWORD:
                      </label>
                      <div className="col-sm-6">
                        <input
                          type="password"
                          className="textField form-control"
                          id="rePassword"
                          {...register("repeatpassword", {
                            required: "This field is required",
                            validate: (value) =>
                              value === watch("password") ||
                              "Passwords do not match",
                          })}
                        />
                        {errors.repeatpassword && (
                          <p className="errmsg">
                            {errors.repeatpassword.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="d-flex justify-content-end">
                      <div>
                        <Link to="/superadmin-account">
                          <button
                            type="button"
                            className="btn btn-primary me-3"
                            id="cancelButton"
                          >
                            CANCEL
                          </button>
                        </Link>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        id="buttons"
                      >
                        ADD
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={modalClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body className="py-4">
          <div className="row mb-4 text-center" style={{ color: "#185D10" }}>
            <h1>
              <i className="bi bi-question-circle"></i>
            </h1>
            <h5 className="fw-bold">
              ARE YOU SURE <br /> YOU WANT TO ADD THIS ACCOUNT?
            </h5>
          </div>
          <div className="row text-center">
            <div className="col text-end">
              <Button
                className="m-auto"
                onClick={modalClose}
                id="modalCancelbtn"
              >
                Cancel
              </Button>
            </div>
            <div className="col text-start">
              <Button
                className="m-auto"
                id="modalConfirmbtn"
                onClick={addAdmin}
              >
                Add
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SADAddAccount;
