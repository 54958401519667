import React, { useEffect, useState } from "react";
// OTHER IMPORTS
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { useForm } from "react-hook-form"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { format } from "date-fns"
import { useFetchSummaryReportQuery, useUploadProSummaryReportImageMutation, useApproveProSummaryReportMutation, useSubmitProSummaryReportMutation } from "../utils/features/summaryReports/summaryReportsApiSlice"
import { PDFDownloadLink } from "@react-pdf/renderer";
import SummaryReportPDF from "../components/exports/SummaryReportPDF";

// COMPONENTS
import Navbar from "../navbar/PRONavbar";
import SideBarnav from '../sidebar/PROSidebar';
import logo from '../images/logo.webp';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const TITLE = 'GoFarmers | Generated Damaged Form'

const validationSchema = z.object({
  cropType: z.string({ errorMap: () => ({ message: "Crop Type is invalid." }) })
    .trim()
    .nonempty("Crop Type is required.")
    .max(255, "Crop Type cannot be more than 255 character(s)."),
  seedType: z.string({ errorMap: () => ({ message: "Seed Type is invalid." }) })
    .trim()
    .nonempty("Seed Type is required.")
    .max(255, "Seed Type cannot be more than 255 character(s)."),
  totallyDamaged: z.coerce.number({ errorMap: () => ({ message: "Totally Damaged Area is invalid." }) })
    .positive("Totally Damaged Area is invalid."),
  partiallyDamaged: z.coerce.number({ errorMap: () => ({ message: "Partially Damaged Area is invalid." }) })
    .positive("Partially Damaged Area is invalid."),
  totalDamaged: z.coerce.number({ errorMap: () => ({ message: "Total Damaged Area is invalid." }) }),
  yieldBefore: z.coerce.number({ errorMap: () => ({ message: "Yield Before is invalid." }) })
    .positive("Yield Before is invalid."),
  yieldAfter: z.coerce.number({ errorMap: () => ({ message: "Yield After is invalid." }) })
    .positive("Yield After is invalid."),
  yieldLoss: z.coerce.number({ errorMap: () => ({ message: "Yield Loss is invalid." }) }),
  image: z.union([
    z.any()
      .nullable(),
    z.any()
      .refine((files) => files?.[0]?.size <= 5000000, "Max image size is 5MB.")
      .refine((files) => ["image/png", "image/jpg", "image/jpeg"].includes(files?.[0]?.type), "Only .png, .jpg, or .jpeg formats are accepted.")
  ])
})

const PRODamagedForm = () => {
  const params = useParams()
  const navigate = useNavigate()

  const [isClicked, setIsClicked] = useState(false);
  const [showModal, setshowModal] = useState(false);
  const [showModal2, setshowModal2] = useState(false);

  const modalClose = () => setshowModal(false);
  const modalShow = () => setshowModal(true);

  const modal2Close = () => setshowModal2(false);
  const modal2Show = () => setshowModal2(true);

  const handleButtonClick = () => {
    setIsClicked(!isClicked);
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const [previewURL, setPreviewURL] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);

    const reader = new FileReader();
    reader.onload = () => {
      // console.log("changed")
      setPreviewURL(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const { data: summaryReport, isLoading: summaryReportIsLoading } = useFetchSummaryReportQuery({
    id: params.id
  })

  const { handleSubmit, register, reset, setValue, watch, formState: { errors } } = useForm({
    resolver: zodResolver(validationSchema)
  })

  useEffect(() => {
    if (!summaryReportIsLoading && summaryReport?.data) {
      reset({
        disasterDate: format(new Date(summaryReport.data.initialReport.disasterDate), "yyyy-MM-dd"),
        disasterType: summaryReport.data.initialReport.disasterType,
        disasterName: summaryReport.data.initialReport.disasterName,
        barangay: summaryReport.data.barangay,
        cropType: summaryReport.data.cropType,
        seedType: summaryReport.data.seedType,
        cropStage: summaryReport.data.cropStage,
        cropArea: summaryReport.data.cropArea,
        farmersCount: summaryReport.data.farmersCount,
        totallyDamaged: summaryReport.data.totallyDamaged,
        partiallyDamaged: summaryReport.data.partiallyDamaged,
        totalDamaged: summaryReport.data.totalDamaged,
        yieldBefore: summaryReport.data.yieldBefore,
        yieldAfter: summaryReport.data.yieldAfter,
        yieldLoss: summaryReport.data.yieldLoss,
        inputCost: summaryReport.data.inputCost,
        totalCost: summaryReport.data.totalCost
      })
    }
  }, [summaryReportIsLoading, summaryReport, reset])

  // Auto-compute Total Damaged Area
  const [_totallyDamaged, _partiallyDamaged] = watch(["totallyDamaged", "partiallyDamaged"])

  useEffect(() => {
    if (_totallyDamaged && _partiallyDamaged && +_totallyDamaged && +_partiallyDamaged) {
      let totalDamaged = (+_totallyDamaged + +_partiallyDamaged)

      setValue("totalDamaged", (Number)(totalDamaged.toFixed(2)))
    }
  }, [_totallyDamaged, _partiallyDamaged, setValue])

  // Auto-compute Yield Loss
  const [_yieldBefore, _yieldAfter] = watch(["yieldBefore", "yieldAfter"])

  useEffect(() => {
    if (_yieldBefore && _yieldAfter && +_yieldBefore && +_yieldAfter) {
      let yieldLoss = (+_yieldAfter / +_yieldBefore)

      yieldLoss *= 100
      yieldLoss -= 100
      yieldLoss *= -1

      setValue("yieldLoss", (Number)(yieldLoss.toFixed(2)))
    }
  }, [_yieldBefore, _yieldAfter, setValue])

  const [uploadSummaryReportImage] = useUploadProSummaryReportImageMutation()
  const [approveSummaryReport] = useApproveProSummaryReportMutation()

  async function handleApproveReport(data) {
    modalClose()

    let imagePath = undefined

    if (data?.image.length) {
      const formData = new FormData()

      formData.append("image", data?.image[0])

      const uploadedSummaryReportImage = await uploadSummaryReportImage(formData).unwrap()

      imagePath = uploadedSummaryReportImage?.path.replace("public", "")
    }

    const approvedSummaryReport = await approveSummaryReport({
      id: params.id,
      imagePath,
      ...data
    })

    if (approvedSummaryReport.error) {
      // console.error("Failed to approve summary report.")
      return
    }
    // console.log("Successfully approved summary report.")
    navigate("/pro-generated-reports")
  }

  const [submitSummaryReport] = useSubmitProSummaryReportMutation()

  async function handleSubmitReport() {
    modal2Close()

    const approvedSummaryReport = await submitSummaryReport({
      id: params.id
    })

    if (approvedSummaryReport.error) {
      // console.error("Failed to submit summary report.")
      return
    }
    // console.log("Successfully submitd summary report.")
    navigate("/pro-generated-reports")
  }

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Navbar handleButtonClick={handleButtonClick} />
      <div className="container-fluid" id="tryFix">
        <div className="row flex-nowrap  overflow-hidden">
          <SideBarnav />
          <div className="col overflow-x-hidden" id={`${isClicked ? 'tryMove' : ''}`}>
            <style>
              {`
                #tryMove {
                  margin-left: ${isClicked ? '380px' : '0px'};
                  
                }
              `}
            </style>
            <h1 className="section">{summaryReport?.data.barangay} Damaged Generated Report</h1>
            <div className="d-flex justify-content-between">
              <Link to="/pro-generated-reports"><button className="btn btn-success my-2" id="buttons"><i class="bi bi-arrow-left"></i> BACK</button></Link>

              <PDFDownloadLink document={<SummaryReportPDF data={summaryReport?.data} />} fileName={`${summaryReport?.data.barangay}_summary-report`} >
                <button className="btn btn-success my-2" id="buttons"><i class="bi bi-download"></i> DOWNLOAD</button>
              </PDFDownloadLink>
            </div>
            <div className="container" id="damageContainer">
              <div className="row justify-content-center align-items-center">
                <div id="textSection">
                  <div className="logoForms">
                    <img src={logo} alt="GoFarmers" />
                  </div>
                  <h2 className="centerTitle">{summaryReport?.data.barangay} Damaged Generated Report</h2>
                  <form id="form-id" onSubmit={handleSubmit(handleApproveReport)} encType="multipart/form-data">
                    <div className="form">
                      <div className="formSection"></div>
                      <h5 className="ms-4 fw-bold">General Information</h5>
                      <div className="form">
                        <div className="row justify-content-center align-items-center" >
                          <label htmlFor="date" className="fw-medium text-end col-sm-5 my-1 col-form-label"> DATE OF OCCURENCE :</label>
                          <div className="col-sm-5">
                            <input type="date" className="textField form-control" id="date" {...register("disasterDate")} disabled readonly />
                          </div>
                        </div>
                        <div className="row justify-content-center align-items-center">
                          <label for="causedamage" className="fw-medium  text-end col-sm-5 my-1 col-form-label"> TYPE OF DISASTER :</label>
                          <div className="col-sm-5">
                            <select className="textField form-select" id="causedamage" {...register("disasterType")} disabled readonly>
                              <option style={{ display: 'none' }}></option>
                              <option defaultValue="Drought">Drought</option>
                              <option defaultValue="Typhoon">Typhoon</option>
                              <option defaultValue="Flood">Flood</option>
                            </select>
                          </div>
                        </div>
                        <div className="row justify-content-center align-items-center" >
                          <label htmlFor="disasterName" className="fw-medium text-end col-sm-5 my-1 col-form-label">NAME OF DISASTER (OPTIONAL) :</label>
                          <div className="col-sm-5">
                            <input type="text" inputmode="numeric" className="textField form-control" id="disasterName" {...register("disasterName")} disabled readonly />
                          </div>
                        </div>
                        <div className="form">
                          <div className="formSection"></div>
                          <h5 className="ms-4 fw-bold">Geographic Information</h5>
                          <div className="row justify-content-center align-items-center">
                            <label for="region" className="fw-medium  text-end col-sm-5 my-1 col-form-label">REGION :</label>
                            <div className="col-sm-5">
                              <input type="text" inputmode="numeric" className="textField form-control" id="region" defaultValue="3" disabled readonly />
                            </div>
                          </div>
                          <div className="row justify-content-center align-items-center">
                            <label for="province" className="fw-medium  text-end col-sm-5 my-1 col-form-label">PROVINCE :</label>
                            <div className="col-sm-5">
                              <input type="text" className="textField form-control" id="province" defaultValue="Bulacan" disabled readonly />
                            </div>
                          </div>
                          <div className="row justify-content-center align-items-center">
                            <label for="municipality" className="fw-medium  text-end col-sm-5 my-1 col-form-label">MUNICIPALITY :</label>
                            <div className="col-sm-5">
                              <input type="text" className="textField form-control" id="municipality" defaultValue="San Miguel" disabled readonly />
                            </div>
                          </div>
                          <div className="row justify-content-center align-items-center">
                            <label for="barangay" className="fw-medium  text-end col-sm-5 my-1 col-form-label">BARANGAY :</label>
                            <div className="col-sm-5">
                              <input type="text" className="textField form-control" id="barangay" {...register("barangay")} disabled readonly />
                            </div>
                          </div>
                          <div className="formSection"></div>
                          <h5 className="ms-4 fw-bold">Particulars</h5>
                          <div className="row justify-content-center align-items-center">
                            <label for="type" className="fw-medium  text-end col-sm-5 my-1 col-form-label"> ECOSYSTEM :</label>
                            <div className="col-sm-5">
                              <select className="textField form-select" id="type" {...register("cropType")} disabled={summaryReport?.data.status === "pro-approved"}>
                                <option defaultValue="">Select an Option</option>
                                <option defaultValue="Irrigated">Irrigated</option>
                                <option defaultValue="Raised Field - lowland">Raised Field - lowland</option>
                                <option defaultValue="Raised Field - upland"> Raised Field - upland</option>
                              </select>
                              {errors?.cropType && <small className="text-danger">{errors.cropType.message}</small>}
                            </div>
                          </div>
                          <div className="row justify-content-center align-items-center">
                            <label for="type" className="fw-medium  text-end col-sm-5 my-1 col-form-label"> CROP STAGE:</label>
                            <div className="col-sm-5">
                              <select className="textField form-select" id="type" {...register("cropStage")} disabled readonly>
                                <option style={{ display: 'none' }}></option>
                                <option defaultValue="Seedling">Seedling</option>
                                <option defaultValue="Planting">Planting</option>
                                <option defaultValue="Vegetative">Vegetative</option>
                                <option defaultValue="Reproductive">Reproductive</option>
                                <option defaultValue="Maturity">Maturity</option>
                              </select>
                            </div>
                          </div>
                          <div className="row justify-content-center align-items-center">
                            <label for="typeOfCrop" className="fw-medium  text-end col-sm-5 my-1 col-form-label"> AREA OF STANDING CROP :</label>
                            <div className="col-sm-5">
                              <input type="text" inputmode="numeric" className="textField form-control" id="typeOfCrop" {...register("cropArea")} disabled readOnly />
                            </div>
                          </div>
                          <div className="row justify-content-center align-items-center">
                            <label for="typeOfCrop" className="fw-medium  text-end col-sm-5 my-1 col-form-label"> NUMBER OF FARMERS AFFECTED :</label>
                            <div className="col-sm-5">
                              <input type="text" inputmode="numeric" className="textField form-control" id="typeOfCrop" {...register("farmersCount")} disabled readonly />
                            </div>
                          </div>
                          <div className="row justify-content-center align-items-center">
                            <label for="type" className="fw-medium  text-end col-sm-5 my-1 col-form-label"> SEED TYPE :</label>
                            <div className="col-sm-5">
                              <select className="textField form-select" id="type" {...register("seedType")} disabled={summaryReport?.data.status === "pro-approved"}>
                                <option style={{ display: 'none' }}></option>
                                <option defaultValue="Inbred">Inbred</option>
                                <option defaultValue="Hybrid">Hybrid</option>
                              </select>
                              {errors?.seedType && <small className="text-danger">{errors.seedType.message}</small>}
                            </div>
                          </div>
                          <h5 className="ms-4 fw-semibold pt-4">Area Affected (HA.) SEEDLINGS (BAGS)</h5>
                          <div className="row justify-content-center align-items-center">
                            <label for="totallyDamaged" className="fw-medium  text-end col-sm-5 my-1 col-form-label"> TOTALLY DAMAGED :</label>
                            <div className="col-sm-5">
                              <div className="form-outline">
                                <input type="number" id="typeNumber" className="textField form-control" min="0" {...register("totallyDamaged")} disabled={summaryReport?.data.status === "pro-approved"} />
                                {errors?.totallyDamaged && <small className="text-danger">{errors.totallyDamaged.message}</small>}
                              </div>
                            </div>
                          </div>
                          <div className="row justify-content-center align-items-center">
                            <label for="partiallyDamaged" className="fw-medium  text-end col-sm-5 my-1 col-form-label"> PARTIALLY DAMAGED :</label>
                            <div className="col-sm-5">
                              <input type="text" className="textField form-control" id="partiallyDamaged" {...register("partiallyDamaged")} disabled={summaryReport?.data.status === "pro-approved"} />
                              {errors?.partiallyDamaged && <small className="text-danger">{errors.partiallyDamaged.message}</small>}
                            </div>
                          </div>
                          <div className="row justify-content-center align-items-center">
                            <label for="total" className="fw-medium  text-end col-sm-5 my-1 col-form-label">TOTAL :</label>
                            <div className="col-sm-5">
                              <input type="text" inputmode="numeric" className="textField form-control" id="total" {...register("totalDamaged")} disabled readonly />
                              {errors?.totalDamaged && <small className="text-danger">{errors.totalDamaged.message}</small>}
                            </div>
                          </div>
                          <h5 className="ms-4 fw-semibold pt-4">Yield per Hectare (M.T)</h5>
                          <div className="row justify-content-center align-items-center">
                            <label for="beforeCalamity" className="fw-medium  text-end col-sm-5 my-1 col-form-label"> BEFORE CALAMITY :</label>
                            <div className="col-sm-5">
                              <input type="text" inputmode="numeric" className="textField form-control" id="beforeCalamity" {...register("yieldBefore")} disabled={summaryReport?.data.status === "pro-approved"} />
                              {errors?.yieldBefore && <small className="text-danger">{errors.yieldBefore.message}</small>}
                            </div>
                          </div>
                          <div className="row justify-content-center align-items-center">
                            <label for="afterCalamity" className="fw-medium  text-end col-sm-5 my-1 col-form-label"> AFTER CALAMITY :</label>
                            <div className="col-sm-5">
                              <input type="text" inputmode="numeric" className="textField form-control" id="afterCalamity" {...register("yieldAfter")} disabled={summaryReport?.data.status === "pro-approved"} />
                              {errors?.yieldAfter && <small className="text-danger">{errors.yieldAfter.message}</small>}
                            </div>
                          </div>
                          <div className="row justify-content-center align-items-center">
                            <label for="yieldLoss" className="fw-medium  text-end col-sm-5 my-1 col-form-label">YIELD LOSS(%) :</label>
                            <div className="col-sm-5">
                              <input type="text" inputmode="numeric" className="textField form-control" id="yieldLoss" {...register("yieldLoss")} disabled readonly />
                              {errors?.yieldLoss && <small className="text-danger">{errors.yieldLoss.message}</small>}
                            </div>
                          </div>
                          <h5 className="ms-4 fw-semibold pt-4">Total Losses(Based on Cost of Input)</h5>
                          <div className="row justify-content-center align-items-center">
                            <label for="costOfInput" className="fw-medium  text-end col-sm-5 my-1 col-form-label"> COST OF INPUT/HA. :</label>
                            <div className="col-sm-5">
                              <input type="text" inputmode="numeric" className="textField form-control" id="costOfInput" {...register("inputCost")} disabled readonly />
                            </div>
                          </div>
                          <div className="row justify-content-center align-items-center">
                            <label for="totalValue" className="fw-medium  text-end col-sm-5 my-1 col-form-label">TOTAL :</label>
                            <div className="col-sm-5">
                              <input type="text" inputmode="numeric" className="textField form-control" id="totalValue" {...register("totalCost")} disabled readonly />
                            </div>
                          </div>

                          {/* FIELD FOR DAMAGE DOCUMENT IMAGE PREVIEW (OPTIONAL) */}
                          <h5 className="ms-4 fw-semibold pt-4">Disaster Damage Photo Documentation (Optional)</h5>
                          {
                            summaryReport?.data.status === "pro-waiting" ? (
                              <div className="attachPhoto">
                                <label for="photoInput" className="fw-medium  text-end col-sm-3 my-1 col-form-label">Attach a Photo: </label>
                                <input
                                  type="file"
                                  id="photoInput"
                                  {...register("image")}
                                  accept="image/png, image/jpg, image/jpeg"
                                  onChange={handleFileUpload}
                                />
                                {
                                  previewURL ? (
                                    <div>
                                      <p>Selected File: {selectedFile.name}</p>
                                      <img
                                        src={previewURL}
                                        alt="Selected"
                                        width="200"
                                        onClick={openModal}
                                        style={{ cursor: 'pointer' }}
                                      />
                                    </div>
                                  ) : (
                                    <div>
                                      <p>Current File:</p>
                                      <img src={`${process.env.REACT_APP_SERVER_URL}/${summaryReport?.data.imagePath}`} width="200" onClick={openModal} style={{ cursor: 'pointer' }} alt="Summary Report" />
                                    </div>
                                  )
                                }
                              </div>
                            ) : (
                              <div className="attachPhoto">
                                <img src={`${process.env.REACT_APP_SERVER_URL}/${summaryReport?.data.imagePath}`} width="200" onClick={openModal} style={{ cursor: 'pointer' }} alt="Summary Report" />
                              </div>
                            )
                          }

                          {/* MODAL FOR DAMAGE DOCUMENT IMAGE PREVIEW  */}
                          <Modal
                            show={modalIsOpen}
                            onHide={closeModal}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                          >
                            <Modal.Header closeButton>
                              <Modal.Title className="modalTitle">Disaster Damage Image</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              {
                                summaryReport?.data.status === "pro-waiting" ? (
                                  previewURL ? (
                                    <img src={previewURL} alt="Enlarged" className="img-fluid" />
                                  ) : <img src={`${process.env.REACT_APP_SERVER_URL}/${summaryReport?.data.imagePath}`} alt="Enlarged" className="img-fluid" />
                                ) : (
                                  <img src={`${process.env.REACT_APP_SERVER_URL}/${summaryReport?.data.imagePath}`} alt="Enlarged" className="img-fluid" />
                                )
                              }
                            </Modal.Body>
                            <Modal.Footer>
                              <button className="btn btn-secondary" onClick={closeModal} id="buttons">
                                Close
                              </button>
                            </Modal.Footer>
                          </Modal>

                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                {/* BUTTONS */}
                {
                  !summaryReportIsLoading && summaryReport?.data.status === "pro-waiting" && (
                    <div className="row justify-content-center align-items-center px-2 pt-3">
                      <div className="d-flex justify-content-end px-0 ">
                        <button type="button" className="btn btn-primary" id="buttonApprove" onClick={modalShow}>APPROVE</button>
                      </div>
                    </div>
                  )
                }

                {
                  !summaryReportIsLoading && summaryReport?.data.status === "pro-approved" && (
                    <div className="row justify-content-center align-items-center px-2 pt-3">
                      <div className="d-flex justify-content-end px-0 ">
                        <button type="button" className="btn btn-primary" id="buttonApprove" onClick={modal2Show}>SUBMIT REPORT</button>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        !summaryReportIsLoading && summaryReport?.data.status === "pro-approved" && (
          <Modal
            show={showModal2}
            onHide={modal2Close}
            backdrop="static"
            keyboard={false}
            centered
          >
            <Modal.Body className="py-4">
              <div className="row mb-4 text-center" style={{ color: '#185D10' }}>
                <h1><i className="bi bi-question-circle"></i></h1>
                <h5 className="fw-bold">ARE YOU SURE <br /> YOU WANT TO SUBMIT THIS REPORT?</h5>
              </div>
              <div className="row text-center">
                <div className="col text-end">
                  <Button className="m-auto" onClick={modal2Close} id="modalCancelbtn">
                    CANCEL
                  </Button>
                </div>
                <div className="col text-start">
                  <Button className="m-auto" id="modalApprovebtn" type="button" onClick={handleSubmitReport}>CONFIRM</Button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        )
      }

      {
        !summaryReportIsLoading && summaryReport?.data.status === "pro-waiting" && (
          <Modal
            show={showModal}
            onHide={modalClose}
            backdrop="static"
            keyboard={false}
            centered
          >
            <Modal.Body className="py-4">
              <div className="row mb-4 text-center" style={{ color: '#185D10' }}>
                <h1><i className="bi bi-question-circle"></i></h1>
                <h5 className="fw-bold">ARE YOU SURE <br /> YOU WANT TO SAVE AND APPROVE THIS REPORT?</h5>
              </div>
              <div className="row text-center">
                <div className="col text-end">
                  <Button className="m-auto" onClick={modalClose} id="modalCancelbtn">
                    CANCEL
                  </Button>
                </div>
                <div className="col text-start">
                  <Button className="m-auto" type="submit" form="form-id" id="modalApprovebtn">CONFIRM</Button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        )
      }
    </>
  )
}

export default PRODamagedForm