import { useLocation, Navigate, Outlet } from "react-router-dom"
import useAuth from "../../hooks/useAuth"
import { useSelector } from "react-redux"
import { selectCurrentToken } from "./authSlice"
import { useEffect } from "react"
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const RequireAuth = ({ allowedRole }) => {
  const token = useSelector(selectCurrentToken)
  const location = useLocation()
  const { role } = useAuth()

  useEffect(() => {
    if (!token) {
      toast.error('Session expired. Please log in again.', {
        position: 'top-center',
        autoClose: 5000, 
        hideProgressBar: true,
      })
    }
  }, [token])

  if (!role || !allowedRole.includes(role) || !token) {
    return <Navigate to="/" state={{ from: location }} replace />
  }

  return (
    <div>
      <ToastContainer />
      <Outlet />
    </div>
  )
}

export default RequireAuth