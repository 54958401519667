import React, { useEffect, useState } from "react";
// OTHER IMPORTS
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { useForm } from "react-hook-form"
import formatDate from "date-fns/format"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod";
import { useFetchBAFCPendingFormQuery, useUpdateBAFCPendingFormMutation } from "../utils/features/_forms/_formsApiSlice"
import { PDFDownloadLink } from "@react-pdf/renderer";
import FarmerFormPDF from "../components/exports/FarmerFormPDF";

// COMPONENTS 
import Navbar from "../navbar/BAFCNavbar";
import SideBarnav from '../sidebar/BAFCSidebar';
import logo from '../images/logo.webp';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const TITLE = 'GoFarmers | Farmer Damaged Form'

const validationSchema = z.object({
  seedType: z.enum(["Inbred", "Hybrid"]),
  cropType: z.enum(["Irrigated", "Raised Field - lowland", "Raised Field - upland"]),
  cropStage: z.enum(["Seedling", "Planting", "Vegetative", "Reproductive", "Maturity"]),
  cropArea: z.coerce.number({ errorMap: () => ({ message: "Area of Standing Crop is invalid." }) })
    .positive("Area of Standing Crop is invalid.")
})

const BAFCDamagedForm = () => {
  const [isClicked, setIsClicked] = useState(false);

  const [showModal, setshowModal] = useState(false);
  const [showModal1, setshowModal1] = useState(false);

  const modalClose = () => setshowModal(false);
  const modalClose1 = () => setshowModal1(false);
  const modalShow = () => setshowModal(true);
  const modalShow1 = () => setshowModal1(true);

  const handleButtonClick = () => {
    setIsClicked(!isClicked);
  };

  const params = useParams()
  const navigate = useNavigate()

  const { data: form, isLoading: formIsLoading } = useFetchBAFCPendingFormQuery({
    cropStage: params.cropStage,
    id: params.id
  })

  const { handleSubmit, register, reset, formState: { errors } } = useForm({
    resolver: zodResolver(validationSchema),
    reValidateMode: "onChange"
  })

  useEffect(() => {
    if (!formIsLoading && form?.data) {
      reset({
        ffrsId: form.data.user.ffrs,
        farmerName: `${form.data.user.firstName} ${form.data.user.lastName}`,
        disasterDate: formatDate(new Date(form.data.disasterDate), "yyyy-MM-dd"),
        disasterType: form.data.disasterType,
        barangay: form.data.barangay,
        cropSeed: form.data.cropSeed,
        seedType: form.data.seedType,
        cropType: form.data.cropType,
        cropStage: form.data.cropStage,
        cropArea: form.data.cropArea
      })
    }
  }, [formIsLoading, form?.data, reset])

  const [updateFormMutation] = useUpdateBAFCPendingFormMutation()

  async function handleApproveForm(data) {
    const updatedForm = await updateFormMutation({
      id: params.id,
      status: "bafc-approved",
      ...data
    })

    if (updatedForm.error) {
      // console.error(updatedForm.error)
      return
    }
    navigate("/bafc-generated-reports")
    // console.log("approved", updatedForm.data)
    modalClose()
  }

  async function handleDeclineForm(data) {
    const updatedForm = await updateFormMutation({
      id: params.id,
      status: "bafc-declined",
      ...data
    })

    if (updatedForm.error) {
      // console.error(updatedForm.error)
      return
    }
    navigate("/bafc-generated-reports")
    // console.log("declined", updatedForm)
    modalClose1()
  }

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Navbar handleButtonClick={handleButtonClick} />
      <div className="container-fluid" id="tryFix">
        <div className="row flex-nowrap  overflow-hidden">
          <SideBarnav />
          <div className="col overflow-x-hidden" id={`${isClicked ? 'tryMove' : ''}`}>
            <style>
              {`
                #tryMove {
                  margin-left: ${isClicked ? '380px' : '0px'};
                }
              `}
            </style>
            <h1 className="section">Edit Farmers Damaged Generated Form</h1>
            <div className="d-flex justify-content-between">
              <Link to={`/bafc-farmer-reports/${params.cropStage}`}><button className="btn btn-success my-2" id="buttons"><i class="bi bi-arrow-left"></i> BACK</button></Link>

              <PDFDownloadLink document={<FarmerFormPDF data={form?.data} />} fileName={`${form?.data.user.ffrs}_damage-assessment-report`} >
                <button className="btn btn-success my-2" id="buttons"><i class="bi bi-download"></i> DOWNLOAD</button>
              </PDFDownloadLink>
            </div>

            <div className="container" id="damageContainer">
              <div className="row justify-content-center align-items-center">
                <div id="textSection">
                  <div className="logoForms">
                    <img src={logo} alt="GoFarmers" />
                  </div>
                  <h2 className="centerTitle">Farmer Damaged Generated Report</h2>
                  <form>
                    <div className="form">
                      <div className="formSection"></div>
                      <h5 className="ms-4 fw-bold">Contact Information</h5>
                      <div className="form">
                        <div className="row justify-content-center align-items-center">
                          <label for="ffrs" className="fw-medium text-end col-sm-5 my-1 col-form-label">FFRS ID :</label>
                          <div className="col-sm-5">
                            <input type="text" {...register("ffrsId")} className="textField form-control" id="email" disabled readonly />
                          </div>
                        </div>
                        <div className="row justify-content-center align-items-center">
                          <label for="farmerName" className="fw-medium text-end col-sm-5 my-1 col-form-label">FARMER NAME :</label>
                          <div className="col-sm-5">
                            <input type="text" {...register("farmerName")} className="textField form-control" id="farmerName" disabled readonly />
                          </div>
                        </div>
                        <div className="formSection"></div>
                        <h5 className="ms-4 fw-bold" >General Information</h5>
                        <div className="row justify-content-center align-items-center" >
                          <label htmlFor="date" className="fw-medium text-end col-sm-5 my-1 col-form-label">DATE OF OCCURENCE:</label>
                          <div className="col-sm-5">
                            <input type="date" {...register("disasterDate")} className="textField form-control" id="date" name="date" disabled readonly />
                          </div>
                        </div>
                        <div className="row justify-content-center align-items-center">
                          <label for="type" className="fw-medium text-end col-sm-5 my-1 col-form-label">TYPE OF DISASTER :</label>
                          <div className="col-sm-5">
                            <select className="textField form-select" {...register("disasterType")} id="type" disabled readonly>
                              <option style={{ display: 'none' }}></option>
                              <option defaultValue="Drought">Drought</option>
                              <option defaultValue="Typhoon">Typhoon</option>
                              <option defaultValue="Flood">Flood</option>
                            </select>
                          </div>
                        </div>
                        <div className="formSection"></div>
                        <h5 className="ms-4 fw-bold">Geographic Information</h5>
                        <div className="form">
                          <div className="row justify-content-center align-items-center">
                            <label for="region" className="fw-medium text-end col-sm-5 my-1 col-form-label">REGION :</label>
                            <div className="col-sm-5">
                              <input type="text" inputmode="numeric" className="textField form-control" id="region" defaultValue="3" disabled readonly />
                            </div>
                          </div>
                          <div className="row justify-content-center align-items-center">
                            <label for="province" className="fw-medium text-end col-sm-5 my-1 col-form-label">PROVINCE :</label>
                            <div className="col-sm-5">
                              <input type="text" className="textField form-control" id="province" defaultValue="Bulacan" disabled readonly />
                            </div>
                          </div>
                          <div className="row justify-content-center align-items-center">
                            <label for="municipality" className="fw-medium text-end col-sm-5 my-1 col-form-label">MUNICIPALITY :</label>
                            <div className="col-sm-5">
                              <input type="text" className="textField form-control" id="municipality" defaultValue="San Miguel" disabled readonly />
                            </div>
                          </div>
                          <div className="row justify-content-center align-items-center">
                            <label for="barangay" className="fw-medium text-end col-sm-5 my-1 col-form-label">BARANGAY :</label>
                            <div className="col-sm-5">
                              <input type="text" {...register("barangay")} className="textField form-control" id="barangay" disabled readonly />
                            </div>
                          </div>

                          <div className="formSection"></div>
                          <h5 className="ms-4 fw-bold">Crop Damage Information</h5>
                          <div className="row justify-content-center align-items-center">
                            <label for="cropseed" className="fw-medium text-end col-sm-5 my-1 col-form-label">CROP TYPE :</label>
                            <div className="col-sm-5">
                              <input type="text" {...register("cropSeed")} className="textField form-control" id="cropseed" disabled readonly />
                              {errors?.cropSeed && <small className="text-danger">{errors.cropSeed.message}</small>}
                            </div>
                          </div>
                          <div className="row justify-content-center align-items-center">
                            <label for="type" className="fw-medium text-end col-sm-5 my-1 col-form-label">SEED TYPE :</label>
                            <div className="col-sm-5">
                              <select className="textField form-select" {...register("seedType")} id="type">
                                <option style={{ display: 'none' }}></option>
                                <option value="Inbred">Inbred</option>
                                <option value="Hybrid">Hybrid</option>
                              </select>
                              {errors?.seedType && <small className="text-danger">{errors.seedType.message}</small>}
                            </div>
                          </div>
                          <div className="row justify-content-center align-items-center">
                            <label for="type" className="fw-medium text-end col-sm-5 my-1 col-form-label">CROP TYPE :</label>
                            <div className="col-sm-5">
                              <select className="textField form-select" id="type" {...register("cropType")}>
                                <option style={{ display: 'none' }}></option>
                                <option value="Irrigated">Irrigated</option>
                                <option value="Raised Field - lowland">Raised Field - lowland</option>
                                <option value="Raised Field - upland"> Raised Field - upland</option>
                              </select>
                              {errors?.cropType && <small className="text-danger">{errors.cropType.message}</small>}
                            </div>
                          </div>
                          <div className="row justify-content-center align-items-center">
                            <label for="type" className="fw-medium text-end col-sm-5 my-1 col-form-label">CROP STAGE :</label>
                            <div className="col-sm-5">
                              <select className="textField form-select" id="type" {...register("cropStage")}>
                                <option value="">Select an Option</option>
                                <option value="Seedling">Seedling Stage</option>
                                <option value="Planting">Planting Stage</option>
                                <option value="Vegetative">Vegetative Stage</option>
                                <option value="Reproductive">Reproductive Stage</option>
                                <option value="Maturity">Maturity Stage</option>
                              </select>
                              {errors?.cropStage && <small className="text-danger">{errors.cropStage.message}</small>}
                            </div>
                          </div>
                          <div className="formSection"></div>
                          <h5 className="ms-4 fw-bold">Damage Assessment of Area</h5>
                          <div className="row justify-content-center align-items-center">
                            <label for="region" className="fw-medium text-end col-sm-5 my-1 col-form-label">AREA OF STANDING CROP IN HECTARES :</label>
                            <div className="col-sm-5">
                              <input type="text" {...register("cropArea")} inputmode="numeric" className="textField form-control" id="region" />
                              {errors?.cropArea && <small className="text-danger">{errors.cropArea.message}</small>}
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              {/* BUTTONS */}
              {
                !formIsLoading && form?.data && form.data.status === "bafc-waiting" && (
                  <div className="row justify-content-center align-items-center pt-3">
                    <div className="d-flex justify-content-end">
                      <button type="button" className="btn btn-primary me-3" id="buttonDecline" onClick={modalShow1}>DECLINE</button>
                      <button type="button" className="btn btn-primary" id="buttonApprove" onClick={modalShow}>APPROVE</button>
                    </div>
                  </div>
                )
              }

            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={modalClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body className="py-4">
          <div className="row mb-4 text-center" style={{ color: '#185D10' }}>
            <h1><i className="bi bi-question-circle"></i></h1>
            <h5 className="fw-bold">ARE YOU SURE <br /> YOU WANT TO APPROVE THIS FORM?</h5>
            <h6 className="fw-normal "><i class="bi bi-lightbulb-fill bulb"></i>The changes you applied will also be saved.</h6>
          </div>
          <div className="row text-center">
            <div className="col text-end">
              <Button className="m-auto" onClick={modalClose} id="modalCancelbtn">
                Cancel
              </Button>
            </div>
            <div className="col text-start">
              <Link to="/BrgyFarmersGeneratedReports">
                <Button className="m-auto" onClick={handleSubmit(handleApproveForm)} id="modalApprovebtn">APPROVE</Button>
              </Link>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showModal1}
        onHide={modalClose1}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body className="py-4">
          <div className="row mb-4 text-center" style={{ color: '#185D10' }}>
            <h1><i className="bi bi-question-circle"></i></h1>
            <h5 className="fw-bold">ARE YOU SURE <br /> YOU WANT TO DECLINE THIS FORM?</h5>
            <span className="fw-regular text-dark">This will remove the form from the generated report record</span>
          </div>
          <div className="row text-center">
            <div className="col text-end">
              <Button className="m-auto" onClick={modalClose1} id="modalCancelbtn">
                Cancel
              </Button>
            </div>
            <div className="col text-start">
              <Link to="/BrgyFarmersGeneratedReports">
                <Button className="m-auto" onClick={handleSubmit(handleDeclineForm)} id="modalDeclinebtn">DECLINE</Button>
              </Link>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default BAFCDamagedForm