
import React, { useState } from "react"
// OTHER IMPORTS
import { useSearchParams, Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import formatDate from "date-fns/format"

import { useFetchAllInitialReportsQuery } from "../utils/features/initialReports/initialReportsApiSlice"
import UseAuth from "../utils/hooks/useAuth"

// COMPONENTS IMPORTS
import Navbar from "../navbar/BAFCNavbar"
import SideBarnav from "../sidebar/BAFCSidebar"

const TITLE = 'GoFarmers | Initial Reports'

const BAFCInitialReports = () => {
  const [isClicked, setIsClicked] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleButtonClick = () => {
    setIsClicked(!isClicked)
  }

  const [searchParams] = useSearchParams()

  const user = UseAuth()

  const {
    data: initialReports,
    isLoading: initialReportsIsLoading
  } = useFetchAllInitialReportsQuery({
    sortField: searchParams.get("sortField"),
    userId: user.userId
  })

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Navbar handleButtonClick={handleButtonClick} />
      <div className="container-fluid" id="tryFix">
        <div className="row flex-nowrap overflow-hidden">
          <SideBarnav />
          <div className="col overflow-x-hidden" id={`${isClicked ? 'tryMove' : ''}`}>
            <style>
              {`
                  #tryMove {
                    margin-left: ${isClicked ? '380px' : '0px'}       
                  }
                `}
            </style>
            {/* SECTION TITLE */}
            <h1 className="section">Barangay {user.barangay} Initial Reports</h1>

            {/* BUTTONS */}
            <div className="topButtons">
              <div className="column buttonContainer2">
                <div className="singleContainer2">
                  <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" id="buttons">
                    SORT BY
                  </button>
                  <ul className="dropdown-menu" id="dropDown">
                    <li><Link className="dropdown-item" to={{ search: `?userId=${user.userId}&sortField=-disasterDate` }}>Latest to Oldest</Link></li>
                    <li><Link className="dropdown-item" to={{ search: `?userId=${user.userId}&sortField=disasterDate` }}>Oldest to Latest</Link></li>
                  </ul>
                </div>
                <div className="singleContainer">
                  <Link to="/bafc-initial-report-add"><button className="btn btn-primary" id="buttons">ADD REPORT <i class="bi bi-plus-circle"></i></button></Link>
                </div>
              </div>
            </div>

            {/* GENERATED BARANGAY REPORTS */}
            <div className="row mt-4 tableContainer">
              <div className="col-11 col-lg-11 overflow-auto p-0" id="tableBorder">
                <table className="table mb-0 text-center table-hover align-middle" style={{ borderColor: '#185D10' }}>
                  <thead>
                    <tr id="thead1">
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                    <tr className="align-middle" id="thead1">
                      <th scope="col">DATE OF OCCURENCE</th>
                      <th scope="col">NUMBER OF AFFECTED FARMERS</th>
                      <th scope="col">AREA OF STANDING 	CROP</th>
                      <th scope="col">AREA AFFECTED</th>
                      <th scope="col">YIELD LOSS (%)</th>
                      <th scope="col">ACTION</th>
                    </tr>
                    <tr id="thead1">
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      !initialReportsIsLoading && initialReports?.data.length ? initialReports.data.map((initialReport) => (
                        <tr className="tableData" key={initialReport._id}>
                          <td>{formatDate(new Date(initialReport.disasterDate), "MM-dd-yyyy")}</td>
                          <td>{initialReport.farmersCount}</td>
                          <td>{initialReport.standingCrops}</td>
                          <td>{initialReport.totalDamaged}</td>
                          <td>{initialReport.yieldLoss}%</td>
                          {/* ACTION BUTTONS */}
                          <td>
                            <div className="d-flex flex-row justify-content-center">
                              <div>
                                <Link to={`/bafc-initial-report/${initialReport._id}`} className="btn btn-primary py-2 mx-auto btn-sm" id="editButton"><i className="bi fs-4 bi-eye"></i></Link>
                                {/* <Link to={ `/bafc-initial-report-edit/${ initialReport._id }` } className="btn btn-primary text-primary py-2 mx-auto btn-sm" id="editButton"><i className="bi fs-4 bi-pencil-square"></i></Link> */}
                              </div>
                            </div>
                          </td>
                        </tr>
                      )) : <tr className="tableData">
                        <td className="text-center" colSpan={100}>There are no Initial Reports available...</td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
            {/* FLOATING INFO BUTTON */}
            <div className="floatPosition">
              <div
                style={{ backgroundColor: '#185D10' }}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              >
                <i class="bi bi-lightbulb-fill floatIcon" />
              </div>
              {showTooltip && (
                <div className="customTooltip2">
                  <span className="tooltipText">Use this modal to view and add initial reports. Initial Reports should be created within 24hr of the date of disaster occurence.</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BAFCInitialReports
