import { apiSlice } from "../../app/api/apiSlice"

const distributionApiSlice = apiSlice.injectEndpoints({
   endpoints: (builder) => ({
      fetchUnreceivedDistributionSchedules: builder.query({
         query: (data) => ({
            url: "/distribution/unreceived",
            params: data
         }),
         providesTags: ["Distribution-Unreceived"]
      }),

      fetchDistributionSchedule: builder.query({
         query: (data) => `/distribution/${ data.id }`,
         providesTags: (_res, _err, args) => [{ type: "Distribution", id: args.id }]
      }),

      fetchBarangayDistributionSchedule: builder.query({
         query: (data) => ({
            url: "/distribution/barangay",
            params: data
         }),
         providesTags: ["Distribution-Barangay"]
      }),

      createDistributionSchedule: builder.mutation({
         query: (data) => ({
            url: "/distribution",
            method: "POST",
            body: data
         }),
         invalidatesTags: [
            "Distribution-Unreceived",
            "SummaryReports-Unscheduled-Validated",
            "SummaryReports",
            "SummaryReports-Pending",
            "SummaryReports-History",
            "Process-History"
         ]
      }),

      updateDistributionSchedule: builder.mutation({
         query: ({ id, ...data }) => ({
            url: `/distribution/${ id }`,
            method: "PATCH",
            body: data
         }),
         invalidatesTags: (_res, _err, args) => [
            "Distribution-Unreceived",
            { type: "Distribution", id: args.id },
            "Process-History"
         ]
      }),

      receiveBarangayRehabAid: builder.mutation({
         query: (data) => ({
            url: `/distribution/${ data.id }/receive`,
            method: "PATCH"
         }),
         invalidatesTags: (_res, _err, args) => [
            "Distribution-Unreceived",
            "Distribution-Barangay",
            { type: "Distribution", id: args.id },
            "Process-History"
         ]
      })
   })
})

export const {
   useFetchUnreceivedDistributionSchedulesQuery,
   useFetchDistributionScheduleQuery,
   useCreateDistributionScheduleMutation,
   useUpdateDistributionScheduleMutation,

   useFetchBarangayDistributionScheduleQuery,
   useReceiveBarangayRehabAidMutation
} = distributionApiSlice