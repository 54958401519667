import React, { useState } from "react";
import "./stepper.css";
import { TiTick } from "react-icons/ti";
import formatDate from "date-fns/format"

const Stepper = ({ report }) => {
  const roleWithIcons = [
    {
      name: "BAFC Assessment",
      icon:
        <button class="border-0 bg-transparent " data-toggle="tooltip" data-placement="top" title="This step shows the status of the farmer reports of the BAFC.">
          <i class="bi bi-info-circle-fill text-muted"></i>
        </button>
    },
    {
      name: "AgriTech Assessment",
      icon:
        <button class="border-0 bg-transparent " data-toggle="tooltip" data-placement="top" title="This step shows the status of the barangay reports generated &#10;from the accummulated farmer reports of the barangay.">
          <i class="bi bi-info-circle-fill text-muted"></i>
        </button>
    },
    {
      name: "MAO Assessment",
      icon:
        <button class="border-0 bg-transparent " data-toggle="tooltip" data-placement="top" title="This step shows the status of the barangay reports in the&#13; Municipal Report Officers of MAO.">
          <i class="bi bi-info-circle-fill text-muted"></i>
        </button>
    },
    {
      name: "PAO Assessment",
      icon:
        <button class="border-0 bg-transparent " data-toggle="tooltip" data-placement="top" title="This step shows the status of the barangay reports in &#10;the Provincial Report Officers of PAO.">
          <i class="bi bi-info-circle-fill text-muted"></i>
        </button>
    },
    {
      name: "Final Validation",
      icon:
        <button class="border-0 bg-transparent " data-toggle="tooltip" data-placement="top" title="This step shows the status of the barangay report officer &#10;after the MAO and PAO performs their final validation &#10;of the submitted reports.">
          <i class="bi bi-info-circle-fill text-muted"></i>
        </button>
    },
    {
      name: "Rehab Aid (Municipal)",
      icon:
        <button class="border-0 bg-transparent " data-toggle="tooltip" data-placement="top" title="This step shows the status of the rehabilitation aid &#10;scheduling of the Municipal Agricultural Office.">
          <i class="bi bi-info-circle-fill text-muted"></i>
        </button>
    },
    {
      name: "Received Rehab Aid (Barangay)",
      icon:
        <button class="border-0 bg-transparent " data-toggle="tooltip" data-placement="top" title="This step shows the status of the receiving &#10;of the rehab aids in the barangay sector.">
          <i class="bi bi-info-circle-fill text-muted"></i>
        </button>
    }
  ];
  const names = [
    {
      icons: <i class="bi bi-geo-alt-fill"></i>,
      officer: `Barangay ${report.bafcAssessment.assessedBy.barangay.at(0)}`,
    },
    {
      icons: <i class="bi bi-person-fill"></i>,
      officer: report?.agritechAssessment ? `${report.agritechAssessment.assessedBy.firstName} ${report.agritechAssessment.assessedBy.lastName}` : "Not Available",
    },
    {
      icons: <i class="bi bi-person-fill"></i>,
      officer: report?.maoAssessment ? `${report.maoAssessment.assessedBy.firstName} ${report.maoAssessment.assessedBy.lastName}` : "Not Available",
    },
    {
      icons: <i class="bi bi-person-fill"></i>,
      officer: report?.paoAssessment ? `${report.paoAssessment.assessedBy.firstName} ${report.paoAssessment.assessedBy.lastName}` : "Not Available",
    },
    {
      officer: "",
    },
    {
      officer: "",
    },
    {
      officer: "",
    }
  ];

  const initialStatuses = [
    report?.bafcAssessment ? "ASSESSED" : "PENDING",
    report?.agritechAssessment ? "ASSESSED" : "PENDING",
    report?.maoAssessment ? "ASSESSED" : "PENDING",
    report?.paoAssessment ? "ASSESSED" : "PENDING",
    report?.finalValidation ? "VALIDATED" : "PENDING",
    report?.maoRehabAid ? "SCHEDULED" : "PENDING",
    report?.barangayRehabAid ? "ASSESSED" : "PENDING"
  ]

  const initialApprovalDates = [
    report?.bafcAssessment ? formatDate(new Date(report.bafcAssessment.assessedAt), "MM-dd-yyyy 'at' h:mm a") : null,
    report?.agritechAssessment ? formatDate(new Date(report.agritechAssessment.assessedAt), "MM-dd-yyyy 'at' h:mm a") : null,
    report?.maoAssessment ? formatDate(new Date(report.maoAssessment.assessedAt), "MM-dd-yyyy 'at' h:mm a") : null,
    report?.paoAssessment ? formatDate(new Date(report.paoAssessment.assessedAt), "MM-dd-yyyy 'at' h:mm a") : null,
    report?.finalValidation ? formatDate(new Date(report.finalValidation.validatedAt), "MM-dd-yyyy 'at' h:mm a") : null,
    report?.maoRehabAid ? formatDate(new Date(report.maoRehabAid.receivedAt), "MM-dd-yyyy 'at' h:mm a") : null,
    report?.barangayRehabAid ? formatDate(new Date(report.barangayRehabAid.receivedAt), "MM-dd-yyyy 'at' h:mm a") : null
  ]

  const [complete, setComplete] = useState(false)

  const [currentStep] = useState(() => {
    switch (true) {
      case (report?.barangayRehabAid != null || report?.barangayRehabAid !== undefined): {
        setComplete(true)
        return 7
      } case (report?.maoRehabAid != null || report?.maoRehabAid !== undefined): {
        return 7
      } case (report?.finalValidation != null || report?.finalValidation !== undefined): {
        return 6
      } case (report?.paoAssessment != null || report?.paoAssessment !== undefined): {
        return 5
      } case (report?.maoAssessment != null || report?.maoAssessment !== undefined): {
        return 4
      } case (report?.agritechAssessment != null || report?.agritechAssessment !== undefined): {
        return 3
      } case (report?.bafcAssessment != null || report?.bafcAssessment !== undefined): {
        return 2
      } default: {
        setComplete(true)
        return 7
      }
    }
  })

  const [statuses] = useState(initialStatuses)

  const [approvalDates] = useState(initialApprovalDates)

  return (
    <>
      <div className="container">
        <div className="stepper">
          {names?.map((step, i) => (
            <div
              key={i}
              className={`step-item mb-3 ${currentStep === i + 1 && "active"} ${(i + 1 < currentStep || complete) && "complete"
                } `}
            >
              <div className="step">
                {i + 1 < currentStep || complete ? <TiTick size={24} /> : i + 1}
              </div>
              <div className="names">
                <p className="role">
                  {roleWithIcons[i].name} {roleWithIcons[i].icon}
                </p>
                <p className="officers">
                  {names[i].icons} {names[i].officer}
                </p>
                <button className="statusBtn">{statuses[i]}</button>
                <p className="approval">
                  {approvalDates[i] && <p className="approvalDate">{approvalDates[i]}</p>}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Stepper;