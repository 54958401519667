import { Document, Font, Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer"
import { format } from "date-fns"

import AppLogo from "../../images/logo.png"

import RalewayBold from "../../fonts/Raleway-Bold.ttf"
import Raleway from "../../fonts/Raleway-Regular.ttf"

Font.register({
   family: "Raleway Bold",
   src: RalewayBold
})

Font.register({
   family: "Raleway",
   src: Raleway
})

const styles = StyleSheet.create({
   body: {
      paddingVertical: 35,
      paddingHorizontal: 35
   },
   wrapper: {
      display: "flex",
      flexDirection: "column",
      borderStyle: "solid",
      borderWidth: "2px",
      borderColor: "#185D10",
      borderRadius: 15,
      width: "75%",
      marginHorizontal: "auto",
      paddingVertical: 10,
      paddingHorizontal: 20
   },
   logo: {
      marginHorizontal: "auto",
      width: 125
   },
   header: {
      color: "#185D10",
      fontSize: 16,
      fontFamily: "Raleway Bold",
      textAlign: "center",
      marginTop: 5
   },
   divider: {
      width: "100%",
      height: 1,
      backgroundColor: "#185D10",
      marginVertical: 8
   },
   sectionHeader: {
      color: "#185D10",
      fontSize: 11,
      fontFamily: "Raleway Bold",
      textAlign: "left",
      marginBottom: 5
   },
   fieldsWrapper: {
      display: "flex",
      flexDirection: "column",
      rowGap: 3.5,
      width: "100%"
   },
   fieldWrapper: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      columnGap: 10,
      width: "100%"
   },
   fieldLabel: {
      color: "#185D10",
      fontSize: 8.5,
      fontFamily: "Raleway",
      textAlign: "right",
      textTransform: "uppercase"
   },
   fieldValue: {
      color: "#185D10",
      fontSize: 8.5,
      fontFamily: "Raleway",
      textAlign: "left",
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: "#185D10",
      borderRadius: 5,
      paddingHorizontal: 5,
      paddingTop: 2.5,
      paddingBottom: 1.5,
      width: 125
   },
   previewImageWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      rowGap: "1.5rem",
      width: "100%"
   },
   previewImage: {
      minWidth: "300px",
      maxWidth: "80%",
      minHeight: "150px",
      maxHeight: "650px"
   }
})

const SummaryReportPDF = ({ data }) => {
   return data ? (
      <Document>
         <Page size="A4" style={styles.body}>
            <View style={styles.wrapper}>
               <Image style={styles.logo} src={AppLogo} />

               <Text style={styles.header}>Barangay {data.barangay} Summary Report</Text>

               <Divider />

               <Text style={styles.sectionHeader}>General Information</Text>

               <View style={styles.fieldsWrapper}>
                  <FormField label="date of occurence :" value={format(new Date(data.initialReport.disasterDate), "MM/dd/yyyy")} />

                  <FormField label="type of disaster :" value={data.initialReport.disasterType} />

                  <FormField label="name of disaster :" value={data.initialReport?.disasterName || "Unnamed Disaster"} />
               </View>

               <Divider />

               <Text style={styles.sectionHeader}>Geographic Information</Text>

               <View style={styles.fieldsWrapper}>
                  <FormField label="region :" value="3" />

                  <FormField label="province :" value="Bulacan" />

                  <FormField label="municipality :" value="San Miguel" />

                  <FormField label="barangay :" value={data.barangay} />
               </View>

               <Divider />

               <Text style={styles.sectionHeader}>Particulars</Text>

               <View style={styles.fieldsWrapper}>
                  <FormField label="crop type :" value={data.cropType} />

                  <FormField label="crop stage :" value={data.cropStage} />

                  <FormField label="area of standing crops :" value={data.cropArea} />

                  <FormField label="number of affected farmers :" value={data.farmersCount} />

                  <FormField label="seed type :" value={data.seedType} />
               </View>

               <Divider />

               <Text style={styles.sectionHeader}>Area Affected (HA) Seedlings (BAGS)</Text>

               <View style={styles.fieldsWrapper}>
                  <FormField label="totally damaged :" value={data.totallyDamaged} />

                  <FormField label="partially damaged :" value={data.partiallyDamaged} />

                  <FormField label="total :" value={data.totalDamaged} />
               </View>

               <Divider />

               <Text style={styles.sectionHeader}>Yield per Hectare (M.T)</Text>

               <View style={styles.fieldsWrapper}>
                  <FormField label="before calamity :" value={data.yieldBefore} />

                  <FormField label="after calamity :" value={data.yieldAfter} />

                  <FormField label="yield loss (%) :" value={data.yieldLoss} />
               </View>

               <Divider />

               <Text style={styles.sectionHeader}>Total Losses (Based on Cost of Input)</Text>

               <View style={styles.fieldsWrapper}>
                  <FormField label="cost of input / HA :" value={data.inputCost} />

                  <FormField label="total :" value={data.totalCost} />
               </View>
            </View>

            <View style={styles.previewImageWrapper}>
               <Text break style={styles.sectionHeader}>Disaster Damage Photo Documentation</Text>

               {
                  data.imagePath ? (
                     <Image style={styles.previewImage} src={`${process.env.REACT_APP_SERVER_URL}/${data.imagePath}`} />
                  ) : null
               }
            </View>
         </Page>
      </Document>
   ) : null
}

const Divider = () => <View style={styles.divider} />

const FormField = ({ label, value }) => (
   <View style={styles.fieldWrapper}>
      <View style={{ width: "100%" }}>
         <Text style={styles.fieldLabel}>{label}</Text>
      </View>

      <View style={{ width: "100%" }}>
         <Text style={styles.fieldValue}>{value}</Text>
      </View>
   </View>
)

export default SummaryReportPDF