/* eslint-disable no-unused-vars */
import React, { useState } from "react"
// OTHER IMPORTS
import { Link } from "react-router-dom"
import { Helmet } from "react-helmet"

// COMPONENTS
import Navbar from "../navbar/BAFCNavbar"
import SideBarnav from "../sidebar/BAFCSidebar"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"

import { useGetAllUserQuery, useDeleteUserMutation } from "../utils/features/users/usersApiSlice"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { useGetUserQuery } from "../utils/features/users/usersApiSlice"

const TITLE = "GoFarmers | Farmer Management"

const BAFCAccount = () => {
  const [isClicked, setIsClicked] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");

  const handleButtonClick = () => {
    setIsClicked(!isClicked)
  }
  const [showModal, setshowModal] = useState(false)

  const modalClose = () => setshowModal(false)
  const modalShow = () => setshowModal(true)

  const [userToDelete, setUserToDelete] = useState(null)
  const { data: user } = useGetUserQuery()

  let BAFCbarangay
  if (user && user.user.barangay && user.user.barangay.length > 0) {
    BAFCbarangay = user.user.barangay[0]
  }

  const { data: users, isLoading } = useGetAllUserQuery("usersList", {
    pollingInterval: 15000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  })

  const [deleteUser, { isLoading: isDeleting }] = useDeleteUserMutation()

  const onDeleteUserClicked = (user) => {
    setUserToDelete(user)
    modalShow()
  }

  const handleDeleteConfirmed = async () => {
    if (userToDelete) {
      try {
        await deleteUser({ id: userToDelete.id })
        toast.success("User deleted successfully!")
      } catch (error) {
        // console.error("Error deleting user:", error)
        toast.success("Failed to delete user")
      }
    }
    modalClose()
  }

  const User = ({ user }) => {
    const navigate = useNavigate()

    if (!user) return null

    const handleEdit = (user) => {
      if (user && user.id) {
        navigate(`/bafc-account-edit/${user.id}`, { state: { user } })
      } else {
        // console.log("Invalid user data:", user)
      }
    }

    const onDeleteUserClickedLocal = () => {
      onDeleteUserClicked(user)
    }

    return (
      <tr className="tableData">
        <td>{user.ffrs}</td>
        <td>{user.firstName}</td>
        <td>{user.lastName}</td>
        <td>{user.mobile}</td>
        <td>{user.barangay}</td>
        <td>
          <div className="d-flex flex-row justify-content-center">
            <div>
              <button className="btn py-2 m-auto" id="editButton" onClick={() => handleEdit(user)}>
                <i className="bi fs-4 bi-pencil-square"></i>
              </button>
            </div>
            <div>
              <button type="button" className="btn py-2 m-auto" onClick={onDeleteUserClickedLocal}>
                <i className="bi fs-4 text-danger bi-trash-fill"></i>
              </button>
            </div>
          </div>
        </td>
      </tr>
    )
  }

  let content

  if (isDeleting) content = <p>Deleting...</p>

  if (isLoading) content = <p>Loading...</p>

  const renderUsers = () => {
    if (isLoading) {
      return (
        <tr className="tableData">
          <td>Loading...</td>
        </tr>
      );
    }

    if (!users || users.length === 0) {
      return (
        <tr className="tableData">
          <td colSpan={100}>No users found</td>
        </tr>
      );
    }

    const lowercaseSearchQuery = searchQuery.toLowerCase();

    const allowedRoles = ['user'];

    const filteredUsers = users.ids
      .map((userId) => users.entities[userId])
      .filter((user) => {
        if (!searchQuery) {
          return allowedRoles.includes(user.role) && user.barangay.includes(BAFCbarangay)
        }

        const lowercaseFFRS = user.ffrs ? user.ffrs.toLowerCase() : '';
        const lowercaseFirstName = user.firstName ? user.firstName.toLowerCase() : '';
        const lowercaseLastName = user.lastName ? user.lastName.toLowerCase() : '';
        const lowercaseMobile = user.mobile ? user.mobile.toLowerCase() : '';
        const lowercaseBarangay = user.barangay
          ? user.barangay.map((b) => b.toLowerCase())
          : [];
        const lowercaseRole = user.role ? user.role.toLowerCase() : '';

        return (
          allowedRoles.includes(user.role) &&
          user.barangay.includes(BAFCbarangay) &&
          (
            lowercaseFFRS.includes(lowercaseSearchQuery) ||
            lowercaseFirstName.includes(lowercaseSearchQuery) ||
            lowercaseLastName.includes(lowercaseSearchQuery) ||
            lowercaseMobile.includes(lowercaseSearchQuery) ||
            lowercaseBarangay.some((b) => b.includes(lowercaseSearchQuery)) ||
            lowercaseRole.includes(lowercaseSearchQuery)
          )
        );
      }).reverse()

    if (filteredUsers.length === 0) {
      return (
        <tr className="tableData">
          <td colSpan={100}>No users found</td>
        </tr>
      );
    }

    return filteredUsers.map((user) => <User key={user.id} user={user} />);
  };

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Navbar handleButtonClick={handleButtonClick} />
      <div className="container-fluid" id="tryFix">
        <div className="row flex-nowrap overflow-hidden">
          <SideBarnav />
          <div
            className="col overflow-x-hidden"
            id={`${isClicked ? "tryMove" : ""}`}
          >
            <style>
              {`
                #tryMove {
                  margin-left: ${isClicked ? "380px" : "0px"};
                  
                }
              `}
            </style>
            {/* SECTION TITLE */}
            <h1 className="section">Farmer Account Management</h1>
            {/* BUTTONS */}
            <div className="topButtons">
              <div className="row buttonContainer">
                <div className="doubleContainer">
                  {/* <div className="searchContainer">
                    <input
                      className="textSearch"
                      type="text"
                      placeholder="Search User here..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <i className="bi bi-search searchIcon"></i>
                  </div> */}
                  <Link to="/bafc-account-add"><button className="btn btn-primary" id="buttons">ADD ACCOUNT <i class="bi bi-plus-circle"></i></button></Link>
                </div>
              </div>
            </div>
            {/* ACCOUNTS TABLE */}
            <div className="row mt-4 tableContainer">
              <div
                className="col-11 col-lg-11 overflow-auto p-0"
                id="tableBorder"
              >
                <table
                  className="table mb-0 text-center table-hover align-middle"
                  style={{ borderColor: "#185D10" }}
                >
                  <thead>
                    <tr id="thead1">
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <thead>
                    <tr className="align-middle" id="thead1">
                      <th scope="col">FFRS ID</th>
                      <th scope="col">FIRST NAME</th>
                      <th scope="col">LAST NAME</th>
                      <th scope="col">MOBILE</th>
                      <th scope="col">BARANGAY</th>
                      <th scope="col">ACTION</th>
                    </tr>
                  </thead>
                  <thead>
                    <tr id="thead1">
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>{renderUsers()}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* FLOATING INFO BUTTON */}
      <div className="floatPosition">
        <div
          style={{ backgroundColor: '#185D10' }}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          <i class="bi bi-lightbulb-fill floatIcon" />
        </div>
        {showTooltip && (
          <div className="customTooltip">
            <span className="tooltipText">Use this modal to manage the farmer accounts on your barangay.</span>
          </div>
        )}
      </div>
      {/* MODAL FOR DELETE ACCOUNT BUTTON*/}
      <Modal
        show={showModal}
        onHide={modalClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body className="py-4">
          <div className="row mb-4 text-center" style={{ color: "#185D10" }}>
            <h1>
              <i className="bi bi-question-circle"></i>
            </h1>
            <h5 className="fw-bold">
              ARE YOU SURE <br /> YOU WANT TO DELETE THIS ACCOUNT?
            </h5>
          </div>
          <div className="row text-center">
            <div className="col text-end">
              <Button
                className="m-auto"
                onClick={modalClose}
                id="modalCancelbtn"
              >
                Cancel
              </Button>
            </div>
            <div className="col text-start">
              <Button
                className="m-auto"
                id="modalDeletebtn"
                onClick={handleDeleteConfirmed}
              >
                Delete
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default BAFCAccount
