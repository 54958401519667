import { Link, useNavigate } from 'react-router-dom';
import sbStyles from './css/sidebar.module.css';

import { useDispatch } from "react-redux"
import { useLogoutMutation } from "../utils/features/auth/authApiSlice"
import { logOut } from "../utils/features/auth/authSlice"
import UseAuth from "../utils/hooks/useAuth"

const myComponentStyle = {
  background: '#185D10',
  borderBottomRightRadius: '2.5rem',
  minWidth: 380,
  maxHeight: 'calc(105vh - 105px)',
  position: 'fixed',
  zIndex: '1',
  transition: '0.4s',
}

const height = {
  minHeight: 'calc(105vh - 105px)',
}

const SADSidebar = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { firstName, lastName } = UseAuth()

  const [logout] = useLogoutMutation()

  const logoutHandler = async () => {
    try {
      await logout().unwrap()
      dispatch(logOut())
      navigate('/')
    } catch (error) {
      // console.log(error)
    }
  }

  return (
    <div style={myComponentStyle} className="collapse collapse-row overflow-hidden col-md-2 col-xl-2 px-sm-2 px-0" id="collapseExample">
      <div className="d-flex flex-column px-3 pt-3" style={height}>
        <ul className="nav nav-pills flex-column mb-sm-auto mb-0 ms-md-4 ms-lg-0" id="menu">
          <li>
            <Link role="button" to="/superadmin-dashboard" className="ms-auto w-100 text-start mt-4 py-3 btn btn-lg btn-block" id={sbStyles["hover2"]}>
              <i className="fs-4 bi-house"></i>
              <span className="ms-3">Dashboard</span>
            </Link>
          </li>
          <li>
            <Link type="button" to="/superadmin-account" className=" p-3 w-100 text-start btn btn-lg btn-block" id={sbStyles["hover2"]}>
              <i className="fs-4 bi-clipboard-data"></i>
              <span className="ms-3">Account Management</span>
            </Link>
          </li>
        </ul>
        <div className="dropdown pb-4">
          <div className="p-0 w-100 d-flex flex-row text-white text-decoration-none border-0 btn btn-lg btn-block">
            <div className="row justify-content-between">
              <div className="col-10">
                <div>
                  <h5 className="pt-1">{firstName} {lastName}</h5>
                </div>
              </div>
              <div className="col-2">
                <Link to="/"><button type="button" className="btn btn-block mx-auto mt-2 justify-content-end" id={sbStyles["hoverOut"]} onClick={logoutHandler}>
                  <i className="bi fs-4 bi-box-arrow-left"></i>
                </button></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SADSidebar