import { useLocation } from "react-router-dom"
import { useNavigate, Link } from 'react-router-dom'
import { useDispatch } from "react-redux"
import { logOut } from "../utils/features/auth/authSlice"
import { useLogoutMutation } from "../utils/features/auth/authApiSlice"

const maskEmail = (email) => {
    const atIndex = email.indexOf("@")
    const maskedPart = email.slice(0, atIndex).substring(0, 3) + "****"
    const domainPart = email.slice(atIndex)
    return maskedPart + domainPart
}

const ForgotPasswordEmailSent = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const [logout] = useLogoutMutation()

    const { email } = location.state

    const maskedEmail = maskEmail(email)

    const logoutHandler = async () => {
        try {
            await logout().unwrap()
            dispatch(logOut())
            navigate('/')
        } catch (error) {
            // console.log(error)
        }
    }

    return (
        <div className="card">
            <div className="card-body">
                <h5 className="card-title">Password Reset</h5>
                <p className="card-text">
                    An email with a password reset link has been sent to your email: <strong>{maskedEmail}</strong>
                </p>
                <p className="card-text">Check your email and click on the link to proceed!</p>
                <div className="pt-1 mb-3 d-grid">
                    <Link to="/"><button type="button" className="btn btn-md fw-bold" id="entryButton" onClick={logoutHandler}>Sign In</button></Link>
                </div>
            </div>
        </div>
    )
}

export default ForgotPasswordEmailSent
