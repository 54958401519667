import { Helmet } from 'react-helmet';
import gofarmers from '../images/mao_logo.webp';
import paologo from '../images/pao_logo.webp';
import smlogo from '../images/sanmiguel.webp';

import { useNavigate, Link } from "react-router-dom"
import { useDispatch } from 'react-redux'
import { setCredentials } from '../utils/features/auth/authSlice'
import { useSigninMutation } from '../utils/features/auth/authApiSlice'
import { useForm } from "react-hook-form"
import { toast } from 'react-toastify'
import jwtDecode from "jwt-decode"

const TITLE = 'GoFarmers | Sign In'

const SignIn = () => {
  const { handleSubmit, register, formState: { errors } } = useForm()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [signin] = useSigninMutation()

  const onSubmit = (data) => {
    signin(data)
      .unwrap()
      .then(({ accessToken }) => {
        toast.success('Signed in successfully!')
        dispatch(setCredentials({ accessToken }))

        const decodedToken = jwtDecode(accessToken)
        const role = decodedToken.role

        if (role === 'superAdmin') {
          navigate('/superadmin-dashboard')
        } else if (role === 'BAFCadmin') {
          navigate('/bafc-dashboard')
        } else if (role === 'agritech') {
          navigate('/agritech-dashboard')
        } else if (role === 'MRO-rice' || role === 'MRO-veg') {
          navigate('/mro-dashboard')
        } else if (role === 'PRO-rice' || role === 'PRO-veg') {
          navigate('/pro-dashboard')
        }
      })
      .catch((err) => {
        if (err.status === 404) {
          toast.error("No account found with this email address")
        }
        else if (err.status === 400) {
          toast.error("Invalid credentials")
        } else {
          toast.error("An error occurred")
          // console.log(err)
        }
      })
  }

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <div className="bg">
        <section className="vh-100">
          <div className="container py-7 h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col col-xl-8">
                <div className="card-transparent border-0 py-5 px-3" id="cardGlass">
                  <div className="row g-0">
                    <div className="col-lg-6 d-none d-lg-block text-center ps-5 pt-4">
                      <img src={gofarmers} alt="Municipal Agricultural Office" id="maoLogo" style={{ minWidth: "55%" }} />
                      <p className="fst-italic fs-3" id="inCollab">In Collaboration with:</p>
                      <div className="row justify-content-center g-0 ">
                        <div className="col-5">
                          <img src={smlogo} alt="Municipality of San Miguel" style={{ minWidth: "65%" }} />
                        </div>
                        <div className="col-5">
                          <img src={paologo} alt="Provincial Agricultural Office" style={{ minWidth: "65%" }} />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-10 col-lg-6 d-flex align-items-center mx-auto">
                      <div className="card-body p-4 p-lg-5 text-black">
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <h5 className="fs-2 fw-bold" style={{ color: '#185D10' }}>Sign In</h5>
                          <p className="text-white fw-normal ms-0 mb-3 pb-1">Enter your email and password to sign in</p>

                          <div className="form-outline mb-2">
                            <input type="email" id="form2Example17" className="form-control form-control-md fw-light" placeholder="Email" {...register("email", { required: "This field is required" })} />
                            {errors.email && <p className="errmsg">{errors.email.message}</p>}
                          </div>

                          <div className="form-outline mb-2">
                            <input type="password" id="form2Example27" className="form-control form-control-md fw-light" placeholder="Password" {...register("password", { required: "This field is required" })} />
                            {errors.password && <p className="errmsg">{errors.password.message}</p>}
                          </div>

                          <div className="d-flex justify-content-between align-items-center">
                            {/* <div className="form-check mb-4">
                              <input className="form-check-input me-2" type="checkbox" value="" id="checkBox" />
                              <label className="form-check-label text-white" for="form2Example3">
                                Remember me
                              </label>
                            </div> */}
                            <Link to="/forgot-password" className="fw-semibold text-decoration-none mb-3" id="brown">Forgot password?</Link>
                          </div>

                          <div className="row">
                            <div className="pt-0 mb-2 d-grid">
                              <button type="submit" className="btn btn-md fw-bold" id="entryButton">Sign In</button>
                            </div>
                            {/* <div className="text-center">
                              <span className="fs-5 text-white fw-light">Don't have an account?</span>
                              <Link to="/signup" className="fs-5 fw-bold text-decoration-none m-0" id="brown"> Sign Up</Link>
                            </div> */}
                          </div>

                          <div className="row">
                            <div className="fs-6 text-white fw-light text-center">
                              By Signing in, you are agreeing to our <br />
                              <Link to="/terms" className="text-white"><span className="fw-bold fst-italic m-2">Terms & Conditions</span></Link>
                              and
                              <Link to="/policy" className="text-white">
                                <span className="fw-bold fst-italic m-2">  Privacy Policy</span>
                              </Link>
                            </div>
                          </div>
                        </form>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default SignIn