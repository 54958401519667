import { apiSlice } from "../../app/api/apiSlice"

const summaryReportsApiSlice = apiSlice.injectEndpoints({
   endpoints: (builder) => ({
      fetchOngoingSummaryReports: builder.query({
         query: (data) => ({
            url: "/summary-reports/ongoing",
            params: data
         }),
         providesTags: ["SummaryReports"]
      }),

      fetchPendingSummaryReports: builder.query({
         query: (data) => ({
            url: "/summary-reports/pending",
            params: data
         }),
         providesTags: ["SummaryReports-Pending"]
      }),

      fetchReportHistory: builder.query({
         query: (data) => ({
            url: "/summary-reports/history",
            params: data
         }),
         providesTags: ["SummaryReports-History"]
      }),

      generateSummaryReports: builder.mutation({
         query: () => ({
            url: "/summary-reports",
            method: "POST"
         }),
         invalidatesTags: ["SummaryReports", "SummaryReports-Pending", "Forms-Web"]
      }),

      fetchSummaryReport: builder.query({
         query: (data) => `/summary-reports/${ data.id }`,
         providesTags: (_res, _err, args) => [{ type: "SummaryReports", id: args.id }]
      }),

      approveAgritechSummaryReport: builder.mutation({
         query: ({ id, ...data }) => ({
            url: `/summary-reports/${ id }/agri-approve`,
            method: "PATCH",
            body: data
         }),
         invalidatesTags: (_res, _err, args) => [
            { type: "SummaryReports", id: args.id },
            "SummaryReports",
            "SummaryReports-Pending",
            "Dashboard"
         ]
      }),

      submitAgritechSummaryReport: builder.mutation({
         query: ({ id, ...data }) => ({
            url: `/summary-reports/${ id }/agri-submit`,
            method: "PATCH",
            body: data
         }),
         invalidatesTags: (_res, _err, args) => [
            { type: "SummaryReports", id: args.id },
            "SummaryReports",
            "SummaryReports-Pending",
            "SummaryReports-History",
            "Dashboard"
         ]
      }),

      uploadMroSummaryReportImage: builder.mutation({
         query: (data) => ({
            url: "/summary-reports/mro-upload",
            method: "POST",
            body: data,
            formData: true
         })
      }),

      approveMroSummaryReport: builder.mutation({
         query: ({ id, ...data }) => ({
            url: `/summary-reports/${ id }/mro-approve`,
            method: "PATCH",
            body: data
         }),
         invalidatesTags: (_res, _err, args) => [
            { type: "SummaryReports", id: args.id },
            "SummaryReports",
            "SummaryReports-Pending",
            "Dashboard"
         ]
      }),

      submitMroSummaryReport: builder.mutation({
         query: ({ id, ...data }) => ({
            url: `/summary-reports/${ id }/mro-submit`,
            method: "PATCH",
            body: data
         }),
         invalidatesTags: (_res, _err, args) => [
            { type: "SummaryReports", id: args.id },
            "SummaryReports",
            "SummaryReports-Pending",
            "SummaryReports-History",
            "Dashboard"
         ]
      }),

      uploadProSummaryReportImage: builder.mutation({
         query: (data) => ({
            url: "/summary-reports/pro-upload",
            method: "POST",
            body: data,
            formData: true
         })
      }),

      approveProSummaryReport: builder.mutation({
         query: ({ id, ...data }) => ({
            url: `/summary-reports/${ id }/pro-approve`,
            method: "PATCH",
            body: data
         }),
         invalidatesTags: (_res, _err, args) => [
            { type: "SummaryReports", id: args.id },
            "SummaryReports",
            "SummaryReports-Pending",
            "Dashboard"
         ]
      }),

      submitProSummaryReport: builder.mutation({
         query: ({ id, ...data }) => ({
            url: `/summary-reports/${ id }/pro-submit`,
            method: "PATCH",
            body: data
         }),
         invalidatesTags: (_res, _err, args) => [
            { type: "SummaryReports", id: args.id },
            "SummaryReports",
            "SummaryReports-Pending",
            "SummaryReports-History",
            "SummaryReports-Validated",
            "Process-History",
            "Dashboard"
         ]
      }),

      fetchValidatedSummaryReports: builder.query({
         query: (data) => ({
            url: "/summary-reports/validated",
            params: data
         }),
         providesTags: ["SummaryReports-Validated"]
      }),

      fetchUnscheduledValidatedSummaryReports: builder.query({
         query: () => "/summary-reports/unscheduled-validated",
         providesTags: ["SummaryReports-Unscheduled-Validated"]
      }),

      fetchProcessHistory: builder.query({
         query: (data) => ({
            url: "/summary-reports/process-history",
            params: data
         }),
         providesTags: ["Process-History"]
      })
   })
})

export const {
   useFetchOngoingSummaryReportsQuery,
   useGenerateSummaryReportsMutation,
   useFetchPendingSummaryReportsQuery,
   useFetchReportHistoryQuery,
   useFetchSummaryReportQuery,
   useApproveAgritechSummaryReportMutation,
   useSubmitAgritechSummaryReportMutation,
   useUploadMroSummaryReportImageMutation,
   useApproveMroSummaryReportMutation,
   useSubmitMroSummaryReportMutation,
   useUploadProSummaryReportImageMutation,
   useApproveProSummaryReportMutation,
   useSubmitProSummaryReportMutation,
   useFetchValidatedSummaryReportsQuery,
   useFetchUnscheduledValidatedSummaryReportsQuery,
   useFetchProcessHistoryQuery
} = summaryReportsApiSlice