import { useSelector } from "react-redux"
import { selectCurrentToken } from "../features/auth/authSlice"
import jwtDecode from "jwt-decode"

const UseAuth = () => {
  const token = useSelector(selectCurrentToken)

  if (token) {
    const decoded = jwtDecode(token)
    const { userId, firstName, lastName, mobile, email, barangay, role } = decoded

    return { userId, firstName, lastName, mobile, email, barangay, role }
  }

  return {
    userId: "",
    firstName: "",
    lastName: "",
    mobile: "",
    email: "",
    barangay: "",
    role: "",
  }
}

export default UseAuth
