import { store } from "../../app/store"
import { usersApiSlice } from "../../features/users/usersApiSlice"
import { useEffect } from "react"
import { Outlet } from "react-router-dom"

const Prefetch = () => {
  useEffect(() => {
    const users = store.dispatch(usersApiSlice.endpoints.getAllUser.initiate())

    return () => {
      users.unsubscribe()
    }
  }, [])

  return <Outlet />
}
export default Prefetch
