import React from "react"
import { useNavigate, Link } from 'react-router-dom'
import { useDispatch } from "react-redux"
import { logOut } from "../utils/features/auth/authSlice"
import { useLogoutMutation } from "../utils/features/auth/authApiSlice"

const ForgotPasswordEmailSent = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [logout] = useLogoutMutation()

    const logoutHandler = async () => {
        try {
            await logout().unwrap()
            dispatch(logOut())
            navigate('/')
        } catch (error) {
            // console.log(error)
        }
    }

    return (
        <div className="card">
            <div className="card-body">
                <h5 className="card-title">Page Not Found!</h5>
                <p className="card-text">
                    We are sorry, but the page you are looking for could not be found.
                </p>
                <div className="pt-1 mb-3 d-grid">
                    <Link to="/"><button type="button" className="btn btn-md fw-bold" id="entryButton" onClick={logoutHandler}>Return to GoFarmers</button></Link>
                </div>
            </div>
        </div>
    )
}

export default ForgotPasswordEmailSent
