import React, { useEffect, useState } from "react";
// OTHER IMPORTS
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import formatDate from "date-fns/format";
import { useFetchInitialReportQuery } from "../utils/features/initialReports/initialReportsApiSlice";
import UseAuth from "../utils/hooks/useAuth";

// COMPONENTS
import Navbar from "../navbar/BAFCNavbar";
import SideBarnav from "../sidebar/BAFCSidebar";
import logo from "../images/logo.webp";

const TITLE = "GoFarmers | Initial Report Form";

const validationSchema = z.object({
  disasterDate: z.coerce.date({
    errorMap: () => ({ message: "Disaster Type is invalid." }),
  }),
  disasterType: z
    .string({ errorMap: () => ({ message: "Disaster Type is invalid." }) })
    .trim()
    .nonempty("Disaster Type is required.")
    .max(255, "Disaster Type cannot be more than 255 character(s)."),
  disasterName: z
    .string({ errorMap: () => ({ message: "Disaster Name is invalid." }) })
    .trim()
    .max(255, "Disaster Name cannot be more than 255 character(s)."),
  cropType: z
    .string({ errorMap: () => ({ message: "Crop Type is invalid." }) })
    .trim()
    .nonempty("Crop Type is required.")
    .max(255, "Crop Type cannot be more than 255 character(s)."),
  farmersCount: z.coerce
    .number({
      errorMap: () => ({ message: "Number of Affected Farmers is invalid." }),
    })
    .positive("Number of Affected Farmers is invalid."),
  seedType: z
    .string({ errorMap: () => ({ message: "Seed Type is invalid." }) })
    .trim()
    .nonempty("Seed Type is required.")
    .max(255, "Seed Type cannot be more than 255 character(s)."),
  totallyDamaged: z.coerce
    .number({
      errorMap: () => ({ message: "Totally Damaged Area is invalid." }),
    })
    .positive("Totally Damaged Area is invalid."),
  partiallyDamaged: z.coerce
    .number({
      errorMap: () => ({ message: "Partially Damaged Area is invalid." }),
    })
    .positive("Partially Damaged Area is invalid."),
  totalDamaged: z.coerce.number({
    errorMap: () => ({ message: "Total Damaged Area is invalid." }),
  }),
  yieldBefore: z.coerce
    .number({ errorMap: () => ({ message: "Yield Before is invalid." }) })
    .positive("Yield Before is invalid."),
  yieldAfter: z.coerce
    .number({ errorMap: () => ({ message: "Yield After is invalid." }) })
    .positive("Yield After is invalid."),
  yieldLoss: z.coerce.number({
    errorMap: () => ({ message: "Yield Loss is invalid." }),
  }),
  inputCost: z.coerce
    .number({ errorMap: () => ({ message: "Input Cost is invalid." }) })
    .positive("Input Cost is invalid."),
  totalCost: z.coerce.number({
    errorMap: () => ({ message: "Total Cost is invalid." }),
  }),
});

const BAFCViewInitialReport = () => {
  const params = useParams();

  const [isClicked, setIsClicked] = useState(false);

  const handleButtonClick = () => {
    setIsClicked(!isClicked);
  };

  const {
    register,
    reset,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    resolver: zodResolver(validationSchema),
    reValidateMode: "onChange",
  });

  const { data: initialReport, isLoading: initialReportIsLoading } =
    useFetchInitialReportQuery({
      id: params.id,
    });

  // Populate form with database values
  useEffect(() => {
    if (!initialReportIsLoading && initialReport?.data) {
      reset({
        disasterDate: formatDate(
          new Date(initialReport.data.disasterDate),
          "yyyy-MM-dd"
        ),
        disasterType: initialReport.data.disasterType,
        disasterName: initialReport.data.disasterName,
        cropType: initialReport.data.cropType,
        standingCrops: initialReport.data.standingCrops,
        farmersCount: initialReport.data.farmersCount,
        seedType: initialReport.data.seedType,
        totallyDamaged: initialReport.data.totallyDamaged,
        partiallyDamaged: initialReport.data.partiallyDamaged,
        yieldBefore: initialReport.data.yieldBefore,
        yieldAfter: initialReport.data.yieldAfter,
        inputCost: initialReport.data.inputCost,
      });
    }
  }, [initialReportIsLoading, initialReport, reset]);

  // ToolTip
  const [showTooltip, setShowTooltip] = useState(false);

  // Auto-compute Total Damaged Area
  const [_totallyDamaged, _partiallyDamaged] = watch([
    "totallyDamaged",
    "partiallyDamaged",
  ]);

  useEffect(() => {
    if (
      _totallyDamaged &&
      _partiallyDamaged &&
      +_totallyDamaged &&
      +_partiallyDamaged
    ) {
      let totalDamaged = +_totallyDamaged + +_partiallyDamaged;

      setValue("totalDamaged", Number(totalDamaged.toFixed(2)));
    }
  }, [_totallyDamaged, _partiallyDamaged, setValue]);

  // Auto-compute Yield Loss
  const [_yieldBefore, _yieldAfter] = watch(["yieldBefore", "yieldAfter"]);

  useEffect(() => {
    if (_yieldBefore && _yieldAfter && +_yieldBefore && +_yieldAfter) {
      let yieldLoss = +_yieldAfter / +_yieldBefore;

      yieldLoss *= 100;
      yieldLoss -= 100;
      yieldLoss *= -1;

      setValue("yieldLoss", Number(yieldLoss.toFixed(2)));
    }
  }, [_yieldBefore, _yieldAfter, setValue]);

  // Auto-compute Total Cost
  const [_inputCost, _totalDamaged] = watch(["inputCost", "totalDamaged"]);

  useEffect(() => {
    if (_inputCost && _totalDamaged && +_inputCost && +_totalDamaged) {
      let totalCost = +_inputCost * +_totalDamaged;

      setValue("totalCost", Number(totalCost.toFixed(2)));
    }
  }, [_inputCost, _totalDamaged, setValue]);

  const { barangay } = UseAuth();

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <Navbar handleButtonClick={handleButtonClick} />

      <div className="container-fluid" id="tryFix">
        <div className="row flex-nowrap  overflow-hidden">
          <SideBarnav />
          <div
            className="col overflow-x-hidden"
            id={`${isClicked ? "tryMove" : ""}`}
          >
            <style>
              {`
                #tryMove {
                  margin-left: ${isClicked ? "380px" : "0px"}; 
                }
              `}
            </style>
            {/* SECTION TITLE */}
            <h1 className="section">View Barangay Initial Report</h1>

            <div className="col-md-3">
              <Link to="/bafc-initial-reports">
                <button className="btn btn-success" id="buttons">
                  <i className="bi bi-arrow-left"></i> BACK
                </button>
              </Link>
            </div>

            <div className="container" id="damageContainer">
              <div className="row justify-content-center align-items-center">
                <div id="textSection">
                  <div className="logoForms">
                    <img src={logo} alt="GoFarmers" />
                  </div>
                  <h2 className="centerTitle">Barangay Initial Report</h2>
                  <form id="form-id">
                    <div className="form">
                      <div className="formSection"></div>
                      <h5 className="ms-4 fw-bold">General Information</h5>
                      <div className="form">
                        <div className="row justify-content-center align-items-center">
                          <label
                            htmlFor="date"
                            className="fw-medium text-end col-sm-5 my-1 col-form-label"
                          >
                            DATE OF OCCURENCE :
                          </label>
                          <div className="col-sm-5">
                            <input
                              type="date"
                              className="textField form-control"
                              {...register("disasterDate")}
                              id="date"
                              disabled
                            />
                            {errors?.disasterDate && (
                              <small className="text-danger text-sm">
                                {errors.disasterDate.message}
                              </small>
                            )}
                          </div>
                        </div>

                        <div className="row justify-content-center align-items-center">
                          <label
                            htmlFor="causedamage"
                            className="fw-medium  text-end col-sm-5 my-1 col-form-label"
                          >
                            CAUSE OF DAMAGE :
                          </label>
                          <div className="col-sm-5">
                            <select
                              className="textField form-select"
                              {...register("disasterType")}
                              id="causedamage"
                              disabled
                            >
                              <option value="">Select an Option</option>
                              <option value="Drought">Drought</option>
                              <option value="Typhoon">Typhoon</option>
                              <option value="Flood">Flood</option>
                            </select>
                            {errors?.disasterType && (
                              <small className="text-danger text-sm">
                                {errors.disasterType.message}
                              </small>
                            )}
                          </div>
                        </div>

                        <div className="row justify-content-center align-items-center">
                          <label
                            htmlFor="date"
                            className="fw-medium text-end col-sm-5 my-1 col-form-label"
                          >
                            NAME OF DISASTER :
                          </label>
                          <div className="col-sm-5">
                            <input
                              type="text"
                              className="textField form-control"
                              {...register("disasterName")}
                              id="disastername"
                              disabled
                            />
                            {errors?.disasterName && (
                              <small className="text-danger text-sm">
                                {errors.disasterName.message}
                              </small>
                            )}
                          </div>
                        </div>

                        <div className="form">
                          <div className="formSection"></div>

                          <h5 className="ms-4 fw-bold">
                            Geographic Information
                          </h5>

                          <div className="row justify-content-center align-items-center">
                            <label
                              htmlFor="region"
                              className="fw-medium  text-end col-sm-5 my-1 col-form-label"
                            >
                              REGION :
                            </label>
                            <div className="col-sm-5">
                              <input
                                type="numeric"
                                {...register("region")}
                                className="textField form-control"
                                id="region"
                                defaultValue="3"
                                tabIndex={-1}
                                disabled
                              />
                            </div>
                          </div>

                          <div className="row justify-content-center align-items-center">
                            <label
                              htmlFor="province"
                              className="fw-medium  text-end col-sm-5 my-1 col-form-label"
                            >
                              PROVINCE :
                            </label>
                            <div className="col-sm-5">
                              <input
                                type="text"
                                {...register("province")}
                                className="textField form-control"
                                id="province"
                                defaultValue="Bulacan"
                                tabIndex={-1}
                                disabled
                              />
                            </div>
                          </div>

                          <div className="row justify-content-center align-items-center">
                            <label
                              htmlFor="municipality"
                              className="fw-medium  text-end col-sm-5 my-1 col-form-label"
                            >
                              MUNICIPALITY :
                            </label>
                            <div className="col-sm-5">
                              <input
                                type="text"
                                {...register("municipality")}
                                className="textField form-control"
                                id="municipality"
                                defaultValue="San Miguel"
                                tabIndex={-1}
                                disabled
                              />
                            </div>
                          </div>

                          <div className="row justify-content-center align-items-center">
                            <label
                              htmlFor="barangay"
                              className="fw-medium  text-end col-sm-5 my-1 col-form-label"
                            >
                              BARANGAY :
                            </label>
                            <div className="col-sm-5">
                              <input
                                type="text"
                                {...register("barangay")}
                                className="textField form-control"
                                id="barangay"
                                defaultValue={barangay}
                                tabIndex={-1}
                                disabled
                              />
                            </div>
                          </div>

                          <div className="formSection"></div>

                          <h5 className="ms-4 fw-bold">Particulars</h5>

                          <div className="row justify-content-center align-items-center">
                            <label
                              htmlFor="type"
                              className="fw-medium  text-end col-sm-5 my-1 col-form-label"
                            >
                              ECOSYSTEM :
                            </label>
                            <div className="col-sm-5">
                              <select
                                className="textField form-select"
                                {...register("cropType")}
                                id="type"
                                disabled
                              >
                                <option value="">Select an Option</option>
                                <option value="Irrigated">Irrigated</option>
                                <option value="Raised Field - lowland">
                                  Raised Field - lowland
                                </option>
                                <option value="Raised Field - upland">
                                  {" "}
                                  Raised Field - upland
                                </option>
                              </select>
                              {errors?.cropType && (
                                <small className="text-danger text-sm">
                                  {errors.cropType.message}
                                </small>
                              )}
                            </div>
                          </div>

                          <div className="row justify-content-center align-items-center">
                            <label
                              htmlFor="standingCrops"
                              className="fw-medium  text-end col-sm-5 my-1 col-form-label"
                            >
                              AREA OF STANDING CROPS :
                            </label>
                            <div className="col-sm-5">
                              <input
                                type="number"
                                {...register("standingCrops")}
                                id="standingCrops"
                                className="textField form-control"
                                min="0"
                                defaultValue="0"
                                disabled
                              />
                              {errors?.standingCrops && (
                                <small className="text-danger text-sm">
                                  {errors.standingCrops.message}
                                </small>
                              )}
                            </div>
                          </div>

                          <div className="row justify-content-center align-items-center">
                            <label
                              htmlFor="typeOfCrop"
                              className="fw-medium  text-end col-sm-5 my-1 col-form-label"
                            >
                              NUMBER OF AFFECTED FARMERS :
                            </label>
                            <div className="col-sm-5">
                              <input
                                type="number"
                                {...register("farmersCount")}
                                id="farmersCount"
                                className="textField form-control"
                                min="0"
                                defaultValue="0"
                                disabled
                              />
                              {errors?.farmersCount && (
                                <small className="text-danger text-sm">
                                  {errors.farmersCount.message}
                                </small>
                              )}
                            </div>
                          </div>

                          <div className="row justify-content-center align-items-center">
                            <label
                              htmlFor="type"
                              className="fw-medium  text-end col-sm-5 my-1 col-form-label"
                            >
                              SEED TYPE :
                            </label>
                            <div className="col-sm-5">
                              <select
                                className="textField form-select"
                                {...register("seedType")}
                                id="type"
                                disabled
                              >
                                <option value="">Select an Option</option>
                                <option value="Inbred">Inbred</option>
                                <option value="Hybrid">Hybrid</option>
                              </select>
                              {errors?.seedType && (
                                <small className="text-danger text-sm">
                                  {errors.seedType.message}
                                </small>
                              )}
                            </div>
                          </div>

                          <h5 className="ms-4 fw-semibold pt-4">
                            Area Affected (HA.) SEEDLINGS (BAGS)
                          </h5>

                          <div className="row justify-content-center align-items-center">
                            <label
                              htmlFor="totallyDamaged"
                              className="fw-medium  text-end col-sm-5 my-1 col-form-label"
                            >
                              TOTALLY DAMAGED :
                            </label>
                            <div className="col-sm-5">
                              <div className="form-outline">
                                <input
                                  type="number"
                                  step="0.01"
                                  {...register("totallyDamaged")}
                                  id="totally"
                                  className="textField form-control"
                                  min="0"
                                  max="100"
                                  defaultValue="0"
                                  disabled
                                />
                                {errors?.totallyDamaged && (
                                  <small className="text-danger text-sm">
                                    {errors.totallyDamaged.message}
                                  </small>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="row justify-content-center align-items-center">
                            <label
                              htmlFor="partiallyDamaged"
                              className="fw-medium  text-end col-sm-5 my-1 col-form-label"
                            >
                              PARTIALLY DAMAGED :
                            </label>
                            <div className="col-sm-5">
                              <input
                                type="number"
                                step="0.01"
                                {...register("partiallyDamaged")}
                                id="partial"
                                className="textField form-control"
                                min="0"
                                defaultValue="0"
                                disabled
                              />
                              {errors?.partiallyDamaged && (
                                <small className="text-danger text-sm">
                                  {errors.partiallyDamaged.message}
                                </small>
                              )}
                            </div>
                          </div>

                          <div className="row justify-content-center align-items-center">
                            <label
                              htmlFor="total"
                              className="fw-medium  text-end col-sm-5 my-1 col-form-label"
                            >
                              TOTAL :
                            </label>
                            <div className="col-sm-5">
                              <input
                                type="number"
                                step="0.01"
                                {...register("totalDamaged")}
                                id="total"
                                className="textField form-control"
                                defaultValue="0"
                                tabIndex={-1}
                                disabled
                              />
                              {errors?.totalDamaged && (
                                <small className="text-danger text-sm">
                                  {errors.totalDamaged.message}
                                </small>
                              )}
                            </div>
                          </div>

                          <h5 className="ms-4 fw-semibold pt-4">
                            Yield per Hectare (M.T)
                          </h5>

                          <div className="row justify-content-center align-items-center">
                            <label
                              htmlFor="beforeCalamity"
                              className="fw-medium  text-end col-sm-5 my-1 col-form-label"
                            >
                              BEFORE CALAMITY (%) :
                            </label>
                            <div className="col-sm-5">
                              <input
                                type="number"
                                step="0.01"
                                {...register("yieldBefore")}
                                id="yieldBefore"
                                className="textField form-control"
                                min="0"
                                defaultValue="0"
                                disabled
                              />
                              {errors?.yieldBefore && (
                                <small className="text-danger text-sm">
                                  {errors.yieldBefore.message}
                                </small>
                              )}
                            </div>
                          </div>

                          <div className="row justify-content-center align-items-center">
                            <label
                              htmlFor="afterCalamity"
                              className="fw-medium  text-end col-sm-5 my-1 col-form-label"
                            >
                              AFTER CALAMITY (%) :
                            </label>
                            <div className="col-sm-5">
                              <input
                                type="number"
                                step="0.01"
                                {...register("yieldAfter")}
                                id="yieldAfter"
                                className="textField form-control"
                                min="0"
                                defaultValue="0"
                                disabled
                              />
                              {errors?.yieldAfter && (
                                <small className="text-danger text-sm">
                                  {errors.yieldAfter.message}
                                </small>
                              )}
                            </div>
                          </div>

                          <div className="row justify-content-center align-items-center">
                            <label
                              htmlFor="yieldLoss"
                              className="fw-medium  text-end col-sm-5 my-1 col-form-label"
                            >
                              YIELD LOSS (%) :
                            </label>
                            <div className="col-sm-5">
                              <input
                                type="number"
                                step="0.01"
                                {...register("yieldLoss")}
                                id="total"
                                className="textField form-control"
                                defaultValue="0"
                                tabIndex={-1}
                                disabled
                              />
                              {errors?.yieldLoss && (
                                <small className="text-danger text-sm">
                                  {errors.yieldLoss.message}
                                </small>
                              )}
                            </div>
                          </div>

                          <h5 className="ms-4 fw-semibold pt-4">
                            Total Losses(Based on Cost of Input)
                          </h5>

                          <div className="row justify-content-center align-items-center">
                            <label
                              htmlFor="costOfInput"
                              className="fw-medium  text-end col-sm-5 my-1 col-form-label"
                            >
                              COST OF INPUT/HA. :
                            </label>
                            <div className="col-sm-5">
                              <input
                                type="number"
                                step="0.01"
                                {...register("inputCost")}
                                id="costOfInput"
                                className="textField form-control"
                                min="0"
                                defaultValue="0"
                                disabled
                              />
                              {errors?.inputCost && (
                                <small className="text-danger text-sm">
                                  {errors.inputCost.message}
                                </small>
                              )}
                            </div>
                          </div>

                          <div className="row justify-content-center align-items-center">
                            <label
                              htmlFor="totalValue"
                              className="fw-medium  text-end col-sm-5 my-1 col-form-label"
                            >
                              TOTAL VALUE :
                            </label>
                            <div className="col-sm-5">
                              <input
                                type="number"
                                step="0.01"
                                {...register("totalCost")}
                                id="totalValue"
                                className="textField form-control"
                                defaultValue="0"
                                tabIndex={-1}
                                disabled
                              />
                              {errors?.totalCost && (
                                <small className="text-danger text-sm">
                                  {errors.totalCost.message}
                                </small>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/* FLOATING INFO BUTTON */}
            <div className="floatPosition">
              <div
                style={{ backgroundColor: "#185D10" }}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              >
                <i class="bi bi-lightbulb-fill floatIcon" />
              </div>
              {showTooltip && (
                <div className="customTooltip">
                  <span className="tooltipText">
                    This created innitial report is reflected to all Municipal
                    and Provincial Agricultural Admins.
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BAFCViewInitialReport;
