
import React, { useEffect, useState } from "react";
// OTHER IMPORTS
import { useSearchParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { format } from "date-fns"
import { CSVLink } from "react-csv"
import { useFetchReportHistoryQuery } from "../utils/features/summaryReports/summaryReportsApiSlice"

// COMPONENTS IMPORTS
import Navbar from "../navbar/ATNavbar";
import SideBarnav from "../sidebar/ATSidebar";

const TITLE = 'GoFarmers | Reports History'

const ATReportHistory = () => {
  const [isClicked, setIsClicked] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleButtonClick = () => {
    setIsClicked(!isClicked);
  };

  const [searchParams, setSearchParams] = useSearchParams({
    sortField: ""
  })

  const { data: reports, isLoading: reportsIsLoading } = useFetchReportHistoryQuery({
    sortField: searchParams.get("sortField")
  })

  const handleSort = (sortField) => setSearchParams((searchParams) => {
    searchParams.set("sortField", sortField)

    return sortField
  })

  const [csvData, setCsvData] = useState([[]])

  useEffect(() => {
    if (!reportsIsLoading && reports?.data && reports.data.length) {
      setCsvData([
        [
          "reportId",
          "barangay",
          "disasterDate",
          "farmersCount",
          "cropArea",
          "cropStage",
          "yieldLoss (%)",
          "totalDamaged (HA)",
          "status"
        ],
        ...(reports.data.map((report) => ([
          report._id,
          report.barangay,
          format(new Date(report.initialReport.disasterDate), "yyyy-MM-dd"),
          report.farmersCount,
          report.cropArea,
          report.cropStage,
          report.yieldLoss,
          report.totalDamaged,
          report.status
        ])))
      ])
    }
  }, [reportsIsLoading, reports])

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Navbar handleButtonClick={handleButtonClick} />
      <div className="container-fluid" id="tryFix">
        <div className="row flex-nowrap overflow-hidden">
          <SideBarnav />
          <div className="col overflow-x-hidden" id={`${isClicked ? 'tryMove' : ''}`}>
            <style>
              {`
                  #tryMove {
                    margin-left: ${isClicked ? '380px' : '0px'};       
                  }
                `}
            </style>
            {/* SECTION TITLE */}
            <h1 className="section">Reports History</h1>

            {/* BUTTONS */}
            <div className="topButtons">
              <div className="column buttonContainer2">
                <div className="singleContainer2">
                  <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" id="buttons">
                    SORT BY
                  </button>
                  <ul className="dropdown-menu" id="dropDown">
                    <li><Link className="dropdown-item" to="" onClick={() => handleSort("barangay")}>Barangay</Link></li>
                    <li><Link className="dropdown-item" to="" onClick={() => handleSort("-createdAt")}>Latest to Oldest</Link></li>
                    <li><Link className="dropdown-item" to="" onClick={() => handleSort("createdAt")}>Oldest to Latest</Link></li>
                  </ul>
                </div>
                <div className="singleContainer">
                  <CSVLink className="btn btn-secondary" data={csvData} filename="agritech_reports-history" id="buttons">DOWNLOAD CSV</CSVLink>
                </div>
              </div>
            </div>

            {/* GENERATED BARANGAY REPORTS */}
            <div className="row mt-4 tableContainer">
              <div className="col-11 col-lg-11 overflow-auto p-0" id="tableBorder">
                <table className="table mb-0 text-center table-hover align-middle" style={{ borderColor: '#185D10' }}>
                  <thead>
                    <tr id="thead1">
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                    <tr className="align-middle" id="thead1">
                      <th scope="col">BARANGAY</th>
                      <th scope="col">DATE OF OCCURENCE</th>
                      <th scope="col">NUMBER OF AFFECTED FARMERS</th>
                      <th scope="col">AREA OF STANDING 	CROP</th>
                      <th scope="col">STAGE OF CROP DEVELOPMENT</th>
                      <th scope="col">YIELD LOSS (%)</th>
                      <th scope="col">AREA AFFECTED</th>
                      <th scope="col">ACTION</th>
                    </tr>
                    <tr id="thead1">
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      !reportsIsLoading && reports?.data.length ? reports.data.reverse().map((report) => (
                        <tr className="tableData">
                          <td>{report.barangay}</td>
                          <td>{format(new Date(report.initialReport.disasterDate), "MM-dd-yyyy")}</td>
                          <td>{report.farmersCount}</td>
                          <td>{report.cropArea}</td>
                          <td>{report.cropStage}</td>
                          <td>{report.yieldLoss}%</td>
                          <td>{report.totalDamaged}</td>
                          <td>
                            <div className="d-flex flex-row justify-content-center">
                              <div>
                                <Link to={`/agritech-form-history/${report._id}`} className="btn btn-primary py-2 mx-auto btn-sm" id="editButton"><i class="bi fs-4 bi-eye"></i></ Link>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )) : (
                        <tr className="tableData">
                          <td className="text-center" colSpan={100}>There is no report available.</td>
                        </tr>
                      )
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* FLOATING INFO BUTTON */}
      {
        !reportsIsLoading && reports?.data.length ? reports.data.map((report) => (
          <div className="floatPosition">
            <div
              style={{ backgroundColor: '#185D10' }}
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            >
              <i class="bi bi-lightbulb-fill floatIcon" />
            </div>
            {showTooltip && (
              <div className="customTooltip2">
                <span className="tooltipText">Use this modal to view your submitted barangay generated reports. You can also download a copy of the reports as csv.</span>
              </div>
            )}
          </div>
        )) : (
          <div className="floatPosition">
            <div
              style={{ backgroundColor: '#185D10' }}
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            >
              <i class="bi bi-lightbulb-fill floatIcon" />
            </div>
            {showTooltip && (
              <div className="customTooltip">
                <span className="tooltipText">The reports will reflect here once you submit a barangay generated report.</span>
              </div>
            )}
          </div>
        )
      }
    </>
  )
}

export default ATReportHistory
