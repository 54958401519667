import React, { useState, useEffect } from "react";
// OTHER IMPORTS
import { Link, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { format, isAfter, isSameDay } from "date-fns"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod";
import { useFetchUnscheduledValidatedSummaryReportsQuery } from "../utils/features/summaryReports/summaryReportsApiSlice"
import { useFetchUnreceivedDistributionSchedulesQuery, useCreateDistributionScheduleMutation } from "../utils/features/distribution/distributionApiSlice"

// COMPONENTS
import Navbar from "../navbar/MRONavbar";
import SideBarnav from "../sidebar/MROSidebar";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const TITLE = 'GoFarmers | Profile Settings'

const validationSchema = z.object({
  report: z.string({ errorMap: () => ({ message: "Report ID is invalid." }) })
    .trim()
    .nonempty("Report ID is required."),
  scheduledAt: z.coerce.date({ errorMap: () => ({ message: "Scheduled Date is invalid." }) })
    .refine((value) => isSameDay(new Date(value), new Date()) || isAfter(new Date(value), new Date()), "Scheduled Date cannot be from the past."),
  bafc: z.string({ errorMap: () => ({ message: "BAFC Admin Name is invalid." }) })
    .trim()
    .nonempty("BAFC Admin Name is required."),
  bafcId: z.string({ errorMap: () => ({ message: "BAFC ID is invalid." }) })
    .trim()
    .nonempty("BAFC ID is required."),
  mobile: z.string({ errorMap: () => ({ message: "Mobile Number is invalid." }) })
    .trim()
    .nonempty()
    .max(15, "Mobile Number cannot be more than 15 characters.")
})

const MROSchedule = () => {
  const [isClicked, setIsClicked] = useState(false);

  const [showTooltip, setShowTooltip] = useState(false);

  const handleButtonClick = () => {
    setIsClicked(!isClicked);
  };

  const [showModal, setshowModal] = useState(false);

  const modalClose = () => setshowModal(false);
  const modalShow = () => setshowModal(true);

  const { data: unscheduledSummaryReports, isLoading: unscheduledSummaryReportsIsLoading } = useFetchUnscheduledValidatedSummaryReportsQuery()

  const { handleSubmit, register, reset, setValue, watch, formState: { errors } } = useForm({
    resolver: zodResolver(validationSchema)
  })

  const [watchReport] = watch(["report"])

  useEffect(() => {
    if (!unscheduledSummaryReportsIsLoading || unscheduledSummaryReports?.data) {
      const targetUnscheduledSummaryReports = unscheduledSummaryReports.data.find((unscheduledSummaryReport) => unscheduledSummaryReport._id === watchReport)

      let bafcName = ""
      let bafcId = ""
      let bafcMobile = ""

      if (targetUnscheduledSummaryReports?.bafcAssessment.assessedBy.firstName && targetUnscheduledSummaryReports?.bafcAssessment.assessedBy.lastName) {
        bafcName = `${targetUnscheduledSummaryReports.bafcAssessment.assessedBy.firstName} ${targetUnscheduledSummaryReports.bafcAssessment.assessedBy.lastName}`
      }

      if (targetUnscheduledSummaryReports?.bafcAssessment.assessedBy._id) {
        bafcId = targetUnscheduledSummaryReports.bafcAssessment.assessedBy._id
      }

      if (targetUnscheduledSummaryReports?.bafcAssessment.assessedBy.mobile) {
        bafcMobile = targetUnscheduledSummaryReports.bafcAssessment.assessedBy.mobile
      }

      setValue("bafc", bafcName)
      setValue("bafcId", bafcId)
      setValue("mobile", bafcMobile)
    }
  }, [watchReport, setValue, unscheduledSummaryReports, unscheduledSummaryReportsIsLoading])

  const [searchParams, setSearchParams] = useSearchParams({
    sortField: ""
  })

  const { data: unreceivedDistributionSchedules, isLoading: unreceivedDistributionSchedulesIsLoading } = useFetchUnreceivedDistributionSchedulesQuery({
    sortField: searchParams.get("sortField")
  })

  const handleSort = (sortField) => setSearchParams((searchParams) => {
    searchParams.set("sortField", sortField)

    return searchParams
  }, { replace: true })

  const [createDistributionSchedule] = useCreateDistributionScheduleMutation()

  async function handleCreateSchedule(data) {
    const createdDistributionSchedule = await createDistributionSchedule({
      report: data.report,
      scheduledAt: data.scheduledAt,
      bafc: data.bafcId
    })

    if (createdDistributionSchedule.error) {
      // console.error("Failed to create new Distribution Schedule!")
      return
    }
    reset()
    // console.log("Successfully created new Distribution Schedule!")
  }

  return (

    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Navbar handleButtonClick={handleButtonClick} />
      <div className="container-fluid" id="tryFix">
        <div className="row flex-nowrap overflow-hidden">
          <SideBarnav />

          <div className="col overflow-x-hidden" id={`${isClicked ? 'tryMove' : ''}`}>
            <style>
              {`
                #tryMove {
                margin-left: ${isClicked ? '380px' : '0px'};               
                }
            `}
            </style>
            {/* SECTION TITLE */}
            <h1 className="section">Distribution Schedule</h1>

            {/* BUTTONS */}
            <div className="topButtons">
              <div className="row buttonContainer">
                <div className="singleContainer">
                  <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" id="buttons">
                    SORT BY
                  </button>
                  <ul className="dropdown-menu" id="dropDown">
                    <li><Link className="dropdown-item" to="" onClick={() => handleSort("barangay")}>Barangay</Link></li>
                    <li><Link className="dropdown-item" to="" onClick={() => handleSort("scheduledAt")}>Date</Link></li>
                    <li><Link className="dropdown-item" to="" onClick={() => handleSort("-createdAt")}>Latest to Oldest</Link></li>
                    <li><Link className="dropdown-item" to="" onClick={() => handleSort("createdAt")}>Oldest to Latest</Link></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row mt-4 tableContainer">
              <div className="col-4 col-md-4 overflow-auto p-0 m-0" id="textSection">
                {/* {inputs && */}
                <form id="form-id" className="ps-4 pe-4 my-4" onSubmit={handleSubmit(handleCreateSchedule)}>
                  <div className="row justify-content-center align-items-center">
                    <label htmlFor="role" className="formLabel col-sm-5 my-1 col-form-label text-end">REPORT:</label>
                    <div className="col-sm-6">
                      <select className="textField form-control" id="role" {...register("report")}>
                        <option value="">Select Report</option>
                        {
                          !unscheduledSummaryReportsIsLoading && unscheduledSummaryReports?.data.length && unscheduledSummaryReports.data.map((unscheduledSummaryReport) => (
                            <option value={unscheduledSummaryReport._id} key={unscheduledSummaryReport._id}>{`${unscheduledSummaryReport.barangay} - ${unscheduledSummaryReport.initialReport?.disasterName || "Unnamed"} - ${unscheduledSummaryReport.cropStage}`}</option>
                          ))
                        }
                      </select>

                      {errors?.report && <small className="text-danger">{errors.report.message}</small>}
                    </div>
                  </div>
                  <div className="row justify-content-center align-items-center" >
                    <label htmlFor="date" className="formLabel col-sm-5 my-1 col-form-label text-end">DATE:</label>
                    <div className="col-sm-6">
                      <input type="date" className="textField form-control" id="date" {...register("scheduledAt")} />
                      {errors?.scheduledAt && <small className="text-danger">{errors.scheduledAt.message}</small>}
                    </div>
                  </div>

                  <div className="row justify-content-center align-items-center">
                    <label htmlFor="name" className="formLabel col-sm-5 my-1 col-form-label text-end">BAFC NAME:</label>
                    <div className="col-sm-6">
                      <input type="text" className="textField form-control" id="name" {...register("bafc")} disabled />
                      <input type="hidden" {...register("bafcId")} />
                      {errors?.bafc && <small className="text-danger">{errors.bafc.message}</small>}
                    </div>
                  </div>
                  <div className="row justify-content-center align-items-center mb-2">
                    <label htmlFor="mobileNumber" className="formLabel col-sm-5 my-1 col-form-label text-end">MOBILE:</label>
                    <div className="col-sm-6">
                      <input type="text" inputMode="numeric" className="textField form-control" id="mobileNumber" disabled {...register("mobile")} />
                      {errors?.mobile && <small className="text-danger">{errors.mobile.message}</small>}
                    </div>
                  </div>

                  {/* BUTTONS */}
                  <div className="d-flex justify-content-end">
                    <div>
                      <Link to='/mro-dashboard'>
                        <button type="button" className="btn btn-primary me-3" id="cancelButton">CANCEL</button>
                      </Link>
                    </div>
                    <button type="button" onClick={modalShow} className="btn btn-primary" id="buttons">SET</button>
                  </div>
                </form>
                {/* } */}
              </div>
              <div className="col-6 col-sm-6 overflow-auto p-0 mx-2" id="tableBorder">
                <table className="table text-center table-hover align-middle" style={{ borderColor: '#185D10' }}>
                  <thead>
                    <tr id="thead1">
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <thead>
                    <tr className="align-middle" id="thead1">
                      <th scope="col">BARANGAY</th>
                      <th scope="col">DATE</th>
                      <th scope="col">BAFC</th>
                      <th scope="col">MOBILE</th>
                      <th scope="col">ACTION</th>
                    </tr>
                  </thead>
                  <thead>
                    <tr id="thead1">
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      !unreceivedDistributionSchedulesIsLoading && unreceivedDistributionSchedules?.data.length ? unreceivedDistributionSchedules.data.map((unreceivedDistributionSchedule) => (
                        <tr className="tableData" key={unreceivedDistributionSchedule._id}>
                          <td>{unreceivedDistributionSchedule.receiver.barangay}</td>
                          <td>{format(new Date(unreceivedDistributionSchedule.scheduledAt), "MM-dd-yyyy")}</td>
                          <td>{`${unreceivedDistributionSchedule.receiver.firstName} ${unreceivedDistributionSchedule.receiver.lastName}`}</td>
                          <td>{unreceivedDistributionSchedule.receiver.mobile}</td>
                          <td>
                            <div className="d-flex flex-row justify-content-center">
                              <div>
                                <Link to={`/mro-distribution-schedule/${unreceivedDistributionSchedule._id}/edit`} className="btn btn-primary py-2 mx-auto btn-sm" id="editButton"><i className="bi fs-4 bi-pencil-square"></i></ Link>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )) : (
                        <tr className="tableData">
                          <td className="text-center" colSpan={100}>There is no distribution schedule available.</td>
                        </tr>
                      )
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* FLOAT INFO */}
      <div className="floatPosition">
        <div
          style={{ backgroundColor: "#185D10" }}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          <i class="bi bi-lightbulb-fill floatIcon" />
        </div>
        {showTooltip && (
          <div className="customTooltip2">
            <span className="tooltipText">
              Use this modal to create and update the rehabilitation aid distribution schedule for the barangays affected by the disaster.
            </span>
          </div>
        )}
      </div>
      {/* MODALS */}
      <Modal
        show={showModal}
        onHide={modalClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body className="py-4">
          <div className="row mb-4 text-center" style={{ color: '#185D10' }}>
            <h1><i className="bi bi-question-circle"></i></h1>
            <h5 className="fw-bold">ARE YOU SURE <br /> YOU WANT TO SET THIS SCHEDULE?</h5>
          </div>
          <div className="row text-center">
            <div className="col text-end">
              <Button className="m-auto" onClick={modalClose} id="modalCancelbtn">
                Cancel
              </Button>
            </div>
            <div className="col text-start">
              <Button className="m-auto" type="submit" form="form-id" onClick={modalClose} id="modalConfirmbtn">CONFIRM</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default MROSchedule