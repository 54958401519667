import { apiSlice } from "../../app/api/apiSlice"

const formsApiSlice = apiSlice.injectEndpoints({
   endpoints: (builder) => ({
      fetchBAFCPendingForms: builder.query({
         query: () => "/forms-web",
         providesTags: ["Forms-Web"]
      }),

      fetchBAFCPendingFormsPerCropStage: builder.query({
         query: (data) => ({
            url: `/forms-web/${ data.cropStage }`,
            params: {
               sortField: data.sortField
            }
         }),
         providesTags: ["Forms-Web-Crop-Stage"]
         // providesTags: (_res, _err, arg) => [{ type: "Forms-Web-Crop-Stage", id: arg.cropStage }]
      }),

      fetchBAFCPendingForm: builder.query({
         query: (data) => `/forms-web/${ data.cropStage }/${ data.id }`,
         providesTags: (_res, _err, arg) => [
            { type: "Form-Web", id: arg.id }
         ]
      }),

      fetchBAFCDeclinedForms: builder.query({
         query: (data) => ({
            url: `/forms-web/declined`,
            params: {
               sortField: data.sortField
            }
         }),
         providesTags: ["Forms-Web-Declined"]
      }),

      fetchBAFCDeclinedForm: builder.query({
         query: (data) => `/forms-web/declined/${ data.id }`,
         providesTags: (_res, _err, arg) => [
            { type: "Form-Web-Declined", id: arg.id }
         ]
      }),

      fetchBAFCApprovedForms: builder.query({
         query: (data) => ({
            url: `/forms-web/approved`,
            params: {
               sortField: data.sortField
            }
         }),
         providesTags: ["Forms-Web-Approved"]
      }),

      fetchBAFCApprovedForm: builder.query({
         query: (data) => `/forms-web/approved/${ data.id }`,
         providesTags: (_res, _err, arg) => [
            { type: "Form-Web-Approved", id: arg.id }
         ]
      }),

      updateBAFCPendingForm: builder.mutation({
         query: (data) => ({
            url: `/forms-web/${ data.cropStage }/${ data.id }`,
            method: "PATCH",
            body: data
         }),
         invalidatesTags: (_res, _err, arg) => [
            { type: "Form-Web", id: arg.id },
            // { type: "Forms-Web-Crop-Stage", id: arg.cropStage },
            "Forms-Web-Crop-Stage",
            "Forms-Web",
            "Forms-Web-Declined",
            "Dashboard"
         ]
      }),

      createFormByBAFC: builder.mutation({
         query: (data) => ({
            url: "/forms-web/create-form-bafc",
            method: "POST",
            body: data
         }),
         invalidatesTags: ["Forms-Web", "Forms-Web-Crop-Stage",
         "Dashboard"]
      })
   })
})

export const {
   useFetchBAFCPendingFormsQuery,
   useFetchBAFCPendingFormsPerCropStageQuery,
   useFetchBAFCPendingFormQuery,
   useFetchBAFCDeclinedFormsQuery,
   useFetchBAFCDeclinedFormQuery,
   useFetchBAFCApprovedFormsQuery,
   useFetchBAFCApprovedFormQuery,
   useUpdateBAFCPendingFormMutation,
   useCreateFormByBAFCMutation
} = formsApiSlice