/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useFetchDashboardDataQuery } from "../utils/features/dashboard/dashboardApiSlice";
import { format } from "date-fns";

// OTHER IMPORTS
import { Helmet } from "react-helmet";
import Navbar from "../navbar/BAFCNavbar";
import SideBarnav from "../sidebar/BAFCSidebar";

const TITLE = "GoFarmers | Dashboard";

const BAFCDashboard = () => {
  const [isClicked, setIsClicked] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleButtonClick = () => {
    setIsClicked(!isClicked);
  };

  const MIN_YEAR_VALUE = 2000;

  const DEFAULT_YEAR = new Date().getFullYear();
  const DEFAULT_MONTH = new Date().getMonth();

  const [searchParams, setSearchParams] = useSearchParams({
    month: DEFAULT_MONTH,
    year: DEFAULT_YEAR,
  });

  const { register, handleSubmit } = useForm();

  const handleFilter = (data) =>
    setSearchParams(
      (prevSearchParams) => {
        prevSearchParams.set("month", data.month);
        prevSearchParams.set("year", data.year);
        return prevSearchParams;
      },
      { replace: true }
    );

  const { data, isLoading: dataIsLoading } = useFetchDashboardDataQuery({
    role: "bafc",
    month: searchParams.get("month"),
    year: searchParams.get("year"),
  });

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Navbar handleButtonClick={handleButtonClick} />
      <div className="container-fluid" id="tryFix">
        <div className="row flex-nowrap overflow-hidden">
          <SideBarnav />
          <div
            className="col overflow-x-hidden"
            id={`${isClicked ? "tryMove" : ""}`}
          >
            <style>
              {`
                #tryMove {
                  margin-left: ${isClicked ? "380px" : "0px"};
                  
                }
              `}
            </style>
            {/* SECTION TITLE */}
            <h1 className="section">BAFC Dashboard</h1>
            <div>
              <div className="row justify-content-center mt-0 px-auto pb-0 ">
                <div className="col-11 col-lg-10 overflow-auto p-0">
                  <div className="row mx-0 mt-0">
                    <h5 className="sortLabel">Choose Month and Year:</h5>
                  </div>
                  <form
                    className="sortContainer row mx-0 pb-0 g-4"
                    onChange={handleSubmit(handleFilter)}
                  >
                    <div className="col-12">
                      <div className="row">
                        <div className="col-12 col-lg-5">
                          <select
                            {...register("year")}
                            defaultValue={
                              searchParams.get("year") || DEFAULT_YEAR
                            }
                            className="form-select textField"
                            autoFocus
                          >
                            <option value="" disabled>
                              Select a Year
                            </option>
                            {[
                              ...Array(
                                new Date().getFullYear() - MIN_YEAR_VALUE
                              ).keys(),
                            ]
                              .map((x) => x + 1)
                              .toReversed()
                              .map((year) => (
                                <option
                                  value={MIN_YEAR_VALUE + year}
                                  key={`year-${MIN_YEAR_VALUE + year}`}
                                >
                                  {MIN_YEAR_VALUE + year}
                                </option>
                              ))}
                            <option value={MIN_YEAR_VALUE}>
                              {MIN_YEAR_VALUE}
                            </option>
                          </select>
                        </div>
                        <div className="col-12 col-lg-7">
                          <select
                            {...register("month")}
                            defaultValue={
                              searchParams.get("month") || DEFAULT_MONTH
                            }
                            className="form-select textField"
                          >
                            <option value="" disabled>
                              Select a Month
                            </option>
                            {[
                              "January",
                              "February",
                              "March",
                              "April",
                              "May",
                              "June",
                              "July",
                              "August",
                              "September",
                              "October",
                              "November",
                              "December",
                            ].map((month, idx) => (
                              <option value={idx} key={`month-${idx}`}>
                                {month}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            {/* ADMIN DASHBOARD CARD STATS */}
            <div className="row justify-content-center g-4 mt-0 mx-3 mb-3">
              {/* CARD 1 */}
              <div className="col-6 col-lg-3 mx-lg-3 mt-2">
                <div className="card py-2" id="bg-card">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div
                          className="h1 text-white text-center"
                          id="fontsize-card"
                        >
                          {data?.data.pendingFormsCount}
                        </div>
                        <div
                          className="text-white text-uppercase text-center"
                          id="fontweight-card"
                        >
                          Pending Farmer Forms
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 col-lg-4 mx-lg-3 mt-2">
                <div className="card py-2" id="bg-card">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div
                          className="h1 font-weight-bold text-white text-center"
                          id="fontsize-card"
                        >
                          {data?.data.approvedFormsCount}
                        </div>
                        <div
                          className="font-weight-bold text-white text-uppercase text-center"
                          id="fontweight-card"
                        >
                          Approved Farmer Forms
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-3 mx-lg-3 mt-2">
                <div className="card py-2" id="bg-card">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div
                          className="h1 font-weight-bold text-white text-center"
                          id="fontsize-card"
                        >
                          {data?.data.annualInitialReportsCount}
                        </div>
                        <div
                          className="font-weight-bold text-white text-uppercase text-center"
                          id="fontweight-card"
                        >
                          Total Initial Reports
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* DASHBOARD TABLE - BARANGAY INITIAL REPORTS  */}
            <div className="row justify-content-center mt-0 px-auto pb-4">
              <div
                className="col-11 col-lg-10 overflow-auto p-0"
                id="tableBorder"
              >
                <h4 className="tableCaption text-center m-0 p-3 fw-bold text-white text-uppercase">
                  Summary Damage Assessment Reports
                </h4>
                <table
                  className="table mb-0 text-center table-hover align-middle"
                  style={{ borderColor: "#185D10" }}
                >
                  <thead>
                    <tr className="align-middle" id="thead1">
                      <th scope="col">NUMBER OF AFFECTED FARMERS</th>
                      <th scope="col">AREA OF STANDING CROP</th>
                      <th scope="col">STAGE OF CROP DEVELOPMENT </th>
                      <th scope="col">STATUS</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!dataIsLoading &&
                      data?.data?.recentSummaryReports.length ? (
                      data.data.recentSummaryReports.map(
                        (recentSummaryReport) => (
                          <tr
                            className="tableData"
                            key={recentSummaryReport._id}
                          >
                            <td>{recentSummaryReport.farmersCount}</td>
                            <td>{recentSummaryReport.cropArea}</td>
                            <td>{recentSummaryReport.cropStage}</td>
                            <td>
                              {recentSummaryReport.status === "agri-waiting" ? (
                                <button
                                  className="btn btn-primary statusButton px-3 disabled"
                                  id="buttons"
                                  aria-disabled="true"
                                >
                                  BAFC ASSESSED
                                </button>
                              ) : recentSummaryReport.status ===
                                "agri-approved" ||
                                recentSummaryReport.status === "mro-waiting" ? (
                                <button
                                  className="btn btn-primary statusButton px-3 disabled"
                                  id="buttons"
                                  aria-disabled="true"
                                >
                                  AGRITECH ASSESSED
                                </button>
                              ) : recentSummaryReport.status ===
                                "mro-approved" ||
                                recentSummaryReport.status === "pro-waiting" ? (
                                <button
                                  className="btn btn-primary statusButton px-3 disabled"
                                  id="buttons"
                                  aria-disabled="true"
                                >
                                  MRO ASSESSED
                                </button>
                              ) : recentSummaryReport.status ===
                                "pro-approved" ? (
                                <button
                                  className="btn btn-primary statusButton px-3 disabled"
                                  id="buttons"
                                  aria-disabled="true"
                                >
                                  PRO ASSESSED
                                </button>
                              ) : recentSummaryReport.status === "validated" ? (
                                <button
                                  className="btn btn-primary statusButton px-3 disabled"
                                  id="buttons"
                                  aria-disabled="true"
                                >
                                  VALIDATED
                                </button>
                              ) : recentSummaryReport.status ===
                                "rehab-muni" ? (
                                <button
                                  className="btn btn-primary statusButton px-3 disabled"
                                  id="buttons"
                                  aria-disabled="true"
                                >
                                  REHAB AID - SCHEDULED
                                </button>
                              ) : recentSummaryReport.status ===
                                "rehab-brgy" ? (
                                <button
                                  className="btn btn-primary statusButton px-3 disabled"
                                  id="buttons"
                                  aria-disabled="true"
                                >
                                  REHAB AID - DISTRIBUTED
                                </button>
                              ) : null}
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr className="tableData">
                        <td className="text-center" colSpan={100}>
                          There is no barangay generated summary report
                          available.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            {/* DASHBOARD TABLE - FARMERS GENERATED REPORTS  */}
            <div className="row justify-content-center mt-0 px-auto pb-3">
              <div
                className="col-11 col-lg-10 overflow-auto p-0"
                id="tableBorder"
              >
                <h4 className="tableCaption text-center m-0 p-3 fw-bold text-white  text-uppercase">
                  {" "}
                  Pending Farmer Reports
                </h4>
                <table
                  className="table mb-0 text-center table-hover align-middle"
                  style={{ borderColor: "#185D10" }}
                >
                  <thead>
                    <tr id="thead1">
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <thead>
                    <tr className="align-middle" id="thead1">
                      <th scope="col">FFRS ID</th>
                      <th scope="col">FARMER NAME</th>
                      <th scope="col">DATE OF OCCURENCE</th>
                      <th scope="col">DISASTER TYPE</th>
                      <th scope="col">AREA OF STANDING CROP</th>
                      <th scope="col">STAGE OF CROP DEVELOPMENT</th>
                      <th scope="col">STATUS</th>
                    </tr>
                  </thead>
                  <thead>
                    <tr id="thead1">
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {!dataIsLoading && data?.data?.recentForms.length ? (
                      data.data.recentForms.map((recentForm) => (
                        <tr className="tableData" key={recentForm._id}>
                          <td>{recentForm.user.ffrs}</td>
                          <td>{`${recentForm.user.firstName} ${recentForm.user.lastName}`}</td>
                          <td>
                            {format(
                              new Date(recentForm.disasterDate),
                              "MM-dd-yyyy"
                            )}
                          </td>
                          <td>{recentForm.disasterType}</td>
                          <td>{recentForm.cropArea}</td>
                          <td>{recentForm.cropStage}</td>
                          <td>
                            {recentForm.status === "bafc-declined" ? (
                              <button
                                className="btn btn-primary statusButton px-3 disabled"
                                id="buttons"
                                aria-disabled="true"
                              >
                                DECLINED
                              </button>
                            ) : recentForm.status === "bafc-waiting" ? (
                              <button
                                className="btn btn-primary statusButton px-3 disabled"
                                id="buttons"
                                aria-disabled="true"
                              >
                                PENDING
                              </button>
                            ) : (
                              <button
                                className="btn btn-primary statusButton px-3 disabled"
                                id="buttons"
                                aria-disabled="true"
                              >
                                APPROVED
                              </button>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="tableData">
                        <td className="text-center" colSpan={100}>
                          There is no farmer submitted form available.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {/* FLOATING INFO BUTTON */}
            <div className="floatPosition">
              <div
                style={{ backgroundColor: "#185D10" }}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              >
                <i class="bi bi-lightbulb-fill floatIcon" />
              </div>
              {showTooltip && (
                <div className="customTooltip">
                  <span className="tooltipText">
                    Use this modal to have an overview of the pending and
                    important reports on your barangay.
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BAFCDashboard; 
