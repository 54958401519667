import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { setCredentials, logOut } from "../../features/auth/authSlice"

export const baseQuery = fetchBaseQuery({
  baseUrl: "https://gofarmers.systems/api",
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token

    if (token) {
      headers.set("authorization", `Bearer ${token}`)
    }
    return headers
  },
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)
  
  if (result.error && (result.error.status === 401 || result.error.status === 403)) {
    const refreshResult = await baseQuery({
      url: '/auth/regenerate',
      method: 'POST'
    }, api, extraOptions)

    if (refreshResult && refreshResult.error && refreshResult.error.data) {
      const errorMessage = refreshResult.error.data.error
      if (errorMessage.includes("Authentication failed") ) {
        sessionStorage.removeItem("key")
        api.dispatch(logOut())
      }
    } else if (refreshResult && refreshResult.data && refreshResult.data.accessToken) {
      const accessToken = refreshResult.data.accessToken
      api.dispatch(setCredentials({ accessToken }))
      result = await baseQuery(args, api, extraOptions)
    } else {
      api.dispatch(logOut())
    }
  }

  return result
}

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    "Form", "User",
    "Initial-Reports", "Initial-Report", "Initial-Reports-Multi",
    "Forms-Web", "Form-Web", "Forms-Web-Crop-Stage", "Forms-Web-Declined", "Form-Web-Declined", "Forms-Web-Approved", "Form-Web-Approved",
    "SummaryReports", "SummaryReports-Pending", "SummaryReports-History", "SummaryReports-Validated", "SummaryReports-Unscheduled-Validated",
    "Distribution", "Distribution-Unreceived", "Distribution-Barangay",
    "Dashboard", "Process-History"
  ],
  endpoints: () => ({}),
})
